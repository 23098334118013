import { gql } from 'apollo-angular';

export const createSubCategory = gql`
mutation ($data:SubCategoryCreateInput!,$where:CategoryWhereUniqueInput!){
	social {
		createSubCategory(
			where: $where,
			data: $data
		) {
			code
			createdAt
			updatedAt
			subCategoryData {
				description
				defaultIsBuyer
				defaultIsSeller
				defaultDemandBridge
				defaultSupplyBridge
			}
			adviseMessage {
				code
				description
				level
				external {
					code
					message
				}
			}
		}
	}
}
`
