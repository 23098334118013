import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubCategoryCreateInput } from '../../../../interfaces/inputs/sub-category-create-input';
import { SubCategoryUpdateInput } from '../../../../interfaces/inputs/sub-category-update-input copy';
import { SubCategoryWhereUniqueInput } from '../../../../interfaces/inputs/sub-category-where-unique-input.interface';
import { Category, CategoryData, SubCategory } from '../../../../interfaces/_index-network.interfaces';
import { ProfileNetworkService } from '../../../../services/profile-network.service';
import { NewSubcategoryFormComponent } from '../new-subcategory-form/new-subcategory-form.component';
import { UpdateSubcategoryFormComponent } from '../update-subcategory-form/update-subcategory-form.component';
import orderBy from 'lodash/orderBy';

@Component({
  selector: 'tgx-subcategories-table',
  templateUrl: './subcategories-table.component.html',
  styleUrls: ['./subcategories-table.component.scss'],
})
export class SubcategoriesTableComponent implements OnInit {
  isLoading = true;
  selectedCategory: CategoryData;
  selectedSubCategoryCode: string;
  isNewCategoryEditable = false;
  isNewSubCategoryEditable = false;
  isUpdateSubCategoryEditable = false;
  isUpdateCategoryEditable = false;
  subcategoriesSource: any[];

  categoriesListData: CategoryData[];

  constructor(
    private networkService: ProfileNetworkService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.recoverCategories();
  }

  getCategoriesSource(subcategories: SubCategory[]) {
    this.subcategoriesSource = [];

    subcategories.forEach((subcategory) => {
      this.subcategoriesSource.push({
        'name': subcategory.code,
        'description': subcategory.subCategoryData.description,
        'isBuyer': subcategory.subCategoryData.defaultIsBuyer,
        'isSeller': subcategory.subCategoryData.defaultIsSeller,
        'isDemandBridge': subcategory.subCategoryData.defaultDemandBridge,
        'isSupplyBridge': subcategory.subCategoryData.defaultSupplyBridge,
        'createdAt': subcategory.createdAt,
        'updatedAt': subcategory.updatedAt,
      });
    });
    this.isLoading = false;
  }

  selectCategory(event: any) {
    this.selectedCategory = event
      ? event.value
      : this.categoriesListData.filter((cat) => {
          return cat.code === this.selectedCategory.code;
        })[0];
    const selectedCat = this.selectedCategory.subCategories.edges.map((subcat) => subcat.node);
    this.getCategoriesSource(selectedCat);
  }

  recoverCategories() {
    this.networkService
      .getCategories()
      .then((result: Category[]) => {
        this.categoriesListData = orderBy(
          result.map((cat) => cat.categoryData),
          'code',
          'asc',
        );
        this.selectedCategory ? this.selectCategory(undefined) : null;
        this.isLoading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onCreate(): void {
    const activeModal = this.modalService.open(NewSubcategoryFormComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    const self = this;

    activeModal.result.then((res) => {
      // on close
      if (typeof res === 'boolean' && res) {
        null;
      } else if (typeof res === 'boolean' && !res) {
        null;
      }
      // OK
      else if (typeof res === 'object') {
        self.sendCreateSubCategory(res);
        self.ngOnInit();
      }
    });
  }

  onEdit(event): void {
    const activeModal = this.modalService.open(UpdateSubcategoryFormComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.selectedCategoryCode = this.selectedCategory.code;
    activeModal.componentInstance.selectedSubCategory = event;

    const self = this;
    activeModal.result.then((res) => {
      // on close
      if (typeof res === 'boolean' && res) {
        null;
      } else if (typeof res === 'boolean' && !res) {
        null;
      }
      // OK
      else if (typeof res === 'object') {
        self.sendUpdateSubCategory(res);
      }
    });
  }

  onDelete(name: string): void {
    this.deleteSubCategory(name);
  }

  sendCreateSubCategory(data: SubCategoryCreateInput) {
    const category = this.selectedCategory;
    this.networkService
      .createSubCategory(data, { code: category.code })
      .then(async () => {
        this.ngOnInit();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  sendUpdateSubCategory(event: { data: SubCategoryUpdateInput; where: SubCategoryWhereUniqueInput }) {
    this.networkService
      .updateSubCategory(event.data, event.where)
      .then(() => {
        this.ngOnInit();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteSubCategory(subCategoryCode: string) {
    const where: SubCategoryWhereUniqueInput = {
      code: subCategoryCode,
      categoryCode: this.selectedCategory.code,
    };
    this.networkService
      .deleteSubCategory(where)
      .then(() => {
        this.ngOnInit();
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
