import { gql } from 'apollo-angular';

export const archiveAccess = gql`
mutation ($where: AccessWhereUniqueInput!) {
    admin {
      archiveAccess(where: $where) {
        code
        adviseMessage {
            code
            description
            level            
        }
      }
    }
  }
`;