import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PartnerService } from '../../../../services/_index-billing.services';
import { WebAdminService } from './../../../../../../@core/services/web-admin.service';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { OrganizationNetworkService } from '../../../../../../features/entities/services/_index-entities.services';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'tgx-dashboard-orgs-with-contracts',
  templateUrl: './dashboard-orgs-with-contracts.component.html',
  styleUrls: ['./dashboard-orgs-with-contracts.component.scss'],
})
export class DashboardOrgsWithContractsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isLoading: boolean;
  totalOrgs: number;
  numOrgs: number;
  orgCodesWithoutContract: string[];

  constructor(
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private notificationService: NotificationService,
    private organizationNetworkService: OrganizationNetworkService
  ) {}

  async ngOnInit(): Promise<void> {
    this.numOrgs = 0;
    this.totalOrgs = 0;
    this.subscriptions.push(
      this.webAdminService.allOrgs.subscribe(async (orgs) => {
        this.totalOrgs = orgs?.length;

        if (orgs?.length > 0) {
          await this.partnerService
            .getAllPartnersWithSomeContract()
            .then(async (rs) => {
              this.numOrgs = rs?.length;

              const partnerCodes = rs.map((x) => x.orgCode);

              this.orgCodesWithoutContract = orgs
                .filter(function (e) {
                  return partnerCodes.indexOf(e.code.toUpperCase()) === -1;
                })
                .map((x) => x.code);
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
              this.isLoading = false;
            });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  getValue() {
    if (this.numOrgs > 0) {
      return Math.round((this.numOrgs * 100) / this.totalOrgs);
    }
    return 0;
  }

  async getCSV() {
    this.isLoading = true;

    await this.organizationNetworkService
      .getOrganizations(this.orgCodesWithoutContract, false)
      .then(async (rs) => {
        const orgs: any[] = [];

        if (rs?.edges?.length > 0) {
          rs.edges.forEach((org) => {
            orgs.push({
              'code': org.node.organizationData.code,
              'label': org.node.organizationData.label,
              'isSeller': org.node.organizationData.isSeller,
              'isBuyer': org.node.organizationData.isBuyer,
              'isPublic': org.node.organizationData.isPublic,
              'owner': org.node.organizationData.owner?.code,
              'country': org.node.organizationData.country,
            });
          });
        }

        await this.downloadCsv(orgs);

        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  async downloadCsv(orgs: any[]) {
    const headers = Object.keys(orgs[0]);

    const options = {
      fieldSeparator: ';',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: headers,
      eol: '\n',
    };

    return new ngxCsv(this.buildCSVLines(headers, orgs), 'OrganizationsWithoutBillingContract', options).getCsv();
  }

  private buildCSVLines(headers: string[], orgs: any[]): any {
    const data: any[] = [];

    orgs.forEach((org) => {
      let lineData: { [key: string]: any } = {};

      lineData = {};

      headers.forEach((header) => {
        if (org[header] !== 'null') {
          lineData[header] = org[header];
        } else {
          lineData[header] = '';
        }
      });
      data.push(lineData);
    });

    return data;
  }
}
