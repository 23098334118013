<div class="row">
    <div class="col-8">
      <tgx-dashboard-partner-types></tgx-dashboard-partner-types>
    </div>
    <div class="col-4">
        <tgx-dashboard-orgs-with-contracts></tgx-dashboard-orgs-with-contracts>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <tgx-dashboard-best-connections></tgx-dashboard-best-connections>
    </div>
 </div>
<div class="row">
    <div class="col-6">
        <tgx-dashboard-best-partners></tgx-dashboard-best-partners>
    </div>   
    <div class="col-6">
        <tgx-dashboard-contract-types></tgx-dashboard-contract-types>
    </div>
</div>

  