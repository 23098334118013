<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form focusInvalidInput #form="ngForm" [formGroup]="profileForm" (ngSubmit)="submit()">
    <nb-card>
      <nb-card-header> Profile - {{ prfCode }} </nb-card-header>
      <nb-card-body>
        <div class="row mt-2">
          <div class="col-4">
            <xen-input
              [id]="'name'"
              [type]="'string'"
              [label]="'Name of the partner'"
              [formControlName]="'name'"
              [required]="true"
              [disabled]="false"
              [validation]="true"
              [validation_status]="form.submitted && profileForm.get('name').invalid ? 'is-invalid' : ''"
              [placeholder]="'Enter a partner name...'"
              [feedback]="(form.submitted && profileForm.get('name').errors?.errorMsg) || ''"
              [icon]="false"
              [icon_placement]="'right'"
              (keyup)="onChange('name')"
            ></xen-input>
          </div>
          <div class="col-4">
            <xen-input
              [id]="'slug'"
              [type]="'string'"
              [label]="'Slug (autogenerated)'"
              [formControlName]="'slug'"
              [required]="true"
              [disabled]="false"
              [validation]="true"
              [validation_status]="form.submitted && profileForm.get('slug').invalid ? 'is-invalid' : ''"
              [feedback]="(form.submitted && profileForm.get('slug').errors?.errorMsg) || ''"
              [icon]="false"
              [icon_placement]="'right'"
              (keyup)="onChange('slug')"
            ></xen-input>
          </div>
          <div class="col-4">
            <xen-select
              [id]="'country'"
              label="Country"
              [formControlName]="'country'"
              [placeholder]="'Select a country...'"
              size="medium"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              [options]="countries"
              (change)="onChange('country')"
            ></xen-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <xen-input
              [id]="'ownerCode'"
              [type]="'string'"
              [label]="'Organization Code'"
              [formControlName]="'ownerCode'"
              [required]="true"
              [disabled]="false"
              [validation]="true"
              [validation_status]="form.submitted && profileForm.get('ownerCode').invalid ? 'is-invalid' : ''"
              [placeholder]="'Enter a organization code...'"
              [feedback]="(form.submitted && profileForm.get('ownerCode').errors?.errorMsg) || ''"
              [icon]="false"
              [icon_placement]="'right'"
              (keyup)="onChange('ownerCode')"
            ></xen-input>
          </div>
          <div class="col-4">
            <xen-input
              [id]="'marketplaceManager'"
              label="Account Manager"
              formControlName="marketplaceManager"
              [placeholder]="'Enter a valid Account Manager...'"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
              (keyup)="onChange('marketplaceManager')"
            ></xen-input>
          </div>
          <div class="col-4">
            <xen-input
              [id]="'customerEngineer'"
              label="Customer Engineer"
              formControlName="customerEngineer"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
            ></xen-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <xen-input
              [id]="'crmId'"
              [type]="'string'"
              [label]="'CRM ID'"
              [formControlName]="'crmId'"
              [required]="true"
              [disabled]="false"
              [validation]="true"
              [validation_status]="form.submitted && profileForm.get('crmId').invalid ? 'is-invalid' : ''"
              [placeholder]="'Enter the CRM ID...'"
              [feedback]="(form.submitted && profileForm.get('crmId').errors?.errorMsg) || ''"
              [icon]="false"
              [icon_placement]="'right'"
              (keyup)="onChange('crmId')"
            ></xen-input>
          </div>
          <div class="col-8">
            <xen-input
              [id]="'websiteUrl'"
              label="Website Url (Hubspot synchronized)"
              formControlName="websiteUrl"
              placeholder="Enter a valid URL..."
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
              (keyup)="onChange('websiteUrl')"
            ></xen-input>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-3">
            <!-- logo -->
            <label for="logo" class="mb-2 edit__logo">Logo</label>
            <div class="edit__wrap">
              <img id="logo" class="edit__wrap__img" [src]="getlastLogoUrl(profile)" alt="{{ profile.name }}" />
              <xen-btn
                [type]="'secondary'"
                class="edit__wrap__btn-edit-img"
                [icon]="true"
                [icon_placement]="'left'"
                [icon_snippet]="'fa-regular fa-pen-to-square'"
                [skin]="'blue'"
                (onClick)="uploadLogo(); onChange('Asset')"
              ></xen-btn>
            </div>
          </div>
          <div class="col-9">
            <xen-textarea
              [id]="'description'"
              label="Description"
              formControlName="description"
              placeholder="Enter a profile description..."
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
              (keyup)="onChange('description')"
            ></xen-textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <xen-input
              *ngIf="profile.buyerLifecycleStageReason !== null"
              [id]="'buyerLifecycleStage'"
              label="Buyer Lifecycle Stage (Hubspot synchronized)"
              formControlName="buyerLifecycleStage"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
              placement="top"
              [ngbPopover]="profile.buyerLifecycleStageReason"
              popoverTitle="Buyer Lifecycle Stage Reason"
              popoverClass="xen-popover"
              triggers="mouseenter:mouseleave"
            ></xen-input>
            <xen-input
              *ngIf="profile.buyerLifecycleStageReason === null"
              [id]="'buyerLifecycleStage'"
              label="Buyer Lifecycle Stage (Hubspot synchronized)"
              formControlName="buyerLifecycleStage"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
            ></xen-input>
          </div>
          <div class="col-6">
            <xen-input
              *ngIf="profile.sellerLifecycleStageReason !== null"
              [id]="'sellerLifecycleStage'"
              label="Seller Lifecycle Stage (Hubspot synchronized)"
              formControlName="sellerLifecycleStage"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
              placement="top"
              [ngbPopover]="profile.sellerLifecycleStageReason"
              popoverTitle="Seller Lifecycle Stage Reason"
              popoverClass="xen-popover"
              triggers="mouseenter:mouseleave"
            ></xen-input>
            <xen-input
              *ngIf="profile.sellerLifecycleStageReason === null"
              [id]="'sellerLifecycleStage'"
              label="Seller Lifecycle Stage (Hubspot synchronized)"
              formControlName="sellerLifecycleStage"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
            ></xen-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <xen-input
              [id]="'buyerPartnerStatus'"
              label="Buyer Partner Status (Hubspot synchronized)"
              formControlName="buyerPartnerStatus"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
            ></xen-input>
          </div>
          <div class="col-6">
            <xen-input
              [id]="'sellerPartnerStatus'"
              label="Seller Partner Status (Hubspot synchronized)"
              formControlName="sellerPartnerStatus"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              type="string"
              size="medium"
            ></xen-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="mb-1">Buyer Integration Type (Hubspot synchronized)</label>
            <p-multiSelect
              styleClass="xen-multiselect-dropdown"
              [options]="integrationTypeEnum.buyer"
              defaultLabel="No buyer integration type"
              [formControlName]="'buyerIntegrationType'"
              display="chip"
              (onChange)="onChange('buyerIntegrationType')"
              [style]="{ 'width': '100%' }"
            ></p-multiSelect>
          </div>
          <div class="col-6">
            <label class="mb-1">Seller Integration Type (Hubspot synchronized)</label>
            <p-multiSelect
              styleClass="xen-multiselect-dropdown"
              [options]="integrationTypeEnum.seller"
              defaultLabel="No seller integration type"
              [formControlName]="'sellerIntegrationType'"
              display="chip"
              (onChange)="onChange('sellerIntegrationType')"
              [style]="{ 'width': '100%' }"
            ></p-multiSelect>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <xen-select
              [id]="'buyerCategory'"
              label="Buyer Category (Hubspot synchronized)"
              [formControlName]="'buyerCategory'"
              [placeholder]="'Select a buyer category...'"
              size="medium"
              [required]="false"
              [options]="categoriesEnum"
              (change)="onCategoryChanges('buyerCategory')"
            ></xen-select>
          </div>
          <div class="col-6">
            <xen-select
              [id]="'sellerCategory'"
              label="Seller Category (Hubspot synchronized)"
              [formControlName]="'sellerCategory'"
              [placeholder]="'Select a seller category...'"
              size="medium"
              [required]="false"
              [options]="categoriesEnum"
              (change)="onCategoryChanges('sellerCategory')"
            ></xen-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <xen-select
              [id]="'buyerSubCategory'"
              [disabled]="!profileForm.controls.buyerCategory.value"
              label="Buyer SubCategory (Hubspot synchronized)"
              [formControlName]="'buyerSubCategory'"
              [placeholder]="'Select a buyer subCategory...'"
              size="medium"
              [required]="false"
              [validation]="true"
              [validation_status]="form.submitted && profileForm.get('buyerSubCategory').invalid ? 'is-invalid' : ''"
              [options]="subCategoriesEnum[profileForm.get('buyerCategory').value]"
              (change)="onChange('buyerSubCategory')"
            ></xen-select>
          </div>
          <div class="col-6">
            <xen-select
              [id]="'sellerSubCategory'"
              [disabled]="!profileForm.controls.sellerCategory.value"
              label="Seller SubCategory (Hubspot synchronized)"
              [formControlName]="'sellerSubCategory'"
              [placeholder]="'Select a seller subCategory...'"
              size="medium"
              [required]="false"
              [validation]="true"
              [validation_status]="form.submitted && profileForm.get('sellerSubCategory').invalid ? 'is-invalid' : ''"
              [options]="subCategoriesEnum[profileForm.get('sellerCategory').value]"
              (change)="onChange('sellerSubCategory')"
            ></xen-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <xen-checkbox
              [id]="'isDemandBridge'"
              [indeterminated]="false"
              [disabled]="false"
              [reverse]="false"
              [label]="'Is Demand Bridge? (Hubspot synchronized)'"
              [formControlName]="'isDemandBridge'"
              [validation]="false"
              [validation_status]="'is-valid'"
              (change)="onChange('isDemandBridge')"
            ></xen-checkbox>
          </div>
          <div class="col-6">
            <xen-checkbox
              [id]="'isSupplyBridge'"
              [indeterminated]="false"
              [disabled]="false"
              [reverse]="false"
              [label]="'Is Supply Bridge? (Hubspot synchronized)'"
              [formControlName]="'isSupplyBridge'"
              [validation]="false"
              [validation_status]="'is-valid'"
              (change)="onChange('isSupplyBridge')"
            ></xen-checkbox>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <xen-select
              [id]="20"
              label="Buyer Tech Bridge (Hubspot synchronized)"
              [formControlName]="'buyerTechBridge'"
              [placeholder]="'Select buyer tech bridge...'"
              size="medium"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              [options]="buyerTechBridgeList"
              (change)="onChange('buyerTechBridge')"
            ></xen-select>
          </div>
          <div class="col-6">
            <xen-select
              [id]="21"
              label="Seller Tech Bridge (Hubspot synchronized)"
              [formControlName]="'sellerTechBridge'"
              [placeholder]="'Select seller tech bridge...'"
              size="medium"
              [required]="false"
              [tooltip]="false"
              tooltip_text="This is a tooltip sample text"
              [options]="sellerTechBridgeList"
              (change)="onChange('sellerTechBridge')"
            ></xen-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="mb-1" for="buyerFirstBooking">Buyer First Booking (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="buyerFirstBooking"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
            ></p-calendar>
          </div>
          <div class="col-6">
            <label class="mb-1" for="sellerFirstBooking">Seller First Booking (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="sellerFirstBooking"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
            ></p-calendar>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="mb-1" for="buyerLastBooking">Buyer Last Booking (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="buyerLastBooking"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
            ></p-calendar>
          </div>
          <div class="col-6">
            <label class="mb-1" for="sellerLastBooking">Seller Last Booking (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="sellerLastBooking"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
            ></p-calendar>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="mb-1" for="buyerOnboardingStart">Buyer Onboarding Start Date (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="buyerOnboardingStart"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [showClear]="true"
              (onSelect)="onChange('buyerOnboardingStart')"
              (onClear)="onChange('buyerOnboardingStart')"
            ></p-calendar>
          </div>
          <div class="col-6">
            <label class="mb-1" for="sellerOnboardingStart">Seller Onboarding Start Date (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="sellerOnboardingStart"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [showClear]="true"
              (onSelect)="onChange('sellerOnboardingStart')"
              (onClear)="onChange('sellerOnboardingStart')"
            ></p-calendar>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="mb-1" for="buyerOnboardingEnd">Buyer Onboarding End Date (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="buyerOnboardingEnd"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [showClear]="true"
              (onSelect)="onChange('buyerOnboardingEnd')"
              (onClear)="onChange('buyerOnboardingEnd')"
            ></p-calendar>
          </div>
          <div class="col-6">
            <label class="mb-1" for="sellerOnboardingEnd">Seller Onboarding End Date (Hubspot synchronized)</label>
            <p-calendar
              styleClass="xen-datepicker"
              [showTime]="true"
              [hourFormat]="24"
              formControlName="sellerOnboardingEnd"
              dateFormat="yy/mm/dd"
              [showIcon]="true"
              inputId="icon"
              [showClear]="true"
              (onSelect)="onChange('sellerOnboardingEnd')"
              (onClear)="onChange('sellerOnboardingEnd')"
            ></p-calendar>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <xen-btn
    type="secondary"
    size="medium"
    [label]="'Cancel'"
    [full_width]="true"
    (click)="closeModal()"
    [id]="'profile-modal-close-button'"
  ></xen-btn>
  <xen-btn
    [disabled]="!isFormUpdated"
    type="primary"
    size="medium"
    label="Update"
    title="click here"
    [full_width]="true"
    (click)="isFormUpdated ? form.onSubmit() : null"
    [id]="'profile-modal-update-button'"
  ></xen-btn>
</div>
