import { gql } from 'apollo-angular';

export const getTotalProfiles = gql`
  query GetProfiles {
    social {
      allProfilesV2 {
        edges {
          node {
            code
            createdAt
            updatedAt
            profileData {
              owner {
                code
              }
              group {
                code
              }
              status
              crmId
              slug
              description
              websiteUrl
              name
            }
          }
        }
      }
    }
  }
`;
