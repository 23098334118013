import { gql } from 'apollo-angular';

export const createClient = gql`
mutation ($data: CreateClientInput!) {
    admin {
        createClient(data: $data) {
            clientData {
                code
                name
                isActive
                group {
                    code
                }
            }
            adviseMessage {
                code
                description
                level
            }
        }
    }
}
`;