import { gql } from 'apollo-angular';

export const addMemberToOrganization = gql`
  mutation AddMemberToOrg($data: AddMemberToOrganizationInput!) {
    admin {
      addMemberToOrganization(input: $data) {
        code
        createdAt
        updatedAt
        memberData {
          id
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
