import { Inject, Injectable } from '@angular/core';
import { CommercialTemplateResponse, PDFResponse, AgreementsResponse } from './../interfaces/_index.billing.interfaces';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { PlatformBrowserService } from '@tgx/shared/services';

@Injectable({
  providedIn: 'root',
})
export class CommercialAgreementService {
  urlBillingCalculation: string;
  private httpAttach: HttpClient;

  constructor(
    @Inject('urlBillingCalculation') urlBillingCalculation: string,
    private http: HttpClient,
    handler: HttpBackend,
    private platformBrowserService: PlatformBrowserService
  ) {
    this.urlBillingCalculation = urlBillingCalculation;
    this.httpAttach = new HttpClient(handler);
  }

  async getTemplates(): Promise<CommercialTemplateResponse> {
    const url = this.urlBillingCalculation + 'pdf-agreements/list?pdfType=template&agreementType=ALL';

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        }
      );
    });
  }

  downloadPdfTemplate(type: string, id: number): Promise<PDFResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .post<PDFResponse>(
          `${this.urlBillingCalculation}pdf-agreements/obtainPdf?mainType=${type}&Id=${id}&fileType=Base64`,
          {}
        )
        .subscribe(
          (res: PDFResponse) => {
            if (res && res.success && res.response !== null) {
              return resolve(res);
            } else if (res && !res.success && res.errors !== null && res.errors.length > 0) {
              return reject(res.errors[0].error);
            }
          },
          (err) => {
            if (err?.error?.errors?.length > 0) {
              reject(err?.error?.errors[0]?.error);
            } else if (err.error?.warnings?.length > 0) {
              reject(err.error?.warnings[0].description);
            }
          }
        );
    });
  }

  async createTemplate(pdf: any, type: string, subtype: string, version: number): Promise<any> {
    const formData = new FormData();
    formData.append('PDF', pdf);

    let url = `${this.urlBillingCalculation}pdf-agreements/load?pdfType=template&agreementType=${type}&version=${version}`;

    if (subtype && subtype !== 'None') {
      url += `&agreementSubType=${subtype}`;
    }

    return await new Promise((resolve, reject) => {
      this.httpAttach
        .post(url, formData, {
          headers: {
            Authorization: 'Bearer ' + this.platformBrowserService.getToken(),
          },
        })
        .subscribe(
          (res: any) => {
            if (res?.success) {
              resolve(res.successMessage);
            }
          },
          (err) => {
            if (err?.error?.errors?.length > 0) {
              reject(err?.error?.errors[0]?.error);
            } else if (err.error?.warnings?.length > 0) {
              reject(err.error?.warnings[0].description);
            }
          }
        );
    });
  }

  async getAgrementsByPartner(orgCode: string): Promise<AgreementsResponse> {
    const url =
      this.urlBillingCalculation + `pdf-agreements/list?pdfType=agreement&agreementType=ALL&orgCode=${orgCode}`;

    const headers = new HttpHeaders({ Accept: 'text/json' });
    const options = { headers };

    return await new Promise((resolve, reject) => {
      this.http.post(url, null, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          if (err?.error?.errors?.length > 0) {
            reject(err?.error?.errors[0]?.error);
          } else if (err.error?.warnings?.length > 0) {
            reject(err.error?.warnings[0].description);
          }
        }
      );
    });
  }

  async loadContractAgreement(
    pdf: any,
    type: string,
    subType: string,
    version: number,
    contractId: number,
    approved: boolean
  ): Promise<any> {
    const formData = new FormData();
    formData.append('PDF', pdf);

    const url = `${this.urlBillingCalculation}pdf-agreements/load?pdfType=agreement&agreementType=${type}&agreementSubType=${subType}&version=${version}&contractId=${contractId}&approved=${approved}`;

    return await new Promise((resolve, reject) => {
      this.httpAttach
        .post(url, formData, {
          headers: {
            Authorization: 'Bearer ' + this.platformBrowserService.getToken(),
          },
        })
        .subscribe(
          (res: any) => {
            if (res?.success) {
              resolve(res.successMessage);
            }
          },
          (err) => {
            if (err?.error?.errors?.length > 0) {
              reject(err?.error?.errors[0]?.error);
            } else if (err.error?.warnings?.length > 0) {
              reject(err.error?.warnings[0].description);
            }
          }
        );
    });
  }

  async updateContractAgreement(id: number, approved: boolean): Promise<any> {
    const url = `${this.urlBillingCalculation}pdf-agreements/update?agreementId=${id}&approved=${approved}`;

    return await new Promise((resolve, reject) => {
      this.httpAttach
        .post(
          url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + this.platformBrowserService.getToken(),
            },
          }
        )
        .subscribe(
          (res: any) => {
            if (res?.success) {
              resolve(res.successMessage);
            }
          },
          (err) => {
            if (err?.error?.errors?.length > 0) {
              reject(err?.error?.errors[0]?.error);
            } else if (err.error?.warnings?.length > 0) {
              reject(err.error?.warnings[0].description);
            }
          }
        );
    });
  }
}
