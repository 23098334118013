import { gql } from 'apollo-angular';

export const removeAccessShared = gql`mutation removeAccessShared($where: AccessWhereUniqueInput!) {
  admin {
    removeAccessShared(where: $where) {
      code
      adviseMessage {
        code
        description
        level
      }
    }
  }
}
`;
