import { gql } from 'apollo-angular';

export const getOrganization = gql`
  query getOrganization($codes: [ID!]) {
    admin {
      organizations(codes: $codes) {
        edges {
          node {
            organizationData {
              id
              code
              info
              label
              isBuyer
              isSeller
              country
            }
          }
        }
      }
    }
  }
`;
