import { gql } from 'apollo-angular';

export const deleteNewPricingCost = gql`
mutation DeleteNewPricingCost($where: NewPricingCostWhereUniqueInput!) {
    deleteNewPricingCost(where: $where) {
      id
    }
  }
  
`;
