import { gql } from 'apollo-angular';

export const deleteProfile = gql`
mutation DeleteProfile($where: ProfileWhereUniqueInput!) {
  social {
    deleteProfile(where: $where) {
      code
      createdAt
      updatedAt
      profileData {
        id
      }
      adviseMessage {
        code
        description
        level
      }
    }
  }
}

`;
  