<nb-card>
  <nb-card-header> Amount per currency comparing two months </nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="source"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      [autoLayout]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 33%" *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request>
        <tr>
          <td style="width: 33%" class="my-center-text">{{ request.Currency }}</td>
          <td style="width: 33%" class="my-center-text">{{ request.StartDate | date : 'dd/MM/yyyy' }}</td>
          <td style="width: 33%" class="my-center-text">{{ request.TotalAmount }}</td>
          <td></td>
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No amounts by currency found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
