import { gql } from 'apollo-angular';

export const createPartner = gql`
mutation CreatePartner($data: PartnerCreateInput!) {
  createPartner(data: $data) {
    id
    orgCode
  }
}

`;
