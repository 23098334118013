<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="memberForm">
    <nb-card>
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-12" *ngIf="!bulkingMode">
            <div class="form-group">
              <label class="mb-1" for="code"
                >{{ !isCreating ? 'Member ID' : 'Member code (email)' }}
                <span *ngIf="!isCreating" class="mandatory ml-1">*</span>
              </label>
              <tooltip-error [element]="memberForm.get('code')" [errors]="memberForm.get('code').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                [ngClass]="{
                  'form-control-danger': memberForm.get('code').errors && memberForm.get('code').touched,
                  'input-disabled': !isCreating
                }"
                name="code"
                formControlName="code"
              />
            </div>
          </div>
          <div class="col-3" *ngIf="bulkingMode">
            <div class="form-group">
              <label class="mb-1" for="isActive">Is Active</label>
              <tooltip-error [element]="memberForm.get('isActive')" [errors]="memberForm.get('isActive').errors">
              </tooltip-error>
              <nb-checkbox
                class="form-control"
                [ngClass]="{
                  'form-control-danger': memberForm.get('isActive').errors && memberForm.get('isActive').touched
                }"
                name="isActive"
                formControlName="isActive"
                disabled
              ></nb-checkbox>
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="isCreating && !bulkingMode">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="memberStatus">Status</label>
              <p-dropdown
                [options]="memberStatusOptions"
                formControlName="memberStatus"
                [appendTo]="'body'"
                baseZIndex="999"
                optionLabel="label"
                filterBy="label"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="memberRole">Role</label>
              <p-dropdown
                [options]="memberRoleOptions"
                formControlName="memberRole"
                [appendTo]="'body'"
                baseZIndex="999"
                optionLabel="label"
                filterBy="label"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="!isCreating && !bulkingMode">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{ 'form-control-danger': memberForm.get('label').errors && memberForm.get('label').touched }"
            >
              <label class="mb-1" for="label">Label</label>
              <tooltip-error [element]="memberForm.get('label')" [errors]="memberForm.get('label').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="label"
                [ngClass]="{ 'form-control-danger': memberForm.get('label').errors && memberForm.get('label').touched }"
                placeholder="Enter a label..."
                name="label"
              />
            </div>
          </div>
          <div class="col-3">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': memberForm.get('firstName').errors && memberForm.get('firstName').touched
              }"
            >
              <label class="mb-1" for="firstName">FirstName</label>
              <tooltip-error [element]="memberForm.get('firstName')" [errors]="memberForm.get('firstName').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="firstName"
                [ngClass]="{
                  'form-control-danger': memberForm.get('firstName').errors && memberForm.get('firstName').touched
                }"
                placeholder="Enter first name.."
                name="firstName"
              />
            </div>
          </div>
          <div class="col-3">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': memberForm.get('lastName').errors && memberForm.get('lastName').touched
              }"
            >
              <label class="mb-1" for="lastName">LastName</label>
              <tooltip-error [element]="memberForm.get('lastName')" [errors]="memberForm.get('lastName').errors">
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="lastName"
                [ngClass]="{
                  'form-control-danger': memberForm.get('lastName').errors && memberForm.get('lastName').touched
                }"
                placeholder="Enter last name.."
                name="lastName"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="!isCreating && !bulkingMode">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="memberStatus">Status</label>
              <p-dropdown
                [options]="memberStatusOptions"
                formControlName="memberStatus"
                [appendTo]="'body'"
                baseZIndex="999"
                optionLabel="label"
                filterBy="label"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="memberRole">Role</label>
              <p-dropdown
                [options]="memberRoleOptions"
                formControlName="memberRole"
                [appendTo]="'body'"
                baseZIndex="999"
                optionLabel="label"
                filterBy="label"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">
    {{ bulkingMode ? 'Cancel' : 'Close' }}
  </button>
  <button
    *ngIf="bulkingMode"
    class="btn btn-md btn-primary"
    [disabled]="memberForm.invalid || bulkingMode"
    (click)="onSave()"
  >
    {{ isCreating ? 'Link' : bulkingMode ? 'Bulk' : 'Update' }}
  </button>
</div>
