import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PlatformAuthService, PlatformBrowserService, PlatformInfoService } from '@tgx/shared/services';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  // List of domains that will get the authentication header set by the interceptor
  private readonly bearerAuthenticatedDomains = ['travelgate', 'travelgatex', 'localhost'];

  constructor(
    @Inject(PlatformBrowserService) private platformBrowserService: PlatformBrowserService,
    private platformInfoService: PlatformInfoService,
    private platformAuthService: PlatformAuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = new URL(req.url).hostname;
    const domainRequiresAuthentication = this.bearerAuthenticatedDomains.some((domain) => reqUrl.includes(domain));

    const requestAppPath = this.platformInfoService.getAppPath();

    let headers: HttpHeaders = req.headers;
    if (domainRequiresAuthentication) {
      if (this.platformBrowserService.isTokenExpired()) {
        return this.sessionExpired();
      }

      if (!req.headers.getAll('Authorization')?.length) {
        headers = headers.set('Authorization', `Bearer ${this.platformBrowserService.getToken()}`);
      }

      if (!req.headers.getAll('Content-Type')?.length) {
        headers = headers.set('Content-Type', 'application/json');
      }

      const query = req?.body?.query;

      if (query?.startsWith('mutation')) {
        headers = headers.set('TGX-Audit', '1');
      }

      if (this.isB2BQuery(query)) {
        headers = headers.set('TGX-Origin', 'B2B');

        if (this.isB2BCancelOrBookingQuery(query)) {
          headers = headers.set('TGX-B2B', '1');
        }
      }
    }

    const reqClone = req.clone({ headers: headers });

    return next.handle(reqClone).pipe(
      tap(() => {}),
      catchError((err: any) => {
        const error = { ...err, requestAppPath: requestAppPath };
        return throwError(() => error);
      }),
    );
  }

  private sessionExpired() {
    this.platformAuthService.logout();
    return throwError(() => new Error('Token expired'));
  }

  private isB2BQuery(query: string): boolean {
    return query?.startsWith('query b2b') || query?.startsWith('mutation b2b');
  }

  private isB2BCancelOrBookingQuery(query: string): boolean {
    return query?.startsWith('mutation b2bCancelBooking') || query?.startsWith('query b2bBooking');
  }
}
