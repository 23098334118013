import { Component, Input } from '@angular/core';

@Component({
  selector: 'xen-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent  {
  @Input() caption: string;
  @Input() full_width: boolean;
  
  public get classes(): Array<string> {
    return [
            this.full_width ? 'is-full' : ''
            ];
  }

  ngOnInit(): void {}
}

