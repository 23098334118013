import { Injectable } from '@angular/core';
import {
  InvoiceBreakdown,
  InvoiceBreakdownWhereInput,
  ObjectWhereUniqueInputById,
  InvoiceBreakdownUpdateInput,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class InvoiceBreakdownService {
  constructor(private apollo: Apollo) {}

  async getInvoiceBreakdowns(invoiceId: number): Promise<InvoiceBreakdown[]> {
    const where: InvoiceBreakdownWhereInput = { invoice: { equals: invoiceId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getInvoiceBreakdowns,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).invoiceBreakdowns);
          } else {
            reject(res);
          }
        });
    });
  }

  async updateInvoiceBreakdown(
    data: InvoiceBreakdownUpdateInput,
    where: ObjectWhereUniqueInputById
  ): Promise<InvoiceBreakdown> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateInvoiceBreadown,
          variables: { data: data, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateInvoiceBreakdown);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  async deleteInvoiceBreakdown(where: ObjectWhereUniqueInputById): Promise<InvoiceBreakdown> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteInvoiceBreadown,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteInvoiceBreakdown);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
