<xen-modal subtitle="Please make sure you think about the action" title="Invalidate Master">
  <div xenModalBody>
    <p class="mb-0 text-center">
      Are you sure to invalidate
      <span>{{ validate.name }}, {{ findNameCountry(validate.country) }}, {{ validate.address }}</span>
      ?
    </p>
  </div>
  <div xenModalFooter>
    <div class="d-flex align-items-center gap-2">
      <xen-btn (click)="close()" label="Cancel" size="medium" skin="gray" type="flat"></xen-btn>
      <xen-btn
        (click)="invalidateMaster(validate.id)"
        label="Invalidate this master"
        size="medium"
        skin="red"
        type="primary"
      ></xen-btn>
    </div>
  </div>
</xen-modal>
