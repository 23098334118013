import { gql } from 'apollo-angular';

export const createL2BCost = gql`
mutation CreateL2bCost($data: BookingL2BCostCreateInput!) {
    createBookingL2BCost(data: $data) {
      id
    }
  }
  
`;
