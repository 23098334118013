import { Component } from '@angular/core';

@Component({
  selector: 'tgx-billing-partner',
  template: `
    <tgx-partners-table></tgx-partners-table>
  `,
})
export class PartnerComponent {
}
