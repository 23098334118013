import { gql } from 'apollo-angular';

export const updateOrg = gql`
  mutation updateOrg($group: UpdateGroupInput!) {
    admin {
      updateGroup(group: $group) {
        groupData {
          code
          label
        }
      }
    }
  }
`;

export const updateOrganization = gql`
  mutation UpdateOrganization($organization: UpdateOrganizationInput!) {
    admin {
      updateOrganization(organization: $organization) {
        code
        organizationData {
          label
          country
        }
      }
    }
  }
`;
