import { gql } from 'apollo-angular';

export const getCategories = gql`
query getCategories{
	social {
		allCategories {
			edges {
				node {
					createdAt
					updatedAt
					categoryData {
						code
						description
						subCategories {
							edges {
								node {
									createdAt
									updatedAt
									code
									subCategoryData {
										description
										defaultIsBuyer
										defaultIsSeller
										defaultDemandBridge
										defaultSupplyBridge
									}
								}
							}
						}
					}
				}
			}
			adviseMessage {
				code
				description
				level
			}
		}
	}
}
`;

