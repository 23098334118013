import { AdviseMessage } from "../../../entities/interfaces/models/advise-message.interface"

export interface SubCategoryData{
  code: string
  defaultDemandBridge: boolean
  defaultIsBuyer: boolean
  defaultIsSeller: boolean
  defaultSupplyBridge: boolean
  description: string
}

export interface SubCategory{
  adviseMessage: [AdviseMessage]
  code: string
  createdAt: Date
  subCategoryData: SubCategoryData
  updatedAt: Date
}

