import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tgx-admin-clients-legacy-connection-modal',
  templateUrl: './clients-legacy-connection-modal.component.html',
  styleUrls: ['./clients-legacy-connection-modal.component.scss'],
})
export class ClientsLegacyConnectionModalComponent implements OnInit {
  @Input() header: string;
  @Input() hubProvider: any;
  @Input() isCreating: boolean;

  isLoading: boolean;

  public clientLegacyConnectionForm: UntypedFormGroup;

  constructor(private activeModal: NgbActiveModal, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.clientLegacyConnectionForm = this.fb.group({
      hubProvider: this.fb.control(this.hubProvider?.name || ''),
      isActive: this.fb.control(false),
    });
    if (!this.isCreating) {
      this.clientLegacyConnectionForm.get('hubProvider').disable();
      this.clientLegacyConnectionForm.get('isActive').disable();
    }
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    if (this.isCreating) {
      this.activeModal.close();
    } else {
      this.activeModal.close();
    }
  }
}
