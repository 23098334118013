import { gql } from 'apollo-angular';

export const createLegacyConnection = gql`
  mutation($input: LegacyAccessInput!) {
    admin {
      createLegacyAccess(input: $input) {
        code
        error {
          code
          type
          description
        }
      }
    }
  }
`;
