import { gql } from 'apollo-angular';

export const getProfiles = gql`
  query GetProfile($where: ProfileWhereInput!) {
    social {
      allProfilesV2(where: $where) {
        edges {
          node {
            code
            createdAt
            updatedAt
            profileData {
              name
              owner {
                code
              }
              group {
                code
              }
              crmId
              description
              slug
              websiteUrl
              internalExtendedInfo {
                customerEngineer
                marketplaceManager
              }
              buyerLifecycleStage
              buyerLifecycleStageReason
              sellerLifecycleStage
              sellerLifecycleStageReason
              buyerPartnerStatus
              sellerPartnerStatus
              buyerOnboardingStart
              buyerOnboardingEnd
              sellerOnboardingStart
              sellerOnboardingEnd
              buyerFirstBooking
              buyerLastBooking
              sellerFirstBooking
              sellerLastBooking
              sellerIntegrationType
              buyerIntegrationType
              buyerOrgType
              sellerOrgType
              buyerTechPlatformOrgType
              sellerTechPlatformOrgType
              buyerCategory
              sellerCategory
              buyerSubCategory
              sellerSubCategory
              isDemandBridge
              isSupplyBridge
              buyerTechBridge
              sellerTechBridge
              country
            }
          }
        }
      }
    }
  }
`;
