import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { buildNeededColorsEchart } from '../../../../../../@core/utils/utils';
import { ContractService } from '../../../../services/_index-billing.services';

@Component({
  selector: 'tgx-dashboard-partner-types',
  templateUrl: './dashboard-partner-types.component.html',
  styleUrls: ['./dashboard-partner-types.component.scss'],
})
export class DashboardPartnerTypesComponent implements OnInit, OnDestroy {
  options: EChartsOption;
  subscriptions: Subscription[] = [];
  private config: any;

  isLoading: boolean;

  partnersType: any[];

  constructor(
    private theme: NbThemeService,
    private contractService: ContractService,
    private notificationService: NotificationService
  ) {
    this.subscriptions.push(
      this.theme.getJsTheme().subscribe((config) => {
        this.config = config;
      })
    );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.contractService
      .getContractsGroupedByType()
      .then((ent) => {
        this.partnersType = [];
        if (ent?.length > 0) {
          ent.forEach((tp) => {
            if (tp.name.startsWith('OTHER')) {
              const index = this.partnersType.findIndex((x) => x.name === 'OTHER');
              if (index === -1) {
                this.partnersType.push({ name: 'OTHER', quantity: tp.numCount });
              } else {
                this.partnersType[index].quantity += tp.numCount;
              }
            } else {
              this.partnersType.push({ name: tp.name, quantity: tp.numCount });
            }
          });
          this.buildOptions();
        } else {
          this.options = null;
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  buildOptions() {
    const colors = this.config.variables;
    const echarts: any = this.config.variables.echarts;

    const types = this.buildDiferentPartnersType();

    const neededColors: any[] = buildNeededColorsEchart(colors, types.length);

    this.options = {
      backgroundColor: echarts.bg,
      color: neededColors,
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        data: types.map((x) => x.name),
        textStyle: {
          color: echarts.textColor,
        },
      },
      series: [
        {
          name: 'Partner Type',
          type: 'pie',
          radius: '80%',
          center: ['50%', '50%'],
          data: types,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: echarts.itemHoverShadowColor,
            },
          },
          label: {
            color: echarts.textColor,
          },
          labelLine: {
            lineStyle: {
              color: echarts.axisLineColor,
            },
          },
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  buildDiferentPartnersType() {
    const types = [];
    this.partnersType.forEach((partType) => {
      types.push({ name: partType.name, value: partType.quantity });
    });
    return types;
  }
}
