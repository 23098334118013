import { gql } from 'apollo-angular';

export const updateNewPricingCost = gql`
mutation UpdateNewPricingCost(
    $data: NewPricingCostUpdateInput!
    $where: NewPricingCostWhereUniqueInput!
  ) {
    updateNewPricingCost(data: $data, where: $where) {
      id
    }
  }
  
`;
