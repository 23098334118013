import { gql } from 'apollo-angular';

export const getAllSuppliersGroups = gql`
query($where:SupplierWhereInput) {
  admin {
    allSuppliers(where:$where) {
      totalCount
      edges {
        node {
          supplierData {
            code
            groups{
              edges{
                node{
                  groupData{
                    code
                  }
                }
              }
            }
          }
        }
      }
      adviseMessage {
        code
        description
        level
      }      
    }
  }
}
`;