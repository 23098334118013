<div class="xen-subtitle-page"> 
    <xen-link
        *ngIf="back"
        (click)="subtitleLink($event)" 
        size="medium" 
        weight="bold" 
        [label]="label" 
        [href]="href"
        [icon]="true" 
        icon_snippet="fas fa-chevron-left" 
        icon_placement="left"
        theme="blue"
        >
    </xen-link>
    <p class="xen-subtitle-page__title">{{title}}</p>
  </div>