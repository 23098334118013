import { gql } from 'apollo-angular';

export const updateProvider = gql`
mutation($input: ProviderInput!) {
  admin {
    createProvider(input:$input){
      code
      adviseMessage{
        code
        description
        level
      }
    }
  }
}
`;
