import { gql } from 'apollo-angular';

export const updateInvoiceBreakdown = gql`
mutation EditInvoiceBreakdown(
    $data: InvoiceBreakdownUpdateInput!
    $where: InvoiceBreakdownWhereUniqueInput!
  ) {
    updateInvoiceBreakdown(data: $data, where: $where) {
      id
    }
  }
  
`;
