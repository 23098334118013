import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as UserActions from './actions';

export const userReducer = createReducer(
  initialState,

  on(UserActions.setAccessToken, (state, { accessToken }) => ({
    ...state,
    accessToken,
  }))
);
