import { gql } from 'apollo-angular';

export const updateOrganization = gql`
  mutation UpdateOrganization($data: UpdateOrganizationInput!) {
    admin {
      updateOrganization(organization: $data) {
        code
        createdAt
        updatedAt
        organizationData {
          label
          partner {
            code
          }
        }

        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
