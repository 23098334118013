import { Injectable } from '@angular/core';
import {
  BillingAccount,
  BillingAccountWhereInput,
  BillingAccountCreateInput,
  ObjectWhereUniqueInputById,
  BillingAccountUpdateInput,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class BillingAccountService {
  constructor(private apollo: Apollo) {}

  async getBillingAccounts(orgCode: string): Promise<BillingAccount[]> {
    const where: BillingAccountWhereInput = { Partner: { is: { orgCode: { equals: orgCode } } } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getBillingAccounts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res && res.data) {
            resolve(((res || {}).data || {}).billingAccounts);
          } else {
            reject(res);
          }
        });
    });
  }

  async createBillingAccount(create: BillingAccountCreateInput): Promise<BillingAccount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createBillingAccount,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createBillingAccount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  async updateBillingAccount(
    update: BillingAccountUpdateInput,
    where: ObjectWhereUniqueInputById
  ): Promise<BillingAccount> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateBillingAccount,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateBillingAccount);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
