<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'200px'"></tgx-spinner>
      <nb-card>
        <nb-card-header>
         {{ isEditor ? 'Select a contract' : 'Warning' }}
        </nb-card-header>
        <nb-card-body *ngIf="!isEditor">
          You are in the billing group, but you must be an admin to view and execute this operation.
        </nb-card-body>
        <nb-card-body *ngIf="isEditor">
            <div class="row mb-3">
                <div class="col-1">
                  <label class="mt-2">Contract: </label>
                  </div>
                <div class="col-6">
                      <p-dropdown [options]="contractsSelector" [appendTo]="'body'" baseZIndex="999" [disabled]="contractsSelector[0]?.code === null"
                      [(ngModel)]="selectedContract" optionLabel="label" filterBy="label" (onHide)="resetForm(); getConnections();">
                        <ng-template pTemplate="selectedItem">
                          <div *ngIf="selectedContract">
                            <div>{{selectedContract?.label}}</div>
                          </div>
                        </ng-template>
                        <ng-template let-ci pTemplate="ci">
                          <div>
                            <div>{{ci.label}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                </div>         
               </div>
        </nb-card-body>
      </nb-card>

      <nb-card *ngIf="contractsSelector[0]?.code !== null">
        <nb-card-header>
         Create a Invoice
        </nb-card-header>
        <nb-card-body *ngIf="!isLoading">
        <form [formGroup]="createForm">
          <div class="row mb-3">
            <div class="col-4">
                <div class="form-group">
                    <label class="mb-1" for="activationDateTime">Billing Type
                        <span class="mandatory ml-1">*</span>
                    </label>
                    <tooltip-error [element]="createForm.get('billingType')" [errors]="createForm.get('billingType').errors"></tooltip-error>                    
                    <p-dropdown (onHide)="changeBillingType()" [options]="billingTypeSelector" [appendTo]="'body'" baseZIndex="999"
                    class="form-control" formControlName="billingType" optionLabel="label" filterBy="label">
                      <ng-template pTemplate="selectedItem">
                        <div *ngIf="createForm.get('billingType').value">
                            <div>{{createForm.get('billingType').value.label}}</div>
                        </div>                          
                      </ng-template>
                      <ng-template let-ci pTemplate="ci">
                        <div>
                          <div>{{ci.label}}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                </div>

          </div>   
          <div class="col-4">
            <div class="form-group">
                <label class="mb-1" for="activationDateTime">Billing Charge</label>   
                <p-dropdown [options]="billingChargeSelector" [appendTo]="'body'" baseZIndex="999"
                class="form-control" formControlName="billingCharge" optionLabel="label" filterBy="label">
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="createForm.get('billingCharge').value">
                        <div>{{createForm.get('billingCharge').value.label}}</div>
                    </div>    
                  </ng-template>
                  <ng-template let-ci pTemplate="ci">
                    <div>
                      <div>{{ci.label}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>                       
                </div>
            </div>  
            <div class="col-4">
              <div class="form-group">
                <label for="contractConnectionId">Connection(s)</label>
                <p-multiSelect
                class="form-control" formControlName="contractConnectionId" [options]="connectionsSelector"
                defaultLabel="Select Connections" optionLabel="name" [maxSelectedLabels]="1" selectedItemsLabel="{0} connections selected">
              </p-multiSelect>                
              </div>
            </div>                       
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="form-group">
                <label class="mb-1" for="start">Start Date
                  <span class="mandatory ml-1">*</span>
                </label>
                <tooltip-error [element]="createForm.get('start')" [errors]="createForm.get('start').errors"></tooltip-error>
                  <p-calendar [appendTo]="'body'" baseZIndex="999" class="form-control p-0 border-0 w-100" formControlName="start" dateFormat="dd/mm/yy" [showIcon]="true" inputId="icon" [readonlyInput]="true"
                  placeholder="Enter a date.."></p-calendar>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="mb-1" for="endDate">End Date
                    <span class="mandatory ml-1">*</span>
                </label>
                <tooltip-error [element]="createForm.get('end')" [errors]="createForm.get('end').errors"></tooltip-error>
                <p-calendar [disabled]="!createForm.get('start').value" [minDate]="createForm.get('start').value" [appendTo]="'body'" baseZIndex="999" class="form-control p-0 border-0 w-100" formControlName="end" dateFormat="dd/mm/yy" [showIcon]="true" inputId="icon" [readonlyInput]="true"
                placeholder="Enter a date.."></p-calendar>
              </div>
            </div>
          </div>
          <div class="row mt-4" *ngIf="isFree">
            <div class="col-6">
                <div class="form-group">
                  <label class="mb-1" for="concept">Concept
                    <span class="mandatory ml-1">*</span>
                  </label>
                  <tooltip-error [element]="createForm.get('concept')" [errors]="createForm.get('concept').errors"></tooltip-error>
                  <input type="text" class="form-control" name="concept" formControlName="concept">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                  <label class="mb-1" for="amount">Amount
                    <span class="mandatory ml-1">*</span>
                  </label>
                  <tooltip-error [element]="createForm.get('amount')" [errors]="createForm.get('amount').errors"></tooltip-error>
                  <input type="number" class="form-control" formControlName="amount" name="amount">
                </div>
            </div>            
          </div>
          <div class="modal-footer mt-4">
            <button class="btn btn-md btn-danger" (click)="resetForm()">Reset</button>
            <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="runCreate()">Bill</button>
          </div>
        </form>
        </nb-card-body>
        <nb-card-body *ngIf="isLoading">
            Your csv is being generated, please be patient..       
        </nb-card-body>
      </nb-card>


