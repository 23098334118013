import { gql } from 'apollo-angular';

export const createGroupContent = gql`
mutation ($data: GroupContentCreateInput!) {
	admin {
		createGroupContent(data: $data) {
			code
			adviseMessage {
				code
				description
			}
		}
	}
}`;