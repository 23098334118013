import { Permission } from '@tgx/shared/interfaces';
import { MembersRole, PartnerType, WebComponent, WebFeature, PermissionsService as Service } from '@tgx/shared/enums';
// Use constants for roles and partner types if they're reused throughout the application
const EDITOR = MembersRole.EDITOR;
const ADMIN = MembersRole.ADMIN;
const BUYER = PartnerType.BUYER;
const SELLER = PartnerType.SELLER;

// Improved visibilityPermissions with type aliases and constants
export const VisibilityPermissions = new Map<WebComponent, Map<WebFeature, Permission>>([
  [
    WebComponent.MY_CONNECTIONS,
    new Map([
      [WebFeature.CREATE_ACCESS, { role: ADMIN, service: Service.ENTITIES, partnerType: null }],
      [WebFeature.EDIT_ACCESS, { role: EDITOR, service: Service.ENTITIES, partnerType: null }],
      [WebFeature.COPY_ACCESS, { role: ADMIN, service: Service.ENTITIES, partnerType: null }],
      [WebFeature.CREATE_CONNECTION, { role: ADMIN, service: Service.ENTITIES, partnerType: null }],
    ]),
  ],
  [
    WebComponent.API_SETTINGS,
    new Map([[WebFeature.FULL_COMPONENT, { role: null, service: null, partnerType: BUYER }]]),
  ],
  [
    WebComponent.SETTINGS,
    new Map([[WebFeature.CREATE_APIKEY, { role: ADMIN, service: Service.ENTITIES, partnerType: null }]]),
  ],
  [WebComponent.B2B, new Map([[WebFeature.FULL_COMPONENT, { role: null, service: null, partnerType: BUYER }]])],
]);
