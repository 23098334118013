import { Component } from '@angular/core';
import { ReportService } from '../../../../services/_index-billing.services';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'tgx-report-all-contracts',
  templateUrl: './report-all-contracts.component.html',
  styleUrls: ['./report-all-contracts.component.scss'],
})
export class BillingReportAllContractsComponent {
  isLoading: boolean;

  constructor(private notificationService: NotificationService, private reportService: ReportService) {}

  async getCSV() {
    this.isLoading = true;
    await this.reportService
      .getAllContracts()
      .then(async (rs) => {
        await this.downloadCsv(rs);
        this.notificationService.success('CSV successfully generated', 'Success');
        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  async downloadCsv(rows: any[]) {
    const headers = Object.keys(rows[0]);

    const options = {
      fieldSeparator: ';',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: headers,
      eol: '\n',
    };

    return new ngxCsv(this.buildCSVLines(headers, rows), 'All_Contracts_' + new Date().toISOString(), options).getCsv();
  }

  private buildCSVLines(headers: string[], rows: any[]): any {
    const data: any[] = [];

    rows.forEach((row) => {
      let lineData: { [key: string]: any } = {};

      lineData = {};

      headers.forEach((header) => {
        lineData[header] = row[header] !== null && row[header] !== 'null' ? row[header] : '';
      });
      data.push(lineData);
    });

    return data;
  }
}
