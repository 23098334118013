import { gql } from 'apollo-angular';

export const createProfile = gql`
mutation CreateProfile($data: ProfileCreateInput!) {
    social {
      createProfile(data: $data) {
        code
        createdAt
        updatedAt
        profileData {
          id
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
  