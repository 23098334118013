import { gql } from 'apollo-angular';

export const getCosts = gql`
query GetCosts($where: CostWhereInput!) {
    costs(where:$where) {
      id
      startDate
      endDate
      min
      max
      amount
      isPush
      createdAt
      updatedAt
      chargeType
      paymentType
    }
  }
`;
