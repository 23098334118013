import { gql } from 'apollo-angular';

export const updateDefaultCost = gql`
mutation UpdateDefaultCost(
  $data: DefaultCostUpdateInput!
  $where: DefaultCostWhereUniqueInput!
) {
  updateDefaultCost(data: $data, where: $where) {
    id
  }
}

`;
