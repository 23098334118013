import { gql } from 'apollo-angular';

export const removeOrganizationPartner = gql`
  mutation RemoveOrganizationPartner($orgCode: ID!) {
    admin {
      removePartnerFromOrganization(orgCode: $orgCode) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
