<!-- <div class="toast-container position-fixed top-0 end-0 p-3">
  <div
    class="xen-toast {{ toast.type }}"
    role="alert"
    data-bs-animation="true"
    aria-live="assertive"
    aria-atomic="true"
    *ngFor="let toast of toasts"
  >
    <div class="toast-body">
      <strong>{{ toast.header }}</strong>
      <br />
      <span>{{ toast.body }}</span>
    </div>
    <button
      type="button"
      class="btn-close btn-close-white ms-auto me-2 me-2 mt-2"
      data-bs-dismiss="toast"
      aria-label="Close"
      (click)="onClose(toast)"
    ></button>
  </div>
</div> -->
<div class="toast-container position-fixed top-0 end-0 p-3">
  <ng-container *ngFor="let toast of toasts">
    <xen-toast
      header="{{ toast.header }}"
      content="{{ toast.body }}"
      [type]="
        toast.type === 'bg-danger'
          ? 'error'
          : toast.type === 'bg-primary'
          ? 'info'
          : toast.type === 'bg-success'
          ? 'success'
          : toast.type === 'bg-warning'
          ? 'warning'
          : 'default'
      "
    ></xen-toast>
  </ng-container>
</div>
