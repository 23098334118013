import { gql } from 'apollo-angular';

export const deleteClient = gql`
mutation ($where: ClientWhereUniqueInput!) {
    admin {
        deleteClient(where: $where) {
            clientData {
                code
                name
                isActive
                group {
                    code
                }
            }
            adviseMessage {
                code
                description
                level
            }
        }
    }
}

`;
