import { gql } from 'apollo-angular';

export const changeStatusClientLegacyConnection = gql`
  mutation($input: LegacyAccessInput!) {
    admin {
      updateLegacyAccess(input: $input) {
        code
        error {
          code
          type
          description
        }
      }
    }
  }
`;
