<xen-modal subtitle="Please select one master" title="Remap this property into a similar validated master">
  <div xenModalBody>
    <xen-summary [size]="'small'" [label]="'Summary'" [icon]="false" [type]="'success'">
      <div #content>
        <p class="txt mb-1"><i class="fa-regular fa-light fa-link"></i> You are remaping this hotel:</p>
        <ul class="mb-1">
          <li>{{ selectedHotel.name }}, {{ findNameCountry(selectedHotel.country) }}, {{ selectedHotel.address }}</li>
        </ul>
      </div>
    </xen-summary>
    <p class="tgx-fontsize-secondary text-secondary mt-3">
      Please select which master you want to move the property to:
    </p>

    <!-- table -->
    <table class="xen-table is-full table-mapping" *ngIf="!isLoading && similarMasterHotels; else loadingTemplate">
      <thead>
        <tr>
          <th *ngFor="let head of tHead">
            <a>{{ head.label }}</a>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="first-tbody" *ngFor="let similar of similarMasterHotels">
          <td class="d-flex align-items-start">
            <input
              type="radio"
              class="form-check-input me-2"
              id="radio-button-{{ similar.id }}"
              name="similarMaster"
              [value]="similar"
              [(ngModel)]="selectedMaster"
              (click)="onSelectionChange(similar, $event.target.value)"
            />
            <label>
              <i class="fa-solid fa-circle-check me-1 pt-1" [ngClass]="'text-success'"></i>
              {{ similar.name }}
            </label>
          </td>
          <td>{{ similar.id }}</td>
          <td>{{ similar.location }}</td>
          <td>{{ similar.country }}</td>
          <td>{{ similar.address }}</td>
          <td class="text-end"></td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="similarMasterHotels?.length === 0" class="mt-3">{{ noSimilarMastersFound() }}</p>
    <xen-pagination
      *ngIf="similarMasterHotels?.length > 3"
      [(page)]="page"
      [pageSize]="3"
      [collectionSize]="similarMasterHotels.length"
      [isDisabled]="isLoading"
      [maxSize]="3"
      (pageChange)="onPageChange($event)"
    ></xen-pagination>
  </div>
  <div xenModalFooter>
    <div class="d-flex align-items-center gap-2">
      <xen-btn (click)="close()" label="Cancel" size="medium" skin="gray" type="flat"></xen-btn>
      <xen-btn
        label="Remap into selected master"
        size="medium"
        skin="blue"
        type="primary"
        [loading]="false"
        [disabled]="isLoading || selectedMaster === undefined || similarMasterHotels.length === 0"
        (onClick)="unmapHotelFromMaster()"
      ></xen-btn>
    </div>
  </div>
</xen-modal>

<!-- Loading -->
<ng-template #loadingTemplate>
  <table class="xen-table is-full">
    <thead>
      <tr>
        <th *ngFor="let head of tHead">{{ head.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [1, 2, 3]">
        <td *ngFor="let item of [1, 2, 3, 4, 5]">
          <p-skeleton width="10rem"></p-skeleton>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
