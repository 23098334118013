import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { ngxCsv } from 'ngx-csv';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReportService } from '../../../../services/_index-billing.services';

@Component({
  selector: 'tgx-report-invoiced-data-by-month',
  templateUrl: './report-invoiced-data-by-month.component.html',
  styleUrls: ['./report-invoiced-data-by-month.component.scss'],
})
export class BillingReportAllInvoicedDataByMonthComponent implements OnInit {
  isLoading: boolean;
  public dateForm: UntypedFormGroup;

  dateStr: string;

  constructor(
    private notificationService: NotificationService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.dateForm = this.fb.group({
      date: ['', Validators.required],
    });
  }

  async getCSV() {
    this.isLoading = true;
    this.buildDateStr();

    await this.reportService
      .getAllInvoicedDataByMonth(this.dateStr)
      .then(async (rs) => {
        await this.downloadCsv(rs);
        this.notificationService.success('CSV successfully generated', 'Success');
        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  buildDateStr() {
    const date = <Date>this.dateForm.get('date').value;

    this.dateStr = '';

    let dateStr = date.getFullYear().toString();
    const month = date.getMonth() + 1;

    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }
    dateStr += '-01 00:00:00.000';

    this.dateStr = dateStr;
  }

  async downloadCsv(rows: any[]) {
    const headers = Object.keys(rows[0]);

    const options = {
      fieldSeparator: ';',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: headers,
      eol: '\n',
    };

    return new ngxCsv(this.buildCSVLines(headers, rows), 'All_Invoiced_Data_' + this.dateStr, options).getCsv();
  }

  private buildCSVLines(headers: string[], rows: any[]): any {
    const data: any[] = [];

    rows.forEach((row) => {
      let lineData: { [key: string]: any } = {};

      lineData = {};

      headers.forEach((header) => {
        lineData[header] = row[header] !== null && row[header] !== 'null' ? row[header] : '';
      });
      data.push(lineData);
    });

    return data;
  }
}
