import { gql } from 'apollo-angular';

export const archiveSupplier = gql`
mutation ($where: SupplierWhereUniqueInput!) {
    admin {
      archiveSupplier(where: $where) {
        code
        adviseMessage {
            code
            description
            level            
        }
      }
    }
  }
`;