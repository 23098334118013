import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { combineLatest, interval } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdatesService {
  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
    // This event is fired each time the Service Worker detects an update
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          // Handles when a new version is detected on the server
          break;
        case 'VERSION_READY':
          // Handles when a nw version has been downloaded and is ready to be installed
          // Prompts the user and reloads the page
          if (environment.production) {
            this.updates.activateUpdate().then(() => document.location.reload());
          }
          break;
        case 'VERSION_INSTALLATION_FAILED':
          // Handles when the new version failed to be installed
          console.warn(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  /**
   * Checks for app updates every 30 minutes.
   */
  updatesPolling() {
    if (this.updates.isEnabled) {
      const subscriptions = [this.appRef.isStable, interval(5000)];
      combineLatest(subscriptions).subscribe({
        next: () => {
          this.updates.checkForUpdate();
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }
}
