import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { configLocalStorage } from '@tgx/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class PlatformBrowserService {
  private _isBrowser: boolean;

  itemsToSave: Map<string, string> = new Map([
    ['tour', ''],
    ['cookies-confirmed', ''],
    ['redirectUrl', ''],
  ]);

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this._isBrowser = !isPlatformServer(platformId);
  }

  isBrowser(): boolean {
    return this._isBrowser;
  }

  getCookieDomain(): string | undefined {
    if (document.domain === 'localhost') return undefined;

    const currentDomain = window.location.hostname;
    let baseDomain = 'travelgate.com';

    if (currentDomain.includes('x')) {
      baseDomain = 'travelgatex.com';
    }

    return baseDomain;
  }

  GetItemFromLocalStorage(key: string): string {
    if (this._isBrowser) {
      const item = localStorage.getItem(key);
      return item ? item : '';
    }
    return '';
  }

  SetItemInLocalStorage(key: string, value: string) {
    if (this._isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  setTour() {
    this.SetItemInLocalStorage(
      'tour',
      JSON.stringify({
        'stats': false,
        'logging': false,
        'logging-table': false,
        'alerts': false,
        'alerts-connections': false,
        'alerts-configuration': false,
        'alerts-notifications': false,
        'speed': false,
        'traffic-optimization': false,
        'traffic-optimization-hotels': false,
        'traffic-optimization-charts': false,
      })
    );
  }

  dontShowAgain(tourName) {
    const item = this.GetItemFromLocalStorage('tour');
    if (item) {
      const tour = JSON.parse(item);
      tour[tourName] = true;
      this.SetItemInLocalStorage('tour', JSON.stringify(tour));
    }
  }

  ClearLocalStorage() {
    if (this._isBrowser) {
      this.saveItems();
      localStorage.clear();
      this.setItemsFromMap();
    }
  }

  private setItemsFromMap() {
    this.itemsToSave.forEach((value, key) => {
      if (value) {
        localStorage.setItem(key, value);
      }
    });
  }

  private saveItems() {
    for (const key of this.itemsToSave.keys()) {
      const itemStored = localStorage.getItem(key);
      if (itemStored) {
        this.itemsToSave.set(key, itemStored);
      }
    }
  }

  RemoveItemFromLocalStorage(key: string) {
    if (this._isBrowser) {
      localStorage.removeItem(key);
    }
  }

  getToken(): string {
    if (this.isBrowser()) {
      return (
        this.GetItemFromLocalStorage(configLocalStorage.idTokenImpersonation) ||
        this.GetItemFromLocalStorage(configLocalStorage.idToken)
      );
    }
    return '';
  }

  /**
   * Checks if there is any information related to stored login information for the user,
   * in case this information is pressent it checks if the expiration date has passed.
   *
   * @returns boolean indicating whether the user's token has expired or not
   */
  isTokenExpired(): boolean {
    const expiration = this.GetItemFromLocalStorage(configLocalStorage.expiresAt);
    const token = this.GetItemFromLocalStorage(configLocalStorage.accessToken);

    if (expiration && token) {
      const expiresAt = JSON.parse(expiration);
      return new Date().getTime() > expiresAt;
    }
    // If there's no storage set yet that means that the user hasn't logged in yet
    // so it has to return false in order for the user to log in
    return false;
  }

  getBrowserInfo(): { name: string; version: string } {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE ', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      // eslint-disable-next-line
      if (tem != null) {
        tem = tem.slice(1);
        return { name: tem[0].replace('OPR', 'Opera'), version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // eslint-disable-next-line
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return { name: M[0], version: M[1] };
  }
}
