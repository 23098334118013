import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService, JwtPermissionsService } from './_index-core.services';
import { OrganizationSelector } from './../shared/interfaces/_index-shared-interfaces';
import { OrganizationNetworkService } from './../../features/entities/services/_index-entities.services';
import { AdminUserPermissions, ModuleType, ActionType } from '../interfaces/_index.interfaces';
import { SupplierSelector } from '../shared/interfaces/models/supplier-selector.interface';
import { SuppliersService } from '../../features/entities/services/suppliers.service';
import { MemberRole, PermissionTable, ServiceRole } from '../utils/jwt-tools.utils';

@Injectable()
export class WebAdminService {
  orgSelected = new BehaviorSubject<OrganizationSelector>(null);
  allOrgs = new BehaviorSubject<OrganizationSelector[]>(null);
  allTeams = new BehaviorSubject<OrganizationSelector[]>(null);

  supplierSelected = new BehaviorSubject<SupplierSelector>(null);
  allSuppliers = new BehaviorSubject<SupplierSelector[]>(null);

  userPermissions = new BehaviorSubject<AdminUserPermissions>(null);
  userRole = new BehaviorSubject<string>(null);
  role: string;

  constructor(
    private organizationNetworkService: OrganizationNetworkService,
    private supplierService: SuppliersService,
    private notificationService: NotificationService,
    private jwtPermissionsService: JwtPermissionsService,
  ) {
    this.jwtPermissionsService.permissionTable.subscribe(async (pt) => {
      if (pt) {
        this.userPermissions.next(this.setUserPermissions(pt));
        await this.getMinInfo();
      } else {
        this.userPermissions.next(null);
      }
    });
  }

  async getMinInfo() {
    await this.supplierService
      .getSuppliersSelector()
      .then((suppliers) => {
        if (suppliers.length > 0) {
          this.supplierSelected.next(suppliers[0]);
          this.allSuppliers.next(suppliers);
        }
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });

    await this.organizationNetworkService
      .getOrganizationsSelector()
      .then((orgs) => {
        if (orgs.length > 0) {
          this.orgSelected.next(orgs[0]);
          this.allOrgs.next(orgs);
        }
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });

    await this.organizationNetworkService
      .getTeamsSelector()
      .then((teams) => {
        if (teams.length > 0) {
          this.orgSelected.next(teams[0]);
          this.allTeams.next(teams);
        }
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  changeOrganization(org: OrganizationSelector) {
    if (org?.code !== this.orgSelected?.value?.code) {
      this.orgSelected.next(org);
    }
  }

  setUserPermissions(permissionTable: PermissionTable): AdminUserPermissions {
    const permissions: AdminUserPermissions = {
      dashboard: this.memberRoleToActionType(permissionTable.orgRole, false),
      entities: this.memberRoleToActionType(permissionTable.orgRole, false),
      network: this.memberRoleToActionType(permissionTable.orgRole, false),
      billing: undefined,
      banner: undefined,
    };

    // Billing
    const billingService = permissionTable.serviceRoles.filter((x) => x.s === ServiceRole.BILLING);
    if (billingService?.length > 0 || this.isOrgEditor(permissionTable.orgRole)) {
      const role = billingService?.length > 0 ? billingService[0].r : permissionTable.orgRole;
      permissions.billing = this.memberRoleToActionType(role, true);
    }

    // Banner
    const bannerService = permissionTable.serviceRoles.filter((x) => x.s === ServiceRole.BANNER);
    if (
      (bannerService?.length > 0 && bannerService[0].r === MemberRole.ADMIN) ||
      permissionTable.orgRole === MemberRole.ADMIN
    ) {
      permissions.banner = this.memberRoleToActionType(MemberRole.ADMIN, false);
    }
    return permissions;
  }

  memberRoleToActionType(role: MemberRole, isBilling: boolean): ActionType {
    // No todos los de TG (Todo cristo está como EDITOR puede tocar billing, por lo tanto bajamos un escalón).
    // En caso de billing sería así:
    // 1) VIEWER (ni pintamos menú), 2) EDITOR > VIEWER, 3) ADMIN > EDITOR (máxima acción en admin).
    if (role === MemberRole.NONE || role === MemberRole.VIEWER) {
      return !isBilling ? ActionType.VIEWER : null;
    }

    if (role === MemberRole.OWNER || role === MemberRole.ADMIN) {
      return ActionType.EDITOR;
    }

    if (role === MemberRole.EDITOR) {
      return !isBilling ? ActionType.EDITOR : ActionType.VIEWER;
    }
    return null;
  }

  isOrgEditor(role: MemberRole): boolean {
    return role === MemberRole.ADMIN || role === MemberRole.EDITOR || role === MemberRole.OWNER;
  }

  emitUserTypevalue(module: ModuleType) {
    switch (module) {
      case ModuleType.BILLING:
        if (this.userPermissions?.value?.billing) {
          if (this.userPermissions?.value.billing === ActionType.EDITOR) {
            this.userRole.next('WRITER');
          } else {
            this.userRole.next('READER');
          }
        }
      case ModuleType.BANNER:
        if (this.userPermissions?.value?.banner) {
          if (this.userPermissions?.value.banner === ActionType.EDITOR) {
            this.userRole.next('WRITER');
          } else {
            this.userRole.next('READER');
          }
        }
      default:
        // DASHBOARD, ENTITIES y NETWORK van sobre el role a nivel de org y todo es igual, niramos el que sea y ya.
        if (this.userPermissions?.value?.dashboard) {
          if (this.userPermissions?.value.dashboard === ActionType.EDITOR) {
            this.userRole.next('WRITER');
          } else {
            this.userRole.next('READER');
          }
        }
    }
  }

  isBillingEditorOrAdmin(avoidEmit?: boolean): boolean {
    if (this.userPermissions?.value?.billing) {
      if (this.userPermissions.value.billing === ActionType.EDITOR) {
        if (!avoidEmit) {
          this.userRole.next('WRITER');
        }
        return true;
      } else {
        if (!avoidEmit) {
          this.userRole.next('READER');
        }
      }
    }
    return false;
  }
}
