import { gql } from 'apollo-angular';

export const updateBuyerOnboardingDate = gql`
    mutation($where: ProfileWhereUniqueInput!, $data: ProfileUpdateInput!) {
        social {
            updateProfile(where: $where, data: $data) {
                profileData {
                buyerOnboardingStart
                buyerOnboardingEnd
                sellerOnboardingStart
                sellerOnboardingEnd
                }
                adviseMessage {
                code
                level
                description
                }
            }
        }
    }
`;
