import { gql } from 'apollo-angular';

export const updateContract = gql`
mutation UpdateContract(
  $where: ContractWhereUniqueInput!
  $data: ContractUpdateInput!
) {
  updateContract(where: $where, data: $data) {
    id
  }
}

`;
