import { gql } from 'apollo-angular';

export const deactivateMemberIAM = gql`
  mutation deactivateMemberIAM($memberCode: ID!) {
    admin {
      deactivateMemberIAM(member: { code: $memberCode }) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
