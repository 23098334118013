import { gql } from 'apollo-angular';

export const updateCost = gql`
mutation UpdateCost($data: CostUpdateInput!, $where: CostWhereUniqueInput!) {
    updateCost(data: $data, where: $where) {
      id
    }
  }
  
`;
