<div class="xen-meta-app">
  <div class="d-flex align-items-start flex-wrap mb-2">
    <xen-icon-app
      *ngIf="appType !== 'billing'"
      width="38"
      font_size="32"
      type="{{ appType }}"
      class="me-2"
    ></xen-icon-app>
    <div>
      <h3 class="mb-0 xen-meta-app__title">{{ appTitle }}</h3>
      <p class="mb-0 xen-meta-app__subtitle">{{ appSubtitle }}</p>
    </div>
  </div>
  <p class="xen-meta-app__txt mb-2">{{ appInfo }}</p>
  <div class="d-grid gap-3">
    <!-- <xen-btn type="secondary" size="medium" label="{{ buttonLabel || 'More information'}}" [full_width]="true"
            [icon]="true" icon_placement="right" icon_snippet="far fa-arrow-up-right-from-square" skin="gray"
            (click)="goLink()"></xen-btn>
        <xen-btn type="secondary" size="medium" label="TGX Academy" [full_width]="true" [icon]="true"
            icon_placement="right" icon_snippet="fa-solid fa-video" skin="gray" (click)="openModal(tutorial)"></xen-btn> -->
    <a class="xen-meta-app__button d-flex align-items-center justify-content-between" (click)="goLink()">
      <div class="d-flex align-items-center">
        <i class="fa-duotone fa-square-info xen-meta-app__button__icon me-2"></i>
        More info
      </div>
      <i class="fa-regular fa-arrow-up-right-from-square xen-meta-app__button__icon-two"></i>
    </a>
    <a
      *ngIf="tutorialUrlSafe && haveTutorial"
      class="xen-meta-app__button d-flex align-items-center justify-content-between"
      (click)="openModal(tutorial)"
    >
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-video xen-meta-app__button__icon me-2"></i>
        TGX Academy
      </div>
      <i class="fa-regular fa-chevron-right xen-meta-app__button__icon-two"></i>
    </a>
  </div>
</div>

<ng-template #tutorial let-modal>
  <xen-modal size="xl" title="TGX Academy" subtitle="Video tutorial how to use our app">
    <div xenModalBody>
      <iframe
        width="100%"
        height="460"
        [src]="tutorialUrlSafe"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div xenModalFooter>
      <div class="d-flex align-items-center">
        <xen-btn type="flat" size="medium" label="Close" skin="gray" (click)="closeModal()"></xen-btn>
      </div>
    </div>
  </xen-modal>
</ng-template>
