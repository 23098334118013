<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="costForm">
    <nb-card>
      <nb-card-header>
        Data
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="id">ID</label>
              <input type="text" class="form-control" name="id" formControlName="id">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group"[ngClass]="{ 'form-control-danger' : costForm.get('amount').errors && costForm.get('amount').touched }">
              <label class="mb-1" for="amount">Amount
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error [element]="costForm.get('amount')" [errors]="costForm.get('amount').errors"></tooltip-error>
              <input type="number" class="form-control" formControlName="amount"
                [ngClass]="{ 'form-control-danger' : costForm.get('amount').errors && costForm.get('amount').touched }" name="amount">
            </div>
          </div> 
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <div class="form-group"[ngClass]="{ 'form-control-danger' : costForm.get('minl2b').errors && costForm.get('minl2b').touched }">
            <label class="mb-1" for="minl2b">Minimum L2B
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error [element]="costForm.get('minl2b')" [errors]="costForm.get('minl2b').errors"></tooltip-error>
            <input type="number" class="form-control" formControlName="minl2b"
              [ngClass]="{ 'form-control-danger' : costForm.get('minl2b').errors && costForm.get('minl2b').touched }" name="minl2b">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group"[ngClass]="{ 'form-control-danger' : costForm.get('maxl2b').errors && costForm.get('maxl2b').touched }">
            <label class="mb-1" for="maxl2b">Maximum L2B</label>
            <input type="number" class="form-control" formControlName="maxl2b"
              [ngClass]="{ 'form-control-danger' : costForm.get('maxl2b').errors && costForm.get('maxl2b').touched }" name="maxl2b">
          </div>
        </div>                                  
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <div class="form-group"[ngClass]="{ 'form-control-danger' : costForm.get('minBookings').errors && costForm.get('minBookings').touched }">
            <label class="mb-1" for="minBookings">Minimum Bookings
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error [element]="costForm.get('minBookings')" [errors]="costForm.get('minBookings').errors"></tooltip-error>
            <input type="number" class="form-control" formControlName="minBookings"
              [ngClass]="{ 'form-control-danger' : costForm.get('minBookings').errors && costForm.get('minBookings').touched }" name="minBookings">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group"[ngClass]="{ 'form-control-danger' : costForm.get('maxBookings').errors && costForm.get('maxBookings').touched }">
            <label class="mb-1" for="maxBookings">Maximum Bookings</label>
            <input type="number" class="form-control" formControlName="maxBookings"
              [ngClass]="{ 'form-control-danger' : costForm.get('maxBookings').errors && costForm.get('maxBookings').touched }" name="maxBookings">
          </div>
        </div>                                  
      </div>      
      <div class="row mt-4">
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="paymentType">Payment Type
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error [element]="costForm.get('paymentType')"
              [errors]="costForm.get('paymentType').errors"></tooltip-error>
            <div class="form-group">
              <p-dropdown (onHide)="validType('paymentType')" [options]="paymentTypes" [appendTo]="'body'" baseZIndex="999"
                formControlName="paymentType" optionLabel="label" filterBy="label">
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="costForm.get('paymentType').value">
                    <div>{{costForm.get('paymentType').value.label}}</div>
                  </div>
                </ng-template>
                <ng-template let-ci pTemplate="ci">
                  <div>
                    <div>{{ci.label}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>                                  
      </div>      
      </nb-card-body>
    </nb-card>
  </form>
</div>


<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">{{isEditor ? 'Cancel' : 'Close' }}</button>
  <button *ngIf="isEditor" class="btn btn-md btn-primary" [disabled]="costForm.invalid"
    (click)="onSave()">{{isCreating ? 'Create' : 'Update'}}</button>
</div>
