import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad } from '@angular/router';
import { AuthService, PlatformAuthService, PlatformInfoService } from '@tgx/shared/services';
// import { PlatformBrowserService } from '../services/platform.service';

@Injectable()
export class LoginGuard implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private platformAuthService: PlatformAuthService,
    private platformInfoService: PlatformInfoService
  ) {}

  /**
   * Only lets user to enter when it's logged in.
   * canLoad avoid to load routes or modules if is not logged in for this guard.
   * canActivate load all routes but only can access if the function returns true.
   */
  // eslint-disable-next-line
  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.platformAuthService.isAuthenticated()) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line
  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.platformAuthService.isAuthenticated()) {
      this.platformInfoService.isLogging$.next(false);
      return true;
    } else if (route.data['redirectAuth0'] === true) {
      this.authService.handleAuthentication();
    }
    this.platformInfoService.isLogging$.next(true);
    return false;
  }
}
