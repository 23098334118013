import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'xen-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() id: string;
  @Input() formControlName = '';
  @Input() value = '';
  @Input() required: boolean;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
  @Input() tooltip_placement: string;
  @Input() type: 'text' | 'tel' | 'email' | 'password' | 'number' | 'time' = 'text';
  @Input() placeholder: string;
  @Input() validation: boolean;
  @Input() validation_status: string;
  @Input() feedback: string;
  @Input() disabled: boolean;
  @Input() icon: boolean;
  @Input() readOnly: boolean;
  @Input() icon_snippet: string;
  @Input() icon_placement: string;
  @Input() formGroup: string;
  @Input() size: 'small' | 'medium' = 'medium';

  form: FormGroup;

  onChange() {}
  onTouched() {}
  isDisabled = false;

  tooltipText: string;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;

    if (this.disabled) {
      this.form.controls[this.formControlName].disable();
    }
  }

  ngOnChanges(): void {
    if (this.disabled && this.form && !this.form.get(this.formControlName).disabled) {
      this.form.get(this.formControlName).disable();
    } else if (this.form && !this.form.get(this.formControlName).enabled) {
      this.form.get(this.formControlName).enable();
    }
  }

  public get labelClasses(): Array<string> {
    return [this.required ? 'is-required' : ''];
  }
  public get inputClasses(): Array<string> {
    return [
      'form-control',
      this.icon && this.icon_placement ? `form-control--icon-${this.icon_placement}` : '',
      this.validation && this.validation_status ? `${this.validation_status}` : '',
      `xen-input__input--${this.size}`,
    ];
  }
  public get iconClasses(): Array<string> {
    return [
      `xen-input__icon-${this.icon_placement}`,
      this.validation && this.icon_placement === 'right' ? `xen-input__icon-right-val` : '',
    ];
  }
  public get feedbackClasses(): Array<string> {
    return [
      this.validation && this.validation_status === 'is-valid' ? 'valid-feedback' : '',
      this.validation && this.validation_status === 'is-invalid' ? 'invalid-feedback' : '',
    ];
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled) {
    this.isDisabled = isDisabled;
  }
}
