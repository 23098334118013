import { Injectable } from '@angular/core';
import { AdviseMessage } from './interfaces/adviseMessage.interface';
import { Toast } from './interfaces/toast.interface';

@Injectable()
export class ToastsService {
  toasts: Toast[] = [];
  i = 0;

  addToast(
    header: string,
    type: 'bg-success' | 'bg-primary' | 'bg-danger' | 'bg-warning' = 'bg-success',
    body: string = null,
    delay?: number,
    cleanErrorStack: boolean = false
  ) {
    const toast = {
      type: 'bg-success',
      header: header,
      body: body,
    } as Toast;
    if (type === 'bg-primary' || type === 'bg-danger' || type === 'bg-warning') {
      toast.type = type;
    }
    if (cleanErrorStack) {
      this.toasts = [toast];
    } else {
      this.toasts.push(toast);
    }
    this.i++;
    const toastDelay = delay !== undefined && delay !== null ? delay : 5;
    if (toastDelay !== -1) {
      setTimeout(() => {
        const index = this.toasts.findIndex((x) => x === toast);
        if (index !== -1) {
          this.toasts.splice(index, 1);
        }
      }, toastDelay * 1000);
    }
  }

  addToastAdviseMessage(adviseMessage: AdviseMessage) {
    let description = adviseMessage.description;
    if (adviseMessage.correlationID) {
      description += `(${adviseMessage.correlationID})`;
    }
    switch (adviseMessage.level) {
      case 'WARN':
        this.addToast(description, 'bg-warning');
        break;
      case 'INFO':
        this.addToast(description, 'bg-primary');
        break;
      case 'ERROR':
      default:
        this.addToast(description, 'bg-danger');
    }
  }

  error(message: string, header: string = 'Error!', cleanErrorStack: boolean = false) {
    this.addToast(header, 'bg-danger', message, 5, cleanErrorStack);
  }

  handleGatewayAndGraphqlErrors(err) {
    let errorFound = false;
    if (err.networkError && err.networkError.error && err.networkError.error.error) {
      this.handleError(err.networkError.error.error);
      errorFound = true;
    }
    if (
      err.networkError &&
      err.networkError.error &&
      err.networkError.error.errors &&
      err.networkError.error.errors.length > 0
    ) {
      errorFound = true;
      err.networkError.error.errors.forEach((e) => {
        if (e.message && e.message !== '') {
          this.handleError(e.message);
        }
      });
    }
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
      errorFound = true;
      err.graphQLErrors.forEach((e) => {
        this.handleError(e);
      });
    }
    if (!errorFound) {
      this.handleError(err);
    }
  }

  public handleError(error): void {
    if (error instanceof Response) {
      this.error(error.statusText);
    } else if (typeof error === 'string') {
      this.error(error.toString());
    } else if (error instanceof TypeError) {
      this.error(error.message);
    } else if (error.hasOwnProperty('code') && error.hasOwnProperty('description')) {
      this.error(error.description, `Error ${error.code}!`);
    } else if (error.error) {
      this.error(error.error, 'Error!');
    } else if (error.message) {
      this.error(error.message);
    } else if (error.hasOwnProperty('status') && error.hasOwnProperty('error')) {
      this.error(error.error.error, `Error ${error.status}!`);
    } else {
      console.log(error, error.constructor.name, Object.keys(error));
    }
  }
}
