<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="groupContentContextForm">
    <nb-card>
      <nb-card-header> Group Content | Context </nb-card-header>
      <nb-card-body>
        <div class="row"></div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="label">Group Code</label>
              <input
                type="text"
                class="form-control"
                formControlName="groupCode"
                [ngClass]="{
                  'form-control-danger':
                    groupContentContextForm.get('groupCode')?.errors && groupContentContextForm.get('groupCode').touched
                }"
                placeholder="Enter a valid Group Code"
                name="groupCode"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  groupContentContextForm.get('contextCode')?.errors &&
                  groupContentContextForm.get('contextCode').touched
              }"
            >
              <label class="mb-1" for="label">Context Code</label>
              <input
                type="text"
                class="form-control"
                formControlName="contextCode"
                [ngClass]="{
                  'form-control-danger':
                    groupContentContextForm.get('contextCode')?.errors &&
                    groupContentContextForm.get('contextCode').touched
                }"
                placeholder="Enter a valid Context Code"
                name="contextCode"
              />
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="groupContentContextForm.invalid" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
