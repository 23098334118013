<nb-card size="small">
    <nb-card-header>Best 5 Connections</nb-card-header>
    <nb-card-body>
      <tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'150px'"></tgx-spinner>
      <ngx-charts-advanced-pie-chart
      [scheme]="colorScheme"
      [results]="connections">
    </ngx-charts-advanced-pie-chart>
    </nb-card-body>
  </nb-card>
  
