import { AdviseMessage } from "../../../entities/interfaces/models/advise-message.interface"
import { PageInfo } from "../_index-network.interfaces"
import { SubCategory } from "./sub-category.interface"

export interface SubCategoryEdge{
  cursor:string
  node:SubCategory
}

export interface SubCategoryConnection{
  adviseMessage: [AdviseMessage]
  edges: [SubCategoryEdge]
  pageInfo: PageInfo
  totalCount: number
}

export interface CategoryData{
  code: string
  description: string
  subCategories: SubCategoryConnection
}

export interface Category{
  adviseMessage: [AdviseMessage]
  code: string
  createdAt: Date
  categoryData: CategoryData
  updatedAt: Date
}

