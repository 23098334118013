import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../@core/services/notification.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { CommercialTypeTemplate } from '../../../../interfaces/_index.billing.interfaces';
import { ContractTypeService, CommercialAgreementService } from '../../../../services/_index-billing.services';

@Component({
  selector: 'tgx-admin-billing-agreement-template-modal',
  styleUrls: ['./commercial-templates-modal.component.scss'],
  templateUrl: 'commercial-templates-modal.component.html',
})
export class BillingAgreementCommercialTemplateModalComponent implements OnInit {
  @Input() header: string;

  public templateForm: UntypedFormGroup;

  typeOptions: Options[] = [{ code: null, label: 'Select One' }];
  container: any;

  subTypeOptions: Options[] = [{ code: null, label: 'None' }];

  isLoading = false;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private readonly commercialAgreementService: CommercialAgreementService,
    private readonly contractTypeService: ContractTypeService
  ) {}

  async ngOnInit() {
    this.templateForm = this.fb.group({
      typeTemplate: [null, Validators.required],
      subTypeTemplate: [],
      version: [0, Validators.required],
    });

    Object.keys(CommercialTypeTemplate).forEach((value) => {
      this.typeOptions.push({ code: value, label: value });
    });

    this.templateForm.get('typeTemplate').setValue(this.typeOptions[0]);

    await this.getContractTypes();

    this.templateForm.get('subTypeTemplate').setValue(this.subTypeOptions[0]);
  }

  async getContractTypes() {
    await this.contractTypeService
      .getContractTypes()
      .then((rs) => {
        rs?.forEach((ct) => {
          this.subTypeOptions.push({ code: ct.id.toString(), label: ct.name });
        });
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    this.isLoading = true;
    await this.commercialAgreementService
      .createTemplate(
        this.container,
        this.templateForm.get('typeTemplate').value.code,
        this.templateForm.get('subTypeTemplate').value.label,
        this.templateForm.get('version').value
      )
      .then((rs) => {
        this.notificationService.success('Template succesfully created', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  validDropdown() {
    if (!this.templateForm.get('typeTemplate').value.code) {
      this.templateForm.get('typeTemplate').setErrors({ 'Required': true });
    } else {
      this.templateForm.get('typeTemplate').setErrors(null);
      if (this.templateForm.get('typeTemplate').value.code === 'OTHER') {
        if (!this.templateForm.get('subTypeTemplate').value.code) {
          this.templateForm.get('subTypeTemplate').setErrors({ 'Required': true });
        } else {
          this.templateForm.get('subTypeTemplate').setErrors(null);
        }
      }
    }
  }

  validForm(): boolean {
    return (
      this.templateForm.get('typeTemplate').value.code &&
      this.templateForm.get('version').value >= 1 &&
      ((!this.templateForm.get('subTypeTemplate').value.code &&
        this.templateForm.get('typeTemplate').value.code !== 'OTHER') ||
        (this.templateForm.get('typeTemplate').value.code === 'OTHER' &&
          this.templateForm.get('subTypeTemplate').value.code) ||
        (this.templateForm.get('typeTemplate').value.code !== 'OTHER' &&
          this.templateForm.get('subTypeTemplate').value.code)) &&
      this.container &&
      this.templateForm.valid
    );
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file && this.isValidPdfFile(file)) {
      this.container = file;
    } else {
      this.notificationService.error('Invalid pdf file.');
    }
  }

  isValidPdfFile(file: any) {
    return file.name.endsWith('.pdf');
  }
}
