<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel">{{title}}</h4>
</div>
<div class="modal-body">
  <div>
    <p class="desc">{{message}}</p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="onDelete($event.target.value)" value="cancel">Cancel</button>
  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onDelete($event.target.value)" value="delete">{{buttonOkTitle}}</button>
</div>
