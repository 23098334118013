import { SharedModule } from './../../../../@core/shared/shared.module';
import { DashboardComponent } from './components/dashboard.component';
import { DashboardPartnerTypesComponent } from './components/dashboard-partner-types/dashboard-partner-types.component';
import { DashboardContractTypesComponent } from './components/dashboard-contract-types/dashboard-contract-types.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbCardModule, NbProgressBarModule } from '@nebular/theme';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule } from '@angular/forms';
import { DashboardRoutingModule } from './dashboard.routing-module';
import { DashboardOrgsWithContractsComponent } from './components/dashboard-orgs-with-contracts/dashboard-orgs-with-contracts.component';
import { DashboardBestConnectionsComponent } from './components/dashboard-best-connections/dashboard-best-connections.component';
import { DashboardBestPartnersComponent } from './components/dashboard-best-partners/dashboard-best-partners.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

  @NgModule({
    imports: [
      DashboardRoutingModule,
      SharedModule,
      FormsModule,
      NbCardModule,
      CommonModule,
      NbProgressBarModule,
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
      }),
      NgxChartsModule
    ],
    declarations: [
       DashboardComponent,
       DashboardContractTypesComponent,
       DashboardPartnerTypesComponent,
       DashboardOrgsWithContractsComponent,
       DashboardBestConnectionsComponent,
       DashboardBestPartnersComponent

    ],
  })
  export class DashboardBillingModule { }