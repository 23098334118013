import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'xen-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() size: string;
  @Input() trigger_label: string;
  @Input() trigger_classes: string;
  @Input() trigger_type: 'link' | 'button' = 'link';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttons = [];

  // closeResult: string;

  close() {
    this.activeModal.dismiss();
    this.modalService.dismissAll();
  }

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) {}

  ngOnInit(): void {}
}
