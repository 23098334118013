import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../@core/services/notification.service';
import { ContractConnectionService } from './../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';
import {
  ContractConnection,
  ContractConnectionCloneInput,
  ObjectWhereUniqueInputById,
  ConnectionType,
} from '../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-connections-clone-modal',
  styleUrls: ['./connections-clone-modal.component.scss'],
  templateUrl: 'connections-clone-modal.component.html',
})
export class BillingConnectionCloneModalComponent implements OnInit {
  @Input() connection: any = null;
  @Input() header: string;

  connectionAux: ContractConnection;
  ended = false;

  public cloneForm: UntypedFormGroup;

  connectionTypes: Options[] = [{ code: null, label: 'Select One' }];

  isLoading = false;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private contractConnectionService: ContractConnectionService
  ) {}

  async ngOnInit() {
    this.buildForm();
    this.buildSelects();
    this.cloneForm.get('connectionType').setValue(this.connectionTypes[0]);
  }

  buildForm() {
    this.cloneForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      connectionType: ['', Validators.required],
    });
  }

  buildSelects() {
    Object.keys(ConnectionType).forEach((value) => {
      this.connectionTypes.push({ code: value, label: value });
    });
  }

  closeModal() {
    this.connection = clone(this.connectionAux);
    this.activeModal.close(true);
  }

  async onSave() {
    const createData: ContractConnectionCloneInput = {
      startDate: '',
      endDate: '',
      connectionType: this.cloneForm.get('connectionType').value.code,
    };

    let date = new Date(this.cloneForm.get('startDate').value);
    let dateStr = date.getFullYear().toString();
    let month = date.getMonth() + 1;
    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }
    let day = date.getDate();
    if (day < 10) {
      dateStr += '-0' + day.toString();
    } else {
      dateStr += '-' + day.toString();
    }
    dateStr += 'T00:00:00.000Z';

    createData.startDate = dateStr;

    date = new Date(this.cloneForm.get('endDate').value);
    dateStr = date.getFullYear().toString();
    month = date.getMonth() + 1;
    if (month < 10) {
      dateStr += '-0' + month.toString();
    } else {
      dateStr += '-' + month.toString();
    }
    day = date.getDate();
    if (day < 10) {
      dateStr += '-0' + day.toString();
    } else {
      dateStr += '-' + day.toString();
    }
    dateStr += 'T23:59:59.000Z';
    createData.endDate = dateStr;

    const where: ObjectWhereUniqueInputById = {
      id: this.connection.id,
    };

    await this.contractConnectionService
      .cloneConnection(createData, where)
      .then((rs) => {
        this.notificationService.success('Contract connection succesfully cloned', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        this.activeModal.close(false);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  validForm(): boolean {
    return this.cloneForm?.get('connectionType').value.code && this.cloneForm.valid;
  }
}
