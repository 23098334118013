export enum AssetType {
  BANNER = 'BANNER',
  DOCUMENT = 'DOCUMENT',
  ICON = 'ICON',
  LOGO = 'LOGO',
}

export interface ProfileData {
  code: string;
  name: string;
  slug: string;
  owner: {
    code: string;
  };
  group: {
    code: string;
  };
  crmId: string;
  websiteUrl?: string;
  description?: string;
  assets: [
    {
      url: string;
      handle: string;
      id: number;
      type: AssetType;
    },
  ];
  buyerOnboardingStart: Date;
  buyerOnboardingEnd: Date;
  sellerOnboardingStart: Date;
  sellerOnboardingEnd: Date;
  buyerFirstBooking: Date;
  buyerLastBooking: Date;
  sellerFirstBooking: Date;
  sellerLastBooking: Date;
  buyerPartnerStatus: string;
  sellerPartnerStatus: string;
  sellerIntegrationType: string[];
  buyerIntegrationType: string[];
  buyerOrgType: string;
  sellerOrgType: string;
  buyerTechPlatformOrgType: string;
  sellerTechPlatformOrgType: string;
  buyerCategory: string;
  sellerCategory: string;
  buyerSubCategory: string;
  sellerSubCategory: string;
  isBuyer: boolean;
  isSeller: boolean;
  isDemandBridge: boolean;
  isSupplyBridge: boolean;
  buyerTechBridge: string;
  sellerTechBridge: string;
  country: string;
  internalExtendedInfo: {
    customerEngineer: string;
    marketplaceManager: string;
  };
  buyerLifecycleStage: lifecycle_stage;
  sellerLifecycleStage: lifecycle_stage;
  buyerLifecycleStageReason: string;
  sellerLifecycleStageReason: string;
  status: status_profile;
}
export interface Profile {
  code: string;
  groupCode?: string;
  profileData: ProfileData;
  createdAt: Date;
  updatedAt: Date;
}

export enum lifecycle_stage {
  LEAD = 'LEAD',
  OPPORTUNITY = 'OPPORTUNITY',
  OPPORTUNITY_LOST = 'OPPORTUNITY_LOST',
  ONBOARDING_IMPLEMENTATION = 'ONBOARDING_IMPLEMENTATION',
  ONBOARDING_ENGAGEMENT = 'ONBOARDING_ENGAGEMENT',
  ONBOARDING_LOST = 'ONBOARDING_LOST',
  CUSTOMER = 'CUSTOMER',
  EX_CUSTOMER = 'EX_CUSTOMER',
  PARTNER = 'PARTNER',
}

export enum status_profile {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}
