import { Subscription } from 'rxjs';
import { NbThemeService } from '@nebular/theme';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildNeededColorsEchart } from '../../../../../../@core/utils/utils';
import { EChartsOption } from 'echarts';
import { ContractService } from '../../../../services/_index-billing.services';
import { NotificationService } from '../../../../../../@core/services/notification.service';

@Component({
  selector: 'tgx-dashboard-contract-types',
  templateUrl: './dashboard-contract-types.component.html',
  styleUrls: ['./dashboard-contract-types.component.scss'],
})
export class DashboardContractTypesComponent implements OnInit, OnDestroy {
  options: EChartsOption;
  subscriptions: Subscription[] = [];

  private config: any;

  contractTypesText: string[];
  contractQuantities: number[];

  isLoading: boolean;

  contractsType: any[];

  constructor(
    private theme: NbThemeService,
    private contractService: ContractService,
    private notificationService: NotificationService
  ) {
    this.subscriptions.push(
      this.theme.getJsTheme().subscribe((config) => {
        this.config = config;
      })
    );
  }

  ngOnInit() {
    this.isLoading = true;

    this.contractService
      .getContractsCountByType()
      .then((cts) => {
        this.contractsType = [];
        if (cts?.length > 0) {
          cts.forEach((ct) => {
            if (ct.name.startsWith('OTHER')) {
              const index = this.contractsType.findIndex((x) => x.name === 'OTHER');
              if (index === -1) {
                this.contractsType.push({ name: 'OTHER', quantity: ct.numCount });
              } else {
                this.contractsType[index].quantity += ct.numCount;
              }
            } else {
              this.contractsType.push({ name: ct.name, quantity: ct.numCount });
            }
          });
          this.buildData();
        } else {
          this.options = null;
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
        this.isLoading = false;
      });
  }

  buildData() {
    const self = this;

    self.contractTypesText = this.contractsType.map((x) => x.name);
    self.contractTypesText.sort();

    const typeWithQuantity = [];

    self.contractTypesText.forEach((type) => {
      const dataType = { name: type, quantity: 0 };
      typeWithQuantity.push(dataType);
    });

    self.contractsType.forEach((type) => {
      const currentType = typeWithQuantity.filter((x) => x.name === type.name)[0];
      currentType.quantity += type.quantity;
    });

    self.contractQuantities = [];
    typeWithQuantity.forEach((type) => {
      self.contractQuantities.push(type.quantity);
    });

    this.buildOptions();
  }

  buildOptions() {
    const colors: any = this.config.variables;
    const echarts: any = this.config.variables.echarts;

    const neededColors: any[] = buildNeededColorsEchart(colors, this.contractsType.length);

    this.options = {
      backgroundColor: echarts.bg,
      color: neededColors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: this.contractTypesText,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: echarts.axisLineColor,
            },
          },
          axisLabel: {
            color: echarts.textColor,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: echarts.axisLineColor,
            },
          },
          splitLine: {
            lineStyle: {
              color: echarts.splitLineColor,
            },
          },
          axisLabel: {
            color: echarts.textColor,
          },
        },
      ],
      series: [
        {
          name: 'ContractType',
          type: 'bar',
          barWidth: '60%',
          data: this.contractQuantities,
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
