import { gql } from 'apollo-angular';

export const getContractsCountByType = gql`
{
    contractsGroupedByType{
      name
      numCount
    }
  }
`;
