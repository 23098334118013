<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'165px'"></tgx-spinner>
<nb-card size="medium">
    <nb-card-header>Orgs With Billing Contract</nb-card-header>
  <nb-card-body *ngIf="!isLoading">
    <nb-progress-bar [value]="getValue()" status="primary" [displayValue]="true"></nb-progress-bar>
    <div class="row mt-3 mb-3">
        <span>{{numOrgs}} of {{totalOrgs}}</span>
    </div>
    <div class="modal-footer">
        <button class="btn btn-md btn-danger" (click)="getCSV()" [disabled]="isLoading">Download CSV</button>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="isLoading">
    Your csv is being generated, please be patient..
  </nb-card-body>
</nb-card>
