import { gql } from 'apollo-angular';

export const getAllProviders = gql`
query {
  admin {
    allProviders {
      providerData{
        code
        name
        isActive
      }
      adviseMessage{
        code
        description
        level
      }
    }
  }
}
`;