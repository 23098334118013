import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { DefaultCostsService } from './../../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  DefaultCost,
  DefaultCostUpdateInput,
  ObjectWhereUniqueInputById,
  PartnerType,
  PaymentType,
} from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-default-cost-modal',
  styleUrls: ['./default-cost-modal.component.scss'],
  templateUrl: 'default-cost-modal.component.html',
})
export class BillingDefaultCostModalComponent implements OnInit {
  @Input() defaultCost: any = null;
  @Input() header: string;
  @Input() isEditor: boolean;

  defaultCostAux: DefaultCost;

  public defaultCostForm: UntypedFormGroup;

  partnerTypes: Options[] = [{ code: null, label: 'Select One' }];
  paymentTypes: Options[] = [{ code: null, label: 'Select One' }];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private defaultCostsService: DefaultCostsService
  ) {}

  ngOnInit() {
    this.buildSelects();

    this.defaultCostAux = clone(this.defaultCost);

    this.defaultCostForm = this.fb.group({
      id: [{ value: this.defaultCost ? this.defaultCost.id : '', disabled: true }],
      amount: [this.defaultCost ? this.defaultCost.amount : '', Validators.required],
      partnerType: ['', Validators.required],
      paymentType: ['', Validators.required],
    });

    this.defaultCostForm.get('partnerType').disable();
    this.defaultCostForm.get('paymentType').disable();

    if (!this.isEditor) {
      this.defaultCostForm.get('amount').disable();
    }

    this.defaultCostForm
      .get('partnerType')
      .setValue(this.partnerTypes[this.partnerTypes.findIndex((x) => x.code === this.defaultCost.partnerType)]);
    this.defaultCostForm
      .get('paymentType')
      .setValue(this.paymentTypes[this.paymentTypes.findIndex((x) => x.code === this.defaultCost.paymentType)]);
  }

  buildSelects() {
    Object.keys(PartnerType).forEach((value) => {
      this.partnerTypes.push({ code: value, label: value });
    });

    Object.keys(PaymentType).forEach((value) => {
      this.paymentTypes.push({ code: value, label: value });
    });
  }

  validType(field: string) {
    if (!this.defaultCostForm.get(field).value.code) {
      this.defaultCostForm.get(field).setErrors({ 'Required': true });
    } else {
      this.defaultCostForm.get(field).setErrors(null);
    }
  }

  validForm(): boolean {
    return (
      this.defaultCostForm.get('partnerType').value.code &&
      this.defaultCostForm.get('paymentType').value.code &&
      this.defaultCostForm.valid
    );
  }

  closeModal() {
    this.defaultCost = clone(this.defaultCostAux);
    this.activeModal.close(true);
  }

  onSave() {
    //Mutation update
    const updateData: DefaultCostUpdateInput = {
      amount: { set: this.defaultCostForm.get('amount').value.toString() },
      partnerType: { set: this.defaultCostForm.get('partnerType').value.code },
      paymentType: { set: this.defaultCostForm.get('paymentType').value.code },
    };

    const where: ObjectWhereUniqueInputById = { id: this.defaultCost.id };

    this.defaultCostsService
      .updateDefaultCost(updateData, where)
      .then((rs) => {
        this.notificationService.success('Default Cost succesfully updated', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        this.activeModal.close(false);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }
}
