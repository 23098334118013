import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { configLocalStorage } from '@tgx/shared/constants';
import { authQueries, GqlService } from '@tgx/shared/data-access/graphql';
import { decodeJWT } from '@tgx/shared/utils';
import { Apollo } from 'apollo-angular';
import * as auth0 from 'auth0-js';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PlatformAuthService } from './platform-auth.service';
import { PlatformBrowserService } from './platform-browser.service';
import { PlatformInfoService } from './platform-info.service';
import { ImpersonationResponse } from '@tgx/shared/interfaces';

@Injectable()
export class AuthService {
  auth: any;
  redirectUri: string;

  loadingExternalData: BehaviorSubject<boolean> = new BehaviorSubject(false);

  configuration;

  serverUriLocal = 'http://localhost:3000';
  serverUriDev = 'https://dev-app.travelgate.com';
  serverUri = 'https://app.travelgate.com';

  constructor(
    @Inject('domain') domain: string,
    @Inject('clientId') clientId: string,
    private httpClient: HttpClient,
    private platformBrowserService: PlatformBrowserService,
    private platformInfoService: PlatformInfoService,
    private platformAuthService: PlatformAuthService,
    private cookieService: CookieService,
    private apollo: Apollo,
  ) {
    if (window.location.origin.includes('localhost')) {
      this.redirectUri = this.serverUriDev + '/login-local';
      // this.redirectUri = this.serverUriLocal + '/login-local'; // Uncomment to launch the local server.
      this.redirectUri += '?redirect=' + location.port;
    } else if (
      window.location.origin.includes('dev-admin.travelgate.com') ||
      window.location.origin.includes('dev-admin.travelgatex.com')
    ) {
      this.redirectUri = this.serverUriDev + '/login-admin';
      this.redirectUri += '?redirect=' + window.location.origin;
    } else if (
      window.location.origin.includes('fb-travelgatex-admin') ||
      window.location.origin.includes('admin.travelgate.com') ||
      window.location.origin.includes('admin.travelgatex.com')
    ) {
      this.redirectUri = this.serverUri + '/login-admin';
      this.redirectUri += '?redirect=' + window.location.origin;
    } else {
      this.redirectUri = window.location.origin + '/login';
    }

    this.configuration = {
      domain: domain,
      clientID: clientId,
      redirectUri: this.redirectUri,
      responseType: 'token id_token',
      scope: 'openid profile email picture name',
      responseMode: 'form_post',
    };

    this.auth = new auth0.WebAuth(this.configuration);
  }

  handleAuthentication(forceLogin = false): Promise<boolean> | void {
    if (
      !window.location.hash.includes('access_token') &&
      !this.platformAuthService.isAuthenticated(forceLogin) &&
      this.platformInfoService.isBrowser()
    ) {
      localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
    }

    if (window.location.hash && window.location.hash.includes('access_token')) {
      const urlLoading = this.serverUri;
      return new Promise((resolve) => {
        // This call will only have data if accessed within the first 60 seconds
        // after logging in, as the data is only stored in Redis for that time.
        this.httpClient
          .post(urlLoading + '/getLogin', {
            access_token: window.location.hash.split('=')[1],
          })
          .subscribe({
            next: async (res: any) => {
              if (
                typeof res === 'string' &&
                (res.includes('Error get this key') || res.includes('No exists this key'))
              ) {
                window.location.href = '/';
                return resolve(false);
              }
              const authResult = {
                accessToken: res.access_token,
                idToken: res.id_token,
                expiresIn: res.expires_in,
              };
              if (this.cookieService.check(configLocalStorage.cookieImpersonation)) {
                const emailImpersCookie = this.cookieService.get(configLocalStorage.cookieImpersonation);
                const impersonationBearer = await this.impersonate(emailImpersCookie, res.id_token);
                if (impersonationBearer) {
                  const emailImpersStorage = emailImpersCookie;
                  const idTokenImpersStorage = impersonationBearer;
                  this.setImpersonation(idTokenImpersStorage, emailImpersStorage);
                }
              }
              this.setAuthentification(authResult);
              return resolve(true);
            },
            error: async () => {
              const loginResponse = await this.login();
              return resolve(loginResponse);
            },
          });
      });
    } else if (window.location.hash && window.location.hash.includes('unauthorized')) {
      window.location.href = `${window.location.origin}/401`;
    } else if (forceLogin) {
      return new Promise((resolve) => {
        return resolve(this.login());
      });
    }
  }

  private setImpersonation(idToken: string, memberCode: string) {
    this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.idTokenImpersonation, idToken);
    this.platformBrowserService.SetItemInLocalStorage(configLocalStorage.emailImpersonation, memberCode);
    this.platformBrowserService.RemoveItemFromLocalStorage('organization');
    this.platformBrowserService.RemoveItemFromLocalStorage('filter');
    this.platformBrowserService.RemoveItemFromLocalStorage('loggingFilter');
  }

  private async impersonate(memberCode: string, token: string): Promise<string> {
    try {
      const res = await lastValueFrom(
        this.apollo.use('gateway').query<ImpersonationResponse>({
          query: authQueries.members.getImpersonation,
          variables: { memberCode },
          context: {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
          },
        }),
      );

      debugger;
      const adviseMessage = res.data?.admin?.getImpersonationJWT?.adviseMessage;
      const bearer = res.data?.admin?.getImpersonationJWT?.bearer;

      if (adviseMessage) {
        throw new Error(adviseMessage[0]?.description);
      } else if (bearer) {
        return bearer;
      } else {
        return null;
      }
    } catch (err) {
      throw err;
    }
  }

  private setAuthentification(authResult: any) {
    if (authResult?.accessToken && authResult?.idToken) {
      window.location.hash = '';
      const tokenIdDecoded = decodeJWT(authResult.idToken);
      if (!tokenIdDecoded['https://travelgatex.com/org']) {
        this.platformAuthService.resetUserData();
      } else {
        const expiresAt = JSON.stringify(tokenIdDecoded.exp * 1000); // authResult.expiresIn + new Date().getTime()
        this.platformAuthService.setSession(authResult, expiresAt, true);
        this.platformAuthService.getProfileByTokenId(authResult.idToken);
        window.location.href = localStorage.getItem('redirectUrl') || '/';
      }
    } else {
      window.location.href = '/';
    }
  }

  async login(): Promise<boolean> {
    if (!this.platformAuthService.isAuthenticated(false)) {
      this.platformBrowserService.ClearLocalStorage();
      this.cookieService.deleteAll();
      this.auth.authorize();
      return false;
    } else {
      // Already logged in but requires impersonation
      if (this.cookieService.check(configLocalStorage.cookieImpersonation)) {
        const emailImpersCookie = this.cookieService.get(configLocalStorage.cookieImpersonation);
        const impersonationResponse = await this.impersonate(emailImpersCookie, this.platformBrowserService.getToken());
        if (impersonationResponse) {
          const emailImpersStorage = emailImpersCookie;
          const idTokenImpersStorage = impersonationResponse;
          this.setImpersonation(idTokenImpersStorage, emailImpersStorage);
        }
      }
      this.platformAuthService.getProfileByTokenId(this.platformBrowserService.getToken(), true);
      return true;
    }
  }

  /**
   * Function to handle de SSO redirect to the Distribution page, since it's on a different
   * domain and uses a different auth0 version it can't be handled by the normal checkSession process.
   *
   * It'll check the current session to retrieve auth data, and if there's some it'll be stored in the
   * redis and retrieved by the Distribution page.
   */
  distributionSSOLogin(): void {
    this.auth.checkSession({}, (err: any, authResult: any) => {
      if (err) {
        console.log('ERROR', err);
      }
      if (authResult) {
        this.loadingExternalData.next(true);
        const usrData = {
          access_token: authResult.accessToken,
          scope: authResult.scope,
          expires_in: authResult.expiresIn,
          token_type: authResult.tokenType,
          state: authResult.state,
          id_token: authResult.idToken,
        };
        this.httpClient.post(this.serverUri + '/distribution-sso', usrData).subscribe({
          next: (res: any) => {
            if (res && res.result && res.result === 'OK') {
              window.location.href = `https://travelb2b.travelgatex.com/login#access_token=${usrData.access_token}`;
            } else {
              // Error while storing SSO data, just redirect to the b2b login page
              window.location.href = 'https://travelb2b.travelgatex.com/login';
            }
          },
          error: () => {
            // Error while storing SSO data, just redirect to the b2b login page
            window.location.href = 'https://travelb2b.travelgatex.com/login';
          },
        });
      } else {
        // No auth data to be stored for the SSO, just redirect to the b2b login page
        window.location.href = 'https://travelb2b.travelgatex.com/login';
      }
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.auth.checkSession(this.configuration, (err, res) => {
        if (!err) {
          const authResult = {
            accessToken: res.accessToken,
            idToken: res.idToken,
          };
          const expiresAt = res.expiresIn * 1000 + new Date().getTime();
          this.platformAuthService.setSession(authResult, expiresAt, true);
          resolve(res.idToken);
        } else {
          reject(err);
        }
      });
    });
  }
}
