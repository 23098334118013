import { gql } from 'apollo-angular';

export const addMemberToOrganizationSafe = gql`
  mutation safeAddMemberToOrganization($data: AddMemberToOrganizationInput!) {
    admin {
      safeAddMemberToOrganization(input: $data) {
        code
        createdAt
        updatedAt
        memberData {
          id
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
