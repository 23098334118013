import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import {
  OrganizationSelector,
  OrganizationResponse,
  CreateOrganizationInput,
  Organization,
  UpdateOrganizationInput,
} from './../interfaces/_index-entities.interfaces';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationNetworkService {
  onToggleArchived$: Subject<any> = new Subject();

  filteredOrgList: OrganizationSelector[];
  filteredPrfList: OrganizationSelector[];
  orgCodes = new BehaviorSubject<string[]>(null);

  constructor(private gqlService: GqlService) {}

  async removeOrganizationPartner(orgCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeOrganizationPartner, { orgCode: orgCode })
        .then((response) => {
          if (response['admin']['removePartnerFromOrganization']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async updateOrganizationGiata(orgCode: string, configuration: string, isActive: boolean): Promise<boolean> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateOrganizationGiata, {
          orgCode: orgCode,
          configuration: configuration,
          isActive: isActive,
        })
        .then((response) => {
          if (response['admin']['updateOrganizationGiata']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async setOrganizationOwner(orgCode: string, ownerCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      const where = { search: orgCode, searchBy: 'CODE' };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setOrganizationOwner, { where: where, ownerCode: ownerCode })
        .then((response) => {
          if (response['admin']['setOrganizationOwner']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async removeOrganizationOwner(orgCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      const where = { search: orgCode, searchBy: 'CODE' };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeOrganizationOwner, { where: where })
        .then((response) => {
          if (response['admin']['removeOrganizationOwner']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async changeOrganizationMemberOwner(orgCode: string, memberCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.changeOrganizationMemberOwner, {
          member: memberCode,
          organization: orgCode,
        })
        .then((response) => {
          if (response['admin']['changeOrganizationMemberOwner']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async setOrganizationPartner(orgCode: string, partnerCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setOrganizationPartner, { orgCode: orgCode, partnerCode: partnerCode })
        .then((response) => {
          if (response['admin']['setPartnerToOrganization']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async getTeamsSelector(): Promise<OrganizationSelector[]> {
    const teams: OrganizationSelector[] = [];

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getMinInfoTeams, {})
        .then((response) => {
          if (response['admin']['groups']['edges'] && response['admin']['groups']['edges'].length > 0) {
            response['admin']['groups']['edges'].forEach((team) => {
              teams.push({
                code: team.node?.groupData.code,
                label: team.node?.groupData.code,
                isTeam: true,
                partnerCode: null,
              });
            });
            resolve(teams);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrganizationsSelector(): Promise<OrganizationSelector[]> {
    const orgs: OrganizationSelector[] = [];

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getMinInfoOrganizations, {})
        .then((response) => {
          if (
            response['admin']['allOrganizations']['edges'] &&
            response['admin']['allOrganizations']['edges'].length > 0
          ) {
            response['admin']['allOrganizations']['edges'].forEach((org) => {
              const orgNode = org.node?.organizationsData;
              orgs.push({
                code: orgNode.code,
                label: orgNode.label,
                isTeam: false,
                partnerCode: orgNode.owner?.code,
              });
            });
            resolve(orgs);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrganizations(orgCodes: string[], includeArchived: boolean): Promise<OrganizationResponse> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getOrganizations, { includeArchived: includeArchived, code_in: orgCodes })
        .then((response) => {
          if (response['admin']['allOrganizations']) {
            resolve(response['admin']['allOrganizations']);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrganizationGiata(orgCode: string): Promise<OrganizationResponse> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getOrganizationGiata, { code: orgCode })
        .then((response) => {
          if (response['admin']['organization']) {
            resolve(response['admin']['organization']);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async blackListOrganization(where: any): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.blackListOrganization, { where: where })
        .then((response) => {
          if (
            response['admin']['addOrganizationToBlacklist']['adviseMessage'] &&
            response['admin']['addOrganizationToBlacklist']['adviseMessage']?.length > 0
          ) {
            reject(response['admin']['addOrganizationToBlacklist']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['addOrganizationToBlacklist'].code,
              createdAt: response['admin']['addOrganizationToBlacklist'].createdAt,
              updatedAt: response['admin']['addOrganizationToBlacklist'].updatedAt,
              node: {
                organizationData: response['admin']['addOrganizationToBlacklist'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async setTestValueOrganization(where: any, testValue: boolean): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.SetOrganizationTestValue, { where: where, isTest: testValue })
        .then((response) => {
          if (
            response['admin']['setOrganizationTestValue']['adviseMessage'] &&
            response['admin']['setOrganizationTestValue']['adviseMessage']?.length > 0
          ) {
            reject(response['admin']['setOrganizationTestValue']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['setOrganizationTestValue'].code,
              createdAt: response['admin']['setOrganizationTestValue'].createdAt,
              updatedAt: response['admin']['setOrganizationTestValue'].updatedAt,
              node: {
                organizationData: response['admin']['setOrganizationTestValue'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async unBlackListOrganization(where: any): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unBlackListOrganization, { where: where })
        .then((response) => {
          if (
            response['admin']['removeOrganizationFromBlacklist']['adviseMessage'] &&
            response['admin']['removeOrganizationFromBlacklist']['adviseMessage']?.length > 0
          ) {
            reject(response['admin']['removeOrganizationFromBlacklist']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['removeOrganizationFromBlacklist'].code,
              createdAt: response['admin']['removeOrganizationFromBlacklist'].createdAt,
              updatedAt: response['admin']['removeOrganizationFromBlacklist'].updatedAt,
              node: {
                organizationData: response['admin']['removeOrganizationFromBlacklist'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async archiveOrganization(archiveInput: any): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveOrganization, { data: archiveInput })
        .then((response) => {
          if (response['admin']['archiveOrganizationIAM']['adviseMessage']?.length > 0) {
            reject(response['admin']['archiveOrganizationIAM']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['archiveOrganizationIAM'].code,
              createdAt: response['admin']['archiveOrganizationIAM'].createdAt,
              updatedAt: response['admin']['archiveOrganizationIAM'].updatedAt,
              node: {
                organizationData: response['admin']['archiveOrganizationIAM'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async unArchiveOrganization(unArchiveInput: any): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unArchiveOrganization, { data: unArchiveInput })
        .then((response) => {
          if (response['admin']['unArchiveOrganizationIAM']['adviseMessage']?.length > 0) {
            reject(response['admin']['unArchiveOrganizationIAM']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['unArchiveOrganizationIAM'].code,
              createdAt: response['admin']['unArchiveOrganizationIAM'].createdAt,
              updatedAt: response['admin']['unArchiveOrganizationIAM'].updatedAt,
              node: {
                organizationData: response['admin']['unArchiveOrganizationIAM'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  filteredOrgs(localMapOrg, localMapPrf, widget) {
    const arrayKeyOrgs: any = Array.from(localMapOrg.keys());
    const arrayKeyProfiles: any = Array.from(localMapPrf.values());

    if (widget === 'orgs') {
      this.filteredOrgList = arrayKeyOrgs.filter(function (n) {
        return arrayKeyProfiles.indexOf(n) === -1;
      });
      return this.filteredOrgList;
    }
    if (widget === 'prfs') {
      this.filteredPrfList = arrayKeyProfiles.filter(function (n) {
        return arrayKeyOrgs.indexOf(n) === -1;
      });
      return this.filteredPrfList;
    }
  }

  setOrganizationsCodes(orgCodes) {
    this.orgCodes.next(orgCodes);
  }
}
