<nb-card>
  <nb-card-header> Bill Scheduled executions </nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="schedulesLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header">
            {{ head.label }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request>
        <tr>
          <td>{{ request.ID }}</td>
          <td>{{ request.StartDate | date : 'dd/MM/yyyy' }}</td>
          <td>{{ request.EndDate | date : 'dd/MM/yyyy' }}</td>
          <td>{{ request.ExecDate | date : 'dd/MM/yyyy' }}</td>
          <td>{{ request.DateStarted | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ request.DateFinished | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ request.CreatedAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ request.UpdatedAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="request.DateFinished"
                pButton
                pRipple
                type="button"
                icon="pi pi-download"
                (click)="onDownload(request)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="!request.DateStarted"
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                (click)="onDelete(request)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No bill scheduled operations found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
