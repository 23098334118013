import { gql } from 'apollo-angular';

export const deleteCost = gql`
mutation DeleteCost($where: CostWhereUniqueInput!) {
    deleteCost(where: $where) {
      id
    }
  }
  
`;
