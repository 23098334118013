<tgx-spinner [isLoading]="isLoading"></tgx-spinner>
<nb-card *ngIf="!isLoading">
  <nb-card-header>Scheduler</nb-card-header>
  <nb-card-body>
    <form [formGroup]="searchForm">
      <div class="row mb-3">
        <div class="col-4">
          <div class="form-group">
            <label for="contractConnectionId">Suppliers</label>
            <p-multiSelect
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control"
              formControlName="suppliers"
              [options]="filteredSuppliersSelector"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} suppliers selected"
              (onFilter)="handleFilter($event)"
              (onChange)="sortSuppliers()"
            >
              <ng-template pTemplate="footer">
                <app-letter-carousel
                  [selectedSupplier]="searchForm.get('suppliers').value[0]"
                  (letterSelected)="filterSuppliersByLetter($event)"
                ></app-letter-carousel>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="mb-1" for="clients">Clients</label>
            <p-multiSelect
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control"
              formControlName="clients"
              [options]="clientsSelector"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} clients selected"
            >
            </p-multiSelect>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="mb-1" for="operations">Operations</label>
            <p-multiSelect
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control"
              formControlName="operations"
              [options]="operationsSelector"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} operations selected"
            >
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="form-group">
            <label class="mb-1" for="status">Status</label>
            <p-multiSelect
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control"
              formControlName="status"
              [options]="statusesSelector"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} status selected"
            >
            </p-multiSelect>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="mt-2 mb-1" for="startDate"
              >DateFrom (Only on 'Finish')<span class="mandatory ml-1">*</span></label
            >
            <p-calendar
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="startDate"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
              (onSelect)="checkEndDate()"
              placeholder="Enter a date.."
            ></p-calendar>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="mt-2 mb-1" for="endDate"
              >DateTo (Only on 'Finish')<span class="mandatory ml-1">*</span></label
            >
            <p-calendar
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="endDate"
              [minDate]="searchForm.get('startDate').value"
              [defaultDate]="searchForm.get('startDate').value"
              [maxDate]="maxDate"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
              placeholder="Enter a date.."
            ></p-calendar>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-md btn-danger" style="margin-right: 10px" (click)="buildForm()">Reset</button>
        <button class="btn btn-md btn-primary" [disabled]="searchForm.invalid" (click)="filter()">Filter</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>

<tgx-spinner [isLoading]="isFiltering" [top]="'120px'"></tgx-spinner>
<tgx-billing-scheduler-details
  *ngIf="scheduleComplete && !isFiltering"
  [title]="'Complete'"
  [source]="scheduleComplete"
></tgx-billing-scheduler-details>
<tgx-billing-scheduler-details
  *ngIf="scheduleExecuting && !isFiltering"
  [title]="'Executing'"
  [source]="scheduleExecuting"
></tgx-billing-scheduler-details>
<tgx-billing-scheduler-details
  *ngIf="scheduleEnqueued && !isFiltering"
  [title]="'Enqueued'"
  [source]="scheduleEnqueued"
></tgx-billing-scheduler-details>
