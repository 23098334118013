import { gql } from 'apollo-angular';

export const removeSupplierDefaultSharedAccess = gql`mutation removeSupplierDefaultSharedAccess($where: SupplierWhereUniqueInput!) {
  admin {
    removeSupplierDefaultSharedAccess(where: $where) {
      code
      adviseMessage {
        code
        description
        level
      }
    }
  }
}
`;
