import { Table } from 'primeng/table';
import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { PreAndPostCheckService } from '../../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../../@core/services/_index-core.services';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-pre-fifth',
  templateUrl: './invoicing-process-pre-fifth.component.html',
  styleUrls: ['./invoicing-process-pre-fifth.component.scss'],
})
export class BillingInvoicingProcessPreFifthComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  @Output() isValidStep: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  header = [
    { label: 'ID', field: 'ID' },
    { label: 'ActivationDateTime', field: 'ActivationDateTime' },
    { label: 'EndDate', field: 'EndDate' },
    { label: 'IsBuy', field: 'IsBuy' },
    { label: 'ConnectionType', field: 'ConnectionType' },
    { label: 'RateType', field: 'RateType' },
    { label: 'OrgCode', field: 'OrgCode' },
    { label: 'ContractID', field: 'ContractID' },
    { label: 'SupplierID', field: 'SupplierID' },
  ];

  source: any[];

  isLoading: boolean;

  $subs: Subscription[] = [];

  step = 5;
  constructor(
    private notificationService: NotificationService,
    private preAndPostCheckService: PreAndPostCheckService,
    private datePipe: DatePipe
  ) {
    this.$subs.push(
      this.preAndPostCheckService.refreshPreStepPage.subscribe(async (refreshStep) => {
        if (refreshStep && refreshStep === this.step) {
          await this.getConnections();
        }
      })
    );
  }

  async ngOnInit(): Promise<void> {
    this.$subs.push(
      this.preAndPostCheckService.currentPreStepPage.subscribe(async (currentStep) => {
        if (currentStep && currentStep === this.step) {
          await this.getConnections();
        }
      })
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  async getConnections() {
    this.isLoading = true;
    this.source = [];
    const requests: any[] = [];

    await this.preAndPostCheckService
      .getCM22SupConnections()
      .then((rs) => {
        if (rs.success) {
          rs.response?.forEach((cn) => {
            requests.push({
              'ID': cn.ID,
              'ActivationDateTime': cn.ActivationDateTime?.Valid
                ? this.datePipe.transform(cn.ActivationDateTime.String, 'dd-MM-yyyy')
                : '',
              'EndDate': cn.EndDate?.Valid ? this.datePipe.transform(cn.EndDate.String, 'dd-MM-yyyy') : '',
              'IsBuy': cn.IsBuy,
              'ConnectionType': this.getRateType(cn.ConnectionType),
              'RateType': cn.RateType,
              'OrgCode': cn.OrgCode,
              'ContractID': cn.ContractID,
              'SupplierID': cn.SupplierID,
            });
          });
        }
        this.source = [...requests];
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
        this.isValidStep.emit(this.source?.length === 0);
      });
  }

  getRateType(rateType: string) {
    switch (rateType) {
      case 'STANDARD':
        return 'STD';
      case 'STANDARD+SUPPLEMENT':
        return 'SUP';
      case 'FREE':
        return rateType;
    }
  }
}
