import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import { SelectorOrganization, UserData } from '@tgx/shared/interfaces';
import { PlatformInfoService } from '@tgx/shared/services';
import { combineLatest, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HasContractGuard implements CanActivate, OnDestroy {
  private isStaff = false;
  private hasNoContract = true;
  private currentOrgCode: string;
  private guardInitialized = false;
  private subscription: Subscription;
  private nextURL: string;

  constructor(private router: Router, private platformInfoService: PlatformInfoService) {}

  canActivate(_, state: RouterStateSnapshot): boolean | UrlTree {
    this.nextURL = state.url;
    if (this.isStaff) return true;

    if (this.guardInitialized) {
      if (this.hasNoContract && this.nextURL === '/no-contract') return true;
      if (this.hasNoContract) return this.router.parseUrl('no-contract');

      if (!this.hasNoContract && this.nextURL === '/no-contract') return this.router.parseUrl('dashboard');

      return true;
    } else {
      this.subscription?.unsubscribe();

      this.subscription = combineLatest([
        this.platformInfoService.organizationSelected,
        this.platformInfoService.userLogged$,
      ]).subscribe({
        next: ([org, userData]: [SelectorOrganization, UserData]) => {
          if (org && userData) {
            this.isStaff = userData.isStaff;

            if (org && org.code !== this.currentOrgCode) {
              if (!this.guardInitialized) this.guardInitialized = true;

              this.currentOrgCode = org.code;
              this.hasNoContract = org.blacklisted;

              if (this.hasNoContract && !this.isStaff) this.router.navigateByUrl('no-contract');
              if (!this.hasNoContract || this.isStaff) {
                this.router.navigateByUrl(this.nextURL === '/no-contract' ? 'dashboard' : this.nextURL);
              }
            }
          }
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
