import { gql } from 'apollo-angular';

export const getClients = gql`
  query getOrganizationsSimple {
    admin {
      allClients {
        totalCount
        edges {
          node {
            code
            clientData {
              code
              name
              isActive
              group {
                code
              }
              owner {
                code
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
