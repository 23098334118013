import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'xen-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => SwitchComponent),
  }],
})
export class SwitchComponent implements OnInit, OnChanges {
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() reverse: boolean;
  @Input() label: string;
  @Input() id: string;
  @Input() formControlName: string;
  @Input() validation: boolean;
  @Input() validation_status: string;
  @Input() feedback: string;

  form: FormGroup;

  value: string;
  onChange() {}
  onTouched() {}
  isDisabled: boolean = false;

  ngOnInit(): void {
    this.form = this.rootFormGroup.control

    if (this.disabled) {
      this.form.controls[this.formControlName].disable()
    }
  }

  ngOnChanges(): void {
    if (this.disabled && this.form) {
      this.form.get(this.formControlName).disable();
    } else if (this.form) {
      this.form.get(this.formControlName).enable();
    }
  }
  
  constructor(
    private rootFormGroup: FormGroupDirective
  ) {}

  public get classes(): Array<string> {
    return [this.reverse ? 'form-check-reverse' : ''];
  }

  public get inputClasses(): Array<string> {
    return [ 
      (this.validation && this.validation_status) ? `${this.validation_status}` : ''
    ]
  }
  public get feedbackClasses(): Array<string> {
    return [ 
      (this.validation && (this.validation_status === 'is-valid')) ? 'valid-feedback' : '',
      (this.validation && (this.validation_status === 'is-invalid')) ? 'invalid-feedback' : '',
    ]
  }

  writeValue(value) {
    this.value = value
  }

  registerOnChange(fn) {
    this.onChange = fn
  }

  registerOnTouched(fn) {
    this.onTouched = fn
  }

  setDisabledState(isDisabled) {
    this.isDisabled = isDisabled;
  }
  
}
