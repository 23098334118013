import { gql } from 'apollo-angular';

export const deactivateProvider = gql`
mutation ($where: ProviderWhereUniqueInput!) {
    admin {
      deactivateProvider(where: $where) {
        code
        adviseMessage{
          code
          description
          level
        }
      }
    }
  }
`;