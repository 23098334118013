<xen-modal
  subtitle="Please select one or more masters"
  title="{{
    isOpen ? 'Remap this property into a similar validated master(s)' : 'Merge into a similar validated master(s)'
  }}"
>
  <div xenModalBody>
    <div class="mb-3">
      <xen-summary [size]="'small'" [label]="'Summary'" [icon]="false" [type]="'success'">
        <div #content>
          <p class="mb-0 txt mb-1">
            <i class="fa-regular fa-link-slash"></i>
            {{ isOpen ? ' You are remaping this master:' : ' You are merging this master:' }}
          </p>
          <ul>
            <li>Pensión Manolita, Spain, Barcelona, 08015, Carrer Calderón, 4, 07181 Magaluf, Balearic Islands</li>
          </ul>
        </div>
      </xen-summary>
    </div>
    <div>
      <p class="txt mb-2">
        <i class="fa-regular fa-link-slash"></i> Please select which master you want to move the property to:
      </p>

      <table class="xen-table is-full">
        <thead>
          <tr>
            <th *ngFor="let head of tHead">
              <a class="sortable sortable--up">{{ head }}</a>
            </th>
          </tr>
        </thead>
        <tbody *ngFor="let body of tBody">
          <tr>
            <td>
              <label class="d-flex align-items-center">
                <input type="checkbox" class="form-check-input align-text-top mt-0 me-2" />
                <i class="fa-solid fa-circle-check text-success me-1"></i> {{ body.master }}
              </label>
            </td>
            <td>{{ body.country }}</td>
            <td>({{ body.geo }})</td>
            <td>{{ body.cp }}</td>
            <td>{{ body.address }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div xenModalFooter>
    <div class="d-flex align-items-center gap-2">
      <xen-btn (click)="close()" label="Cancel" size="medium" skin="gray" type="flat"></xen-btn>
      <xen-btn
        (click)="close()"
        label="{{ isOpen ? ' Remap into selected master(s)' : ' Merge into selected master(s)' }}"
        size="medium"
        skin="blue"
        type="primary"
      ></xen-btn>
    </div>
  </div>
</xen-modal>
