import { gql } from 'apollo-angular';

export const removeMemberFromOrganization = gql`
  mutation RemoveMemberFromOrganization($data: RemoveMemberFromOrganizationInput!) {
    admin {
      removeMemberFromOrganization(input: $data) {
        code
        createdAt
        updatedAt
        memberData {
          id
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
