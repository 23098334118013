import { gql } from 'apollo-angular';

export const updateContractConnection = gql`
mutation updateContractConnection(
  $data: ContractConnectionUpdateInput!
  $where: ContractConnectionWhereUniqueInput!
) {
  updateContractConnection(data: $data, where: $where) {
    id
  }
}

`;
