import { gql } from 'apollo-angular';

export const getOrgs = gql`
  query getOrganizationsSimple {
    admin {
      organizations {
        edges {
          node {
            organizationData {
              id
              code
              label
            }
          }
        }
      }
    }
  }
`;
