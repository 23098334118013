import { createFolder } from './create-folder';
import { updateBuyerOnboardingDate } from './social/update-buyer-onboarding-date';
import { updateUserProfile } from './social/update-user-profile';
import { createUserProfile } from './social/create-user-profile';
import { updateOrg, updateOrganization } from './update-org';

export const adminMutations = {
  updateOrg,
  createFolder,
  updateOrganization,
};

export const socialMutations = {
  updateBuyerOnboardingDate,
  updateUserProfile,
  createUserProfile,
};
