import { Injectable } from '@angular/core';
import {
  BookingL2BCost,
  BookingL2BCostWhereInput,
  BookingL2BCostCreateInput,
  BookingL2BCostUpdateInput,
  ObjectWhereUniqueInputById,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CostsL2BService {
  constructor(private apollo: Apollo) {}

  async getL2BCosts(contractId: number): Promise<BookingL2BCost[]> {
    const where: BookingL2BCostWhereInput = { contract: { equals: contractId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getL2BCosts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).bookingL2BCosts);
          } else {
            reject(res);
          }
        });
    });
  }

  async createL2BCost(create: BookingL2BCostCreateInput): Promise<BookingL2BCost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createL2BCost,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createBookingL2BCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  async updateL2BCost(update: BookingL2BCostUpdateInput, where: ObjectWhereUniqueInputById): Promise<BookingL2BCost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateL2BCost,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateBookingL2BCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  async deleteL2BCost(where: ObjectWhereUniqueInputById): Promise<BookingL2BCost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteL2BCost,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteBookingL2BCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
