import { Component, Input, OnInit } from '@angular/core';
import { ClientsEntitiesService } from '../../../features/entities/services/clients-entities.service';
import { OrganizationNetworkService } from '../../../features/entities/services/organization-network.service';
import { LegacyConnectionEntitiesService } from '../../../features/entities/services/clients-legacy-connections-entities.service';
import { SuppliersService } from '../../../features/entities/services/suppliers.service';
import { ProvidersService } from '../../../features/entities/services/providers.service';
import { AccessesService } from '../../../features/entities/services/accesses.service';
import { MemberNetworkService } from '../../../features/entities/services/member-network.services';
@Component({
  selector: 'tgx-custom-toggle-renderer',
  templateUrl: './custom-toggle-renderer.component.html',
  styleUrls: ['./custom-toggle-renderer.component.scss'],
})
export class CustomToggleRendererComponent implements OnInit {
  renderValue: boolean;
  disabled: boolean;
  from: string;

  @Input() value: any;
  @Input() rowData: any;

  constructor(
    private clientService: ClientsEntitiesService,
    private organizationNetworkService: OrganizationNetworkService,
    private LegacyConnectionEntitiesService: LegacyConnectionEntitiesService,
    private SuppliersService: SuppliersService,
    private ProvidersService: ProvidersService,
    private AccessesService: AccessesService,
    private MemberNetworkService: MemberNetworkService
  ) {}

  ngOnInit() {
    this.renderValue = Boolean(this.value.value);
    this.disabled = this.value.disabled;
    this.from = this.value.from;
  }

  onToggle() {
    const originalStatus = this.rowData.isActive;
    this.rowData.isActive = this.renderValue;

    if (this.from === 'active_client') {
      this.clientService.onToggleTable$.next(this.rowData);
    } else if (this.from === 'archive_client') {
      this.rowData.isActive = originalStatus;
      this.clientService.onToggleArchive$.next(this.rowData);
    } else if (this.from === 'archive_organization') {
      this.organizationNetworkService.onToggleArchived$.next(this.rowData);
    } else if (this.from === 'legacy') {
      this.LegacyConnectionEntitiesService.onToggleTable$.next(this.rowData);
    } else if (this.from === 'supplier') {
      this.SuppliersService.onToggleTable$.next(this.rowData);
    } else if (this.from === 'archive_supplier') {
      this.rowData.isActive = originalStatus;
      this.SuppliersService.onToggleArchive$.next(this.rowData);
    } else if (this.from === 'provider') {
      this.ProvidersService.onToggleTable$.next(this.rowData);
    } else if (this.from === 'access') {
      this.AccessesService.onToggleTable$.next(this.rowData);
    } else if (this.from === 'archive_access') {
      this.AccessesService.onToggleArchive$.next(this.rowData);
    } else if (this.from === 'activate_member') {
      this.rowData.isActive = originalStatus;
      this.MemberNetworkService.onToggleTable$.next(this.rowData);
    } else if (this.from === 'archive_member') {
      this.rowData.isActive = originalStatus;
      this.MemberNetworkService.onToggleArchive$.next(this.rowData);
    }
  }
}
