import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { CostsService } from './../../../../../services/_index-billing.services';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  Cost,
  CostCreateInput,
  CostUpdateInput,
  ObjectWhereUniqueInputById,
  ChargeType,
  PaymentType,
} from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-cost-modal',
  styleUrls: ['./cost-modal.component.scss'],
  templateUrl: 'cost-modal.component.html',
})
export class BillingCostModalComponent implements OnInit, OnDestroy {
  @Input() cost: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;

  isCreating: boolean;
  costAux: Cost;

  public costForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  chargeTypes: Options[] = [{ code: null, label: 'Select One' }];
  paymentTypes: Options[] = [{ code: null, label: 'Select One' }];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private costsService: CostsService
  ) {}

  ngOnInit() {
    this.buildSelects();

    this.costForm = this.fb.group({
      id: [{ value: this.cost ? this.cost.id : '', disabled: true }],
      isPush: [this.cost && this.cost?.isPush !== null ? this.cost.isPush : null],
      min: [this.cost ? this.cost.min : 0, Validators.required],
      max: [this.cost ? this.cost.max : ''],
      amount: [this.cost ? this.cost.amount : '', Validators.required],
      chargeType: ['', Validators.required],
      paymentType: ['', Validators.required],
      startDate: [''],
      endDate: [''],
    });

    this.isCreating = true;

    if (this.cost) {
      this.isCreating = false;
    }

    if (!this.isCreating) {
      if (!this.isEditor) {
        this.costForm.get('isPush').disable();
        this.costForm.get('min').disable();
        this.costForm.get('max').disable();
        this.costForm.get('amount').disable();
        this.costForm.get('chargeType').disable();
        this.costForm.get('paymentType').disable();
        this.costForm.get('startDate').disable();
        this.costForm.get('endDate').disable();
      }

      this.costForm
        .get('chargeType')
        .setValue(this.chargeTypes[this.chargeTypes.findIndex((x) => x.code === this.cost.chargeType)]);
      this.loadPaymentTypeEvent(this.costForm.get('chargeType').value);
      this.costForm
        .get('paymentType')
        .setValue(this.paymentTypes[this.paymentTypes.findIndex((x) => x.code === this.cost.paymentType)]);
    } else {
      this.costForm.get('chargeType').setValue(this.chargeTypes[0]);
      this.costForm.get('paymentType').setValue(this.paymentTypes[0]);
      this.costForm.get('endDate').disable();
    }
  }

  buildSelects() {
    Object.keys(ChargeType).forEach((value) => {
      this.chargeTypes.push({ code: value, label: value });
    });

    Object.keys(PaymentType).forEach((value) => {
      this.paymentTypes.push({ code: value, label: value });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  validType(field: string) {
    if (!this.costForm.get(field).value.code) {
      this.costForm.get(field).setErrors({ 'Required': true });
    } else {
      this.costForm.get(field).setErrors(null);
    }
  }

  validForm(): boolean {
    return (
      this.costForm.get('chargeType').value.code && this.costForm.get('paymentType').value.code && this.costForm.valid
    );
  }

  closeModal() {
    this.cost = clone(this.costAux);
    this.activeModal.close(true);
  }

  checkEndDate() {
    this.costForm.get('endDate').setValue('');
    if (this.costForm.get('startDate').value) {
      this.costForm.get('endDate').enable();
    } else {
      this.costForm.get('endDate').disable();
    }
  }

  onSave() {
    let startDate: any;
    let endDate: any;
    let date: Date;
    let dateStr = '';
    let month = 0;
    let day = 0;

    if (
      (this.costForm.get('startDate').value !== '' && this.costForm.get('endDate').value === '') ||
      (this.costForm.get('startDate').value === '' && this.costForm.get('endDate').value !== '')
    ) {
      this.notificationService.warning(
        'If you use start and end date, you have to fill both, not only one.',
        'Warning'
      );
      return;
    }

    if (this.costForm.get('startDate').value !== '') {
      date = new Date(this.costForm.get('startDate').value);
      dateStr = date.getFullYear().toString();
      month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T00:00:00.000Z';
      startDate = dateStr;
    }

    if (this.costForm.get('endDate').value !== '') {
      date = new Date(this.costForm.get('endDate').value);
      dateStr = date.getFullYear().toString();
      month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T23:59:59.000Z';
      endDate = dateStr;
    }

    if (this.isCreating) {
      //Mutation create

      const createData: CostCreateInput = {
        min: this.costForm.get('min').value.toString(),
        isPush: this.costForm.get('isPush').value,
        amount: this.costForm.get('amount').value.toString(),
        Contract: {
          connect: { id: this.contractId },
        },
        chargeType: this.costForm.get('chargeType').value.code,
        paymentType: this.costForm.get('paymentType').value.code,
        startDate: startDate,
        endDate: endDate,
      };

      if (this.costForm.get('max').value) {
        createData.max = this.costForm.get('max').value.toString();
      }

      this.costsService
        .createCost(createData)
        .then((rs) => {
          this.notificationService.success('Cost succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: CostUpdateInput = {
        min: { set: this.costForm.get('min').value.toString() },
        max: { set: this.costForm.get('max').value ? this.costForm.get('max').value.toString() : null },
        isPush: { set: this.costForm.get('isPush').value },
        amount: { set: this.costForm.get('amount').value.toString() },
        chargeType: { set: this.costForm.get('chargeType').value.code },
        paymentType: { set: this.costForm.get('paymentType').value.code },
        startDate: { set: startDate ? startDate : null },
        endDate: { set: endDate ? endDate : null },
      };

      const where: ObjectWhereUniqueInputById = { id: this.cost.id };

      this.costsService
        .updateCost(updateData, where)
        .then((rs) => {
          this.notificationService.success('Cost succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  getIsPushValue() {
    if (this.costForm.get('isPush').value === null) {
      return 'Both';
    }
    if (!this.costForm.get('isPush').value) {
      return 'Pull';
    }
    return 'Push';
  }

  loadPaymentTypeEvent({ code }) {
    this.paymentTypes = [
      { code: null, label: 'Select One' },
      { code: 'SUPPLEMENT', label: 'SUPPLEMENT' },
      { code: 'STANDARD', label: 'STANDARD' },
    ];
    switch (code) {
      case 'SETUP':
      case 'MAINTENANCE':
      case 'CHANNELX_COSTPERHOTEL':
      case 'SPEEDCOST':
      case 'INSIGHTS':
      case 'EXCESS':
        this.paymentTypes = [{ code: 'SUPPLEMENT', label: 'SUPPLEMENT' }];
        break;
      case 'GMV':
        this.paymentTypes.push({ code: 'SUPPLEMENT_CM', label: 'SUPPLEMENT_CM' });
        this.paymentTypes.push({ code: 'SUPPLEMENT_FS', label: 'SUPPLEMENT_FS' });
    }

    this.costForm.get('paymentType').setValue(this.paymentTypes[0]);
  }
}
