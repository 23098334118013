import { gql } from 'apollo-angular';

export const activateMemberIAM = gql`
  mutation activateMemberIAM($memberCode: ID!) {
    admin {
      activateMemberIAM(member: { code: $memberCode }) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
