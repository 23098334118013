import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { NewPricingCostsService } from './../../../../../services/_index-billing.services';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  NewPricingCost,
  NewPricingCostCreateInput,
  NewPricingCostUpdateInput,
  ObjectWhereUniqueInputById,
  ChargeType,
  PaymentType,
} from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-new-pricing-cost-modal',
  styleUrls: ['./new-pricing-costs-modal.component.scss'],
  templateUrl: 'new-pricing-costs-modal.component.html',
})
export class BillingNewPricingCostModalComponent implements OnInit, OnDestroy {
  @Input() cost: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;

  isCreating: boolean;
  costAux: NewPricingCost;

  public costForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  chargeTypes: Options[] = [{ code: null, label: 'Select One' }];
  paymentTypes: Options[] = [{ code: null, label: 'Select One' }];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private newPricingCostsService: NewPricingCostsService
  ) {}

  ngOnInit() {
    this.buildSelects();

    this.costForm = this.fb.group({
      id: [{ value: this.cost ? this.cost.id : '', disabled: true }],
      min: [this.cost ? this.cost.min : 0, Validators.required],
      max: [this.cost ? this.cost.max : ''],
      bookingFee: [this.cost ? this.cost.bookingFee : '', Validators.required],
      cancelFee: [this.cost ? this.cost.cancelFee : '', Validators.required],
      l2b: [this.cost?.l2b ? this.cost.l2b : ''],
      excess: [this.cost?.excess ? this.cost.excess : ''],
      chargeType: ['', Validators.required],
      paymentType: ['', Validators.required],
    });

    this.isCreating = true;

    if (this.cost) {
      this.isCreating = false;
    }

    if (!this.isCreating) {
      if (!this.isEditor) {
        this.costForm.get('min').disable();
        this.costForm.get('max').disable();
        this.costForm.get('bookingFee').disable();
        this.costForm.get('cancelFee').disable();
        this.costForm.get('l2b').disable();
        this.costForm.get('excess').disable();
        this.costForm.get('chargeType').disable();
        this.costForm.get('paymentType').disable();
      }

      this.costForm
        .get('chargeType')
        .setValue(this.chargeTypes[this.chargeTypes.findIndex((x) => x.code === this.cost.chargeType)]);
      this.costForm
        .get('paymentType')
        .setValue(this.paymentTypes[this.paymentTypes.findIndex((x) => x.code === this.cost.paymentType)]);
    } else {
      this.costForm.get('chargeType').setValue(this.chargeTypes[0]);
      this.costForm.get('paymentType').setValue(this.paymentTypes[0]);
    }
  }

  buildSelects() {
    Object.keys(ChargeType).forEach((value) => {
      if (value === ChargeType.GMV || value === ChargeType.BOOKING_SCALED) {
        this.chargeTypes.push({ code: value, label: value });
      }
    });

    Object.keys(PaymentType).forEach((value) => {
      if (value !== PaymentType.SUPPLEMENT_CM) {
        this.paymentTypes.push({ code: value, label: value });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  validType(field: string) {
    if (!this.costForm.get(field).value.code) {
      this.costForm.get(field).setErrors({ 'Required': true });
    } else {
      this.costForm.get(field).setErrors(null);
    }
  }

  validForm(): boolean {
    return (
      this.costForm.get('chargeType').value.code && this.costForm.get('paymentType').value.code && this.costForm.valid
    );
  }

  closeModal() {
    this.cost = clone(this.costAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create

      const createData: NewPricingCostCreateInput = {
        min: this.costForm.get('min').value.toString(),
        bookingFee: this.costForm.get('bookingFee').value.toString(),
        cancelFee: this.costForm.get('cancelFee').value.toString(),
        Contract: {
          connect: { id: this.contractId },
        },
        chargeType: this.costForm.get('chargeType').value.code,
        paymentType: this.costForm.get('paymentType').value.code,
      };

      if (this.costForm.get('max').value) {
        createData.max = this.costForm.get('max').value.toString();
      }

      if (this.costForm.get('l2b').value) {
        createData.l2b = this.costForm.get('l2b').value.toString();
      }
      if (this.costForm.get('excess').value) {
        createData.excess = this.costForm.get('excess').value.toString();
      }

      this.newPricingCostsService
        .createNewPricingCost(createData)
        .then((rs) => {
          this.notificationService.success('New pricing cost succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: NewPricingCostUpdateInput = {
        min: { set: this.costForm.get('min').value.toString() },
        max: { set: this.costForm.get('max').value ? this.costForm.get('max').value.toString() : null },
        l2b: { set: this.costForm.get('l2b').value ? this.costForm.get('l2b').value.toString() : null },
        excess: { set: this.costForm.get('excess').value ? this.costForm.get('excess').value.toString() : null },
        bookingFee: { set: this.costForm.get('bookingFee').value.toString() },
        cancelFee: { set: this.costForm.get('cancelFee').value.toString() },
        chargeType: { set: this.costForm.get('chargeType').value.code },
        paymentType: { set: this.costForm.get('paymentType').value.code },
      };

      const where: ObjectWhereUniqueInputById = { id: this.cost.id };

      this.newPricingCostsService
        .updateNewPricingCost(updateData, where)
        .then((rs) => {
          this.notificationService.success('New pricing cost succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
