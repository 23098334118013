import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../../../../../../@core/services/_index-core.services';
import { Options } from '../../../../../../@core/interfaces/_index.interfaces';
import { SupplierData } from '../../../../interfaces/models/suppliers.interface';
import { SuppliersService } from '../../../../services/suppliers.service';

@Component({
  selector: 'tgx-giata-modal',
  templateUrl: './giata-modal.component.html',
  styleUrls: ['./giata-modal.component.scss'],
})
export class GiataModalComponent implements OnInit {
  @Input() header: string;

  @Input() SupplierData: SupplierData;
  public GiataForm: UntypedFormGroup;

  giataTypes = [
    { label: 'GDS', code: 'GDS' },
    { label: 'TOUROPERATOR', code: 'TOUROPERATOR' },
  ];

  selectedGiataType: Options;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationService,
    private suppliersService: SuppliersService
  ) {}

  ngOnInit(): void {
    this.GiataForm = this.fb.group({
      code: this.fb.control(this.SupplierData?.code || ''),
      giataType: this.fb.control((this.SupplierData?.giata?.giataType || '').toUpperCase()),
      giataCode: this.fb.control(this.SupplierData?.giata?.giataCode || ''),
    });

    this.GiataForm.get('code').disable();
    this.selectedGiataType = {
      code: this.SupplierData?.giata?.giataType.toUpperCase() || '',
      label: this.SupplierData?.giata?.giataType.toUpperCase() || '',
    };
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSave() {
    const supcode = this.GiataForm.get('code').value;
    const giataCode = this.GiataForm.get('giataCode').value;
    const giataType = this.selectedGiataType?.code ?? '';

    if (supcode && giataCode && giataType) {
      const where = { search: supcode, searchBy: 'CODE' };
      this.suppliersService
        .updateSupplierGiata(where, giataCode, giataType)
        .then(() => {
          this.notificationsService.info('Supplier GIATA was successfully updated');
        })
        .catch((err) => {
          this.notificationsService.info(err);
        });
    } else {
      this.notificationsService.error('Please, select an Organization and a Group');
    }
  }
}
