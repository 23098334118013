import { gql } from 'apollo-angular';

export const getAccesses = gql`
  query getAccesses($owner: [ID!], $supplierID: [ID!]) {
    admin {
      allAccesses(where: { owner_in: $owner, supplier_in: $supplierID }) {
        edges {
          node {
            createdAt
            updatedAt
            accessData {
              code
              name
              isActive
              isTest
              owner {
                code
              }
              supplier {
                code
                supplierData {
                  code
                  name
                  owner {
                    code
                  }
                  context
                }
              }
            }
          }
        }
      }
    }
  }
`;
