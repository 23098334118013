import { NotificationType } from './../interfaces/_index.interfaces';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {

  constructor(
    private toastrService: ToastrService
  ) {  }

  /**
   * Create an info toastr
   * @param title title
   * @param message message
   */
  info(message: string, title: string = 'Info') {
    this.toastrService.info(message, title);
  }

  /**
   * Create a success toastr
   * @param title title
   * @param message message
   */
  success(message: string, title: string = 'Éxito') {
    this.toastrService.success(message, title);
  }

   /**
   * Create an error toastr
   * @param title title
   * @param message message
   */
  error(message: string, title: string = 'Error') {
    this.toastrService.error(message, title);
  }

  /**
   * Create a warning toastr
   * @param title title
   * @param message message
   */
  warning(message: string, title: string = 'Warning') {
    this.toastrService.warning(message, title);
  }

  /**
   * Create a custom toastr
   * @param title title
   * @param msg message
   * @param type notification type
   */
  toast(title: string, msg: string, type: NotificationType) {

    switch (type) {
      case NotificationType.INFO:
        this.toastrService.info(msg, title);
        break;
      case NotificationType.SUCCESS:
        this.toastrService.success(msg, title);
        break;
      case NotificationType.ERROR:
        this.toastrService.error(msg, title);
        break;
      case NotificationType.WARNING:
        this.toastrService.warning(msg, title);
        break;
    }
  }

  handleGatewayAndGraphqlErrors(err) {
    let errorFound = false;
    if (err.networkError && err.networkError.error && err.networkError.error.error) {
      this.handleError(err.networkError.error.error);
      errorFound = true;
    }
    if (err.networkError && err.networkError.error && err.networkError.error.errors && err.networkError.error.errors.length > 0) {
      errorFound = true;
      err.networkError.error.errors.forEach((e) => {
        if (e.message && e.message !== '') {
          if(e.message.includes('Field "buyerSubCategory" of required type "String!" was not provided')){
            e.message = "Buyer mandatory information missing. Please indicate this partner Subcategory as per its Category."
          }
          if(e.message.includes('Field "sellerSubCategory" of required type "String!" was not provided')){
            e.message = "Seller mandatory information missing. Please indicate this partner Subcategory as per its Category."
          }
          this.handleError(e.message);
        }
      });
    }
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
      errorFound = true;
      err.graphQLErrors.forEach((e) => {
        this.handleError(e);
      });
    }
    if (!errorFound) {
      this.handleError(err);
    }
  }

  public handleError(error): void {
    if (error instanceof Response) {
      this.error(error.statusText);
    } else if (typeof error === 'string') {
      this.error(error.toString());
    } else if (error instanceof TypeError) {
      this.error(error.message);
    } else if (error.hasOwnProperty('code') && error.hasOwnProperty('description')) {
      this.error(error.description, `Error ${error.code}!`);
    } else if (error.error) {
      this.error(error.error, 'Error!');
    } else if (error.message) {
      this.error(error.message);
    } else if (error.hasOwnProperty('status') && error.hasOwnProperty('error')) {
      this.error(error.error.error, `Error ${error.status}!`);
    } else {
      console.log(error, error.constructor.name, Object.keys(error));
    }
  }

}
