import { gql } from 'apollo-angular';

export const updateCategory = gql`
mutation ($data:CategoryUpdateInput!,$where:CategoryWhereUniqueInput!){
	social {
		updateCategory(
			where: $where
			data: $data
		) {
			code
			createdAt
			updatedAt
			categoryData {
				code
				description
			}
			adviseMessage {
				code
				description
				level
				external {
					code
					message
				}
			}
		}
	}
}
`;

