<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="agreementForm">
    <nb-card *ngIf="isLoading">
      <tgx-spinner [isLoading]="isLoading"></tgx-spinner>
    </nb-card>
    <nb-card *ngIf="!isLoading">
      <nb-card-header> Data </nb-card-header>
      <nb-card-body>
        <div class="row mt-2" *ngIf="isCreating">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="typeTemplate"
                >Base template
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="agreementForm.get('typeTemplate')"
                [errors]="agreementForm.get('typeTemplate').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('typeTemplate')"
                  [options]="typeOptions"
                  optionLabel="label"
                  filterBy="label"
                  class="form-control p-0 border-0 w-100"
                  formControlName="typeTemplate"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [ngClass]="{
                    'form-control-danger':
                      agreementForm.get('typeTemplate').errors && agreementForm.get('typeTemplate').touched
                  }"
                  placeholder="Select a type..."
                  name="typeTemplate"
                  >>
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="agreementForm.get('typeTemplate')?.value">
                      <div>{{ agreementForm.get('typeTemplate').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-cur pTemplate="cur">
                    <div>
                      <div>{{ cur.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="version"
                >Version
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="agreementForm.get('version')"
                [errors]="agreementForm.get('version').errors"
              ></tooltip-error>
              <input
                (blur)="checkVersion($event.target.value)"
                type="number"
                class="form-control"
                formControlName="version"
                [ngClass]="{
                  'form-control-danger': agreementForm.get('version').errors && agreementForm.get('version').touched
                }"
                placeholder="Enter a version..."
                name="version"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="isCreating">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="container"
                >Pdf File
                <span class="mandatory ml-1">*</span>
              </label>
              <input type="file" class="file-input" (change)="onFileSelected($event)" #filePdfUpload />
              <div class="file-upload">
                <button class="btn btn-md btn-primary" (click)="filePdfUpload.click()">
                  {{ container?.name || 'Attach file' }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="contractId"
                >Contract
                <span class="mandatory ml-1">*</span>
              </label>
              <tooltip-error
                [element]="agreementForm.get('contractId')"
                [errors]="agreementForm.get('contractId').errors"
              ></tooltip-error>
              <div class="form-group">
                <p-dropdown
                  (onHide)="validDropdown('contractId')"
                  [options]="contractOptions"
                  optionLabel="label"
                  filterBy="label"
                  class="form-control p-0 border-0 w-100"
                  formControlName="contractId"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  [ngClass]="{
                    'form-control-danger':
                      agreementForm.get('contractId').errors && agreementForm.get('contractId').touched
                  }"
                  placeholder="Select a type..."
                  name="contractId"
                  >>
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="agreementForm.get('contractId')?.value">
                      <div>{{ agreementForm.get('contractId').value.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-cur pTemplate="cur">
                    <div>
                      <div>{{ cur.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1" for="free">Approved</label>
              <nb-checkbox class="form-control" name="approved" formControlName="approved"></nb-checkbox>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Cancel</button>
  <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
