import { gql } from 'apollo-angular';

export const getAllClients = gql`
query {
  admin{
    allClients{
      edges{
        node{
          clientData{
            code
            name
          }
        }
      }
    }
  }
}
`;
