import { activateAccess } from './activateAccess';
import { activateClient } from './activateClient';
import { activateProvider } from './activateProvider';
import { activateSupplier } from './activateSupplier';
import { appendAccessMarkets } from './appendAccessMarkets';
import { appendAccessParameters } from './appendAccessParameters';
import { appendAccessRateRules } from './appendAccessRateRules';
import { archiveAccess } from './archiveAccess';
import { archiveClient } from './archiveClient';
import { archiveSupplier } from './archiveSupplier';
import { changeStatusClientLegacyConnection } from './changeStatusClientLegacyConnection';
import { createAccess } from './createAccess';
import { createClient } from './createClient';
import { createGroupContent } from './createGroupContent';
import { createLegacyConnection } from './createLegacyConnection';
import { createProvider } from './createProvider';
import { createSupplier } from './createSupplier';
import { deactivateAccess } from './deactivateAccess';
import { deactivateClient } from './deactivateClient';
import { deactivateProvider } from './deactivateProvider';
import { deactivateSupplier } from './deactivateSupplier';
import { deleteAccessMarkets } from './deleteAccessMarkets';
import { deleteAccessParameters } from './deleteAccessParameters';
import { deleteAccessRateRules } from './deleteAccessRateRules';
import { deleteClient } from './deleteClient';
import { grantAccessToGroups } from './grantAccessToGroups';
import { grantClientToGroup } from './grantClientToGroup';
import { grantSupplierToGroups } from './grantSupplierToGroups';
import { revokeAccessFromGroups } from './revokeAccessFromGroups';
import { revokeSupplierFromGroups } from './revokeSupplierFromGroups';
import {
  safeDeactivateAccess,
  safeDeactivateClient,
  safeDeactivateProvider,
  safeDeactivateSupplier,
} from './safeDeactivates';
import { setAccessMaster } from './setAccessMaster';
import { setAccessShared } from './setAccessShared';
import { unArchiveAccess } from './unArchiveAccess';
import { unArchiveClient } from './unArchiveClient';
import { unArchiveSupplier } from './unArrchiveSupplier';
import { updateAccess } from './updateAccess';
import { updateHubUser } from './updateHubUser';
import { updateProvider } from './updateProvider';
import { updateSupplier } from './updateSupplier';

import { archiveMember } from '../../../entities/services/mutations/archiveMember';
import { activateMemberIAM } from './activateMemberIAM';
import { addMemberToOrganization } from './addMemberToOrg';
import { archiveOrganization } from './archiveOrganization';
import { blackListOrganization } from './blackListOrganization';
import { createMember } from './createMember';
import { deactivateMemberIAM } from './deactivateMemberIAM';
import { deleteOrganization } from './deleteOrganization';
import { removeAccessMaster } from './removeAccessMaster';
import { removeAccessShared } from './removeAccessShared';
import { removeMemberFromOrganization } from './removeMemberFromOrg';
import { removeOrganizationPartner } from './removeOrganizationPartner';
import { removeSupplierDefaultSharedAccess } from './removeSupplierDefaultSharedAccess';
import { setIsPublicSupplier } from './setIsPublicSupplier';
import { setOrganizationPartner } from './setOrganizationPartner';
import { setSupplierDefaultSharedAccess } from './setSupplierDefaultSharedAccess';
import { unArchiveOrganization } from './unArchiveOrganization';
import { unBlackListOrganization } from './unBlackListOrganization';
import { unarchiveMember } from './unarchiveMember';
import { updateMember } from './updateMember';
import { updateMemberRole } from './updateMemberRole';
import { updateOrganizationGiata } from './updateOrganizationGiata';
import { updateSupplierGiata } from './updateSupplierGiata';
import { activateApikey } from './activateApikey';
import { deactivateApikey } from './deactivateApikey';
import { archiveApikey } from './archiveApikey';
import { unArchiveApikey } from './unArchiveApikey';
import { safeDeactivateApikey } from './safeDeactivateApikey';
import { addMemberToOrganizationSafe } from './addMemberToOrgSafe ';
import { SetOrganizationTestValue } from './setOrganizationTestValue';
import { changeOrganizationMemberOwner } from './changeOrganizationMemberOwner';
import { setOrganizationOwner } from './setOrganizationOwner';
import { removeOrganizationOwner } from './removeOrganizationOwner';

export const GraphqlGatewayMutations = {
  activateClient: activateClient,
  deactivateClient: deactivateClient,
  createClient: createClient,
  deleteClient: deleteClient,
  grantClientToGroup: grantClientToGroup,
  changeStatusClientLegacyConnection: changeStatusClientLegacyConnection,
  createLegacyConnection: createLegacyConnection,
  updateHubUser: updateHubUser,
  activateProvider: activateProvider,
  deactivateProvider: deactivateProvider,
  activateSupplier: activateSupplier,
  setIsPublicSupplier: setIsPublicSupplier,
  deactivateSupplier: deactivateSupplier,
  createSupplier: createSupplier,
  updateSupplier: updateSupplier,
  createProvider: createProvider,
  updateProvider: updateProvider,
  grantSupplierToGroups: grantSupplierToGroups,
  updateSupplierGiata: updateSupplierGiata,
  revokeSupplierFromGroups: revokeSupplierFromGroups,
  createGroupContent: createGroupContent,
  activateAccess: activateAccess,
  deactivateAccess: deactivateAccess,
  safeDeactivateAccess: safeDeactivateAccess,
  safeDeactivateClient: safeDeactivateClient,
  safeDeactivateSupplier: safeDeactivateSupplier,
  safeDeactivateProvider: safeDeactivateProvider,
  updateAccess: updateAccess,
  createAccess: createAccess,
  grantAccessToGroups: grantAccessToGroups,
  revokeAccessFromGroups: revokeAccessFromGroups,
  archiveClient: archiveClient,
  unArchiveClient: unArchiveClient,
  archiveSupplier: archiveSupplier,
  unArchiveSupplier: unArchiveSupplier,
  archiveAccess: archiveAccess,
  unArchiveAccess: unArchiveAccess,
  appendAccessRateRules: appendAccessRateRules,
  deleteAccessRateRules: deleteAccessRateRules,
  appendAccessParameters: appendAccessParameters,
  deleteAccessParameters: deleteAccessParameters,
  appendAccessMarkets: appendAccessMarkets,
  setSupplierDefaultSharedAccess: setSupplierDefaultSharedAccess,
  removeSupplierDefaultSharedAccess: removeSupplierDefaultSharedAccess,
  deleteAccessMarkets: deleteAccessMarkets,
  setAccessShared: setAccessShared,
  setAccessMaster: setAccessMaster,
  removeAccessShared: removeAccessShared,
  removeAccessMaster: removeAccessMaster,
  addMemberToOrganization: addMemberToOrganization,
  addMemberToOrganizationSafe: addMemberToOrganizationSafe,
  updateMember: updateMember,
  updateMemberRole: updateMemberRole,
  removeMemberFromOrganization: removeMemberFromOrganization,
  createMember: createMember,
  archiveMember: archiveMember,
  unarchiveMember: unarchiveMember,
  activateMemberIAM: activateMemberIAM,
  deactivateMemberIAM: deactivateMemberIAM,
  removeOrganizationPartner: removeOrganizationPartner,
  setOrganizationPartner: setOrganizationPartner,
  archiveOrganization: archiveOrganization,
  unArchiveOrganization: unArchiveOrganization,
  deleteOrganization: deleteOrganization,
  blackListOrganization: blackListOrganization,
  unBlackListOrganization: unBlackListOrganization,
  updateOrganizationGiata: updateOrganizationGiata,
  activateApikey: activateApikey,
  deActivateApikey: deactivateApikey,
  archiveApikey: archiveApikey,
  unArchiveApikey: unArchiveApikey,
  safeDeactivateApikey: safeDeactivateApikey,
  SetOrganizationTestValue: SetOrganizationTestValue,
  changeOrganizationMemberOwner: changeOrganizationMemberOwner,
  setOrganizationOwner: setOrganizationOwner,
  removeOrganizationOwner: removeOrganizationOwner,
};
