import { Injectable } from '@angular/core';
import { MembersRole } from '@tgx/shared/enums';
import { ToastsService } from '@tgx/shared/toasts';
import { combineLatest } from 'rxjs';
import { cloneDeep } from 'lodash';
import { PlatformInfoService } from './platform-info.service';
import { GqlService, socialQueries, socialMutations, authQueries } from '@tgx/shared/data-access/graphql';
import { IAuth0Permissions, IAuth0User, Members, UserData, UserProfile, UserProfileData } from '@tgx/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  selectedUserEmail: string = '';
  userData: UserData;
  memberChanged: boolean = true;

  constructor(
    private gqlService: GqlService,
    private toastsService: ToastsService,
    private platformInfoService: PlatformInfoService,
  ) {
    this.platformInfoService.userLogged$.subscribe((userData) => {
      this.userData = userData;
    });

    combineLatest([
      this.platformInfoService.userAuthenticated$,
      this.platformInfoService.organizationSelected,
    ]).subscribe(async ([auth0Data, orgSelected]) => {
      if (auth0Data && orgSelected) {
        const previousEmail = this.selectedUserEmail;
        this.selectedUserEmail = auth0Data.impersonation || auth0Data.email;

        // memberChanged is set to 'true' when 'previousEmail' is undefined,
        // which means that the user just logged in.
        this.memberChanged = this.selectedUserEmail !== previousEmail ?? true;

        const isStaff = this.isStaff();

        const { role, permissions } = this.retrieveUserPermissions(isStaff, auth0Data);

        if (this.memberChanged) {
          const userProfile: UserProfileData = await this.retrieveUserProfile();

          this.platformInfoService.userLogged$.next({
            role: role,
            permissions: permissions,
            isStaff: isStaff,
            picture: auth0Data.picture,
            hasUserProfile: userProfile != undefined,
            ...userProfile,
          });
        } else {
          // If is the same member, just update the role the new selected org.
          const currentUserData = cloneDeep(this.userData);
          currentUserData.role = role;
          this.platformInfoService.userLogged$.next(currentUserData);
        }
      }
    });
  }

  private retrieveUserPermissions(
    isStaff: boolean,
    auth0Data: IAuth0User,
  ): { role: MembersRole; permissions: IAuth0Permissions } {
    let memberRole: MembersRole;
    if (auth0Data.permissions.has(this.platformInfoService.selectedOrg.code)) {
      memberRole = auth0Data.permissions.get(this.platformInfoService.selectedOrg.code).role;
    }

    if (!memberRole && isStaff) memberRole = MembersRole.NONE;

    return { role: memberRole, permissions: auth0Data.permissions.get(this.platformInfoService.selectedOrg.code) };
  }

  private async retrieveUserProfile(): Promise<UserProfileData> {
    const body = {
      where: {
        email: this.selectedUserEmail,
      },
    };

    const result: { social: { userProfile: UserProfile } } = await this.gqlService.queryGateway(
      socialQueries.getUserProfile,
      body,
    );

    const userProfile: UserProfile = result.social.userProfile;

    if (userProfile.adviseMessage !== null) {
      //TODO: Uncomment this toast when the create userProfile functionality from Social is implemented
      // this.toastsService.error(userProfile.adviseMessage[0].description);
      return { email: this.selectedUserEmail };
    }

    return userProfile.userProfileData;
  }

  async createUserProfile(data: {} = {}) {
    const body = {
      where: {
        email: this.selectedUserEmail,
      },
      data,
    };

    const result: { social: { updateUserProfile: UserProfile } } = await this.gqlService.mutationGateway(
      socialMutations.createUserProfile,
      body,
    );

    const userProfile: UserProfile = result.social.updateUserProfile;

    if (userProfile.adviseMessage !== null) {
      this.toastsService.error(userProfile.adviseMessage.description);
      return null;
    }

    this.platformInfoService.userLogged$.next({
      role: this.userData.role,
      permissions: this.userData.permissions,
      isStaff: this.userData.isStaff,
      picture: this.userData.picture,
      hasUserProfile: userProfile != undefined,
      ...userProfile.userProfileData,
    });

    return userProfile.userProfileData;
  }

  async updateUserProfile(data: {} = {}) {
    const body = {
      where: {
        email: this.selectedUserEmail,
      },
      data,
    };

    const result: { social: { updateUserProfile: UserProfile } } = await this.gqlService.mutationGateway(
      socialMutations.updateUserProfile,
      body,
    );

    const userProfile: UserProfile = result.social.updateUserProfile;

    if (userProfile?.adviseMessage !== null) {
      this.toastsService.error(userProfile.adviseMessage.description);
      return null;
    }

    this.platformInfoService.userLogged$.next({
      role: this.userData.role,
      permissions: this.userData.permissions,
      isStaff: this.userData.isStaff,
      picture: this.userData.picture,
      hasUserProfile: userProfile != undefined,
      ...userProfile.userProfileData,
    });

    return userProfile.userProfileData;
  }

  private isStaff(): boolean {
    const email = this.selectedUserEmail;
    const emailMatches = email.match(/.*@(.*)\..*/) || [];
    const emailDomain = emailMatches[1] || '';
    return emailDomain.includes('travelgate');
  }
}
