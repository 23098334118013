import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BillingAccount, Partner } from '../../../../interfaces/_index.billing.interfaces';
import { BillingAccountService, PartnerService } from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../entities/interfaces/_index-entities.interfaces';
import { BillingAccountModalComponent } from './../billing-accounts-modal/billing-accounts-modal.component';

@Component({
  selector: 'tgx-billing-account-table',
  templateUrl: './billing-accounts-table.component.html',
  styleUrls: ['./billing-accounts-table.component.scss'],
})
export class BillingAccountTableComponent implements OnInit, OnDestroy {
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Name', field: 'name' },
    { label: 'Country code', field: 'countryCode' },
    { label: 'Fiscal address', field: 'fiscalAddress' },
    { label: 'Odoo code', field: 'odooCode' },
    { label: 'Options', field: 'Options' },
  ];

  billingAccounts: BillingAccount[];
  billingAccountsLoadSource: any[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  isEditor = false;

  selectedBA: BillingAccount;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private billingAccountService: BillingAccountService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
  ) {}

  ngOnInit(): void {
    this.isLoading = false;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getBillingAccounts(this.partner.orgCode);
            } else {
              this.partner = null;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getBillingAccounts(orgCode: string) {
    this.isLoading = true;

    if (orgCode) {
      this.billingAccountService
        .getBillingAccounts(orgCode)
        .then((bas) => {
          this.billingAccounts = [];
          if (bas.length > 0) {
            this.billingAccounts = bas;
          }
          this.buildBAs();

          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  buildBAs() {
    this.billingAccountsLoadSource = [];

    if (this.billingAccounts?.length > 0) {
      this.billingAccounts.forEach((ba) => {
        this.billingAccountsLoadSource.push({
          id: ba.id,
          name: ba.name,
          countryCode: ba.countryCode,
          fiscalAddress: ba.fiscalAddress,
          odooCode: ba.odooCode,
          city: ba.city,
          cif: ba.cif,
          zipCode: ba.zipCode,
          excludeVAT: ba.excludeVAT,
          singleInvoice: ba.singleInvoice,
        });
      });
      this.billingAccountsLoadSource = [...this.billingAccountsLoadSource];
      this.billingAccountsLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
    }
  }

  onCreate(): void {
    if (!this.partner) {
      this.notificationService.warning(
        'This organization has not partner in billing section, first create it and then you will be able to create its billing accounts.',
      );
      return;
    }

    const activeModal = this.modalService.open(BillingAccountModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create BillingAccount';
    activeModal.componentInstance.partnerId = this.partner.id;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getBillingAccounts(self.partner.orgCode);
      }
    });
  }

  onEdit(event): void {
    const billingAccount: BillingAccount = event;

    const activeModal = this.modalService.open(BillingAccountModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = (this.isEditor ? 'Edit ' : 'View ') + 'BillingAccount';
    activeModal.componentInstance.billingAccount = billingAccount;
    activeModal.componentInstance.partnerId = this.partner.id;
    activeModal.componentInstance.isEditor = this.isEditor;

    const self = this;
    activeModal.result.then((res) => {
      // OK
      if (typeof res === 'object') {
        self.getBillingAccounts(self.partner.orgCode);
      }
    });
  }
}
