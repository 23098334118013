import { gql } from 'apollo-angular';

export const getMinInfoTeams = gql`
  query {
    admin {
      groups(type: TEAM) {
        totalCount
        edges {
          node {
            adviseMessage {
              code
              description
              level
            }
            groupData {
              code
              label
            }
          }
        }
      }
    }
  }
`;
