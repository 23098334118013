import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'apps/web-admin/src/app/@core/services/notification.service';
import { AddMemberToOrganizationInput } from '../../../../interfaces/_index-entities.interfaces';
import { OrganizationNetworkService } from '../../../../services/_index-entities.services';
import { MemberNetworkService } from '../../../../services/member-network.services';

@Component({
  selector: 'tgx-member-modal',
  templateUrl: './add-member-to-org-modal.component.html',
  styleUrls: ['./add-member-to-org-modal.component.scss'],
})
export class AddMemberToOrganizationModalComponent implements OnInit {
  @Input() member: any = null;
  @Input() org: any = null;
  @Input() role: any = null;

  form: UntypedFormGroup;
  allOrgs = [];
  isLoading: boolean;
  isFormReady;
  totalOrgs: any;
  isCardHidden: boolean = true;

  memberRoleOptions = [
    { code: 'ADMIN', label: 'Admin' },
    { code: 'OWNER', label: 'Owner' },
    { code: 'VIEWER', label: 'Viewer' },
  ];
  constructor(
    private notificationService: NotificationService,
    private organizationNetworkService: OrganizationNetworkService,
    private activeModal: NgbActiveModal,
    private MemberService: MemberNetworkService,
    private fb: UntypedFormBuilder,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  showCard() {
    this.isCardHidden = false;
  }

  ngOnInit(): void {
    this.isFormReady = false;
    this.allOrgs = [];
    this.isLoading = true;
    this.getCurrentOrgs().then(() => {
      this.isLoading = false;
      this.isFormReady = true;
    });
    this.form = this.fb.group({
      member: [this.member ? this.member.code : '', Validators.required],
      org: [this.fb.control(this.org ? this.org.code + this.org.label : '', Validators.required)],
      role: [this.fb.control(this.role ? this.role.code : '', Validators.required)],
    });
    this.form.get('member').disable();
  }

  async getCurrentOrgs(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.organizationNetworkService
        .getOrganizations(null, false)
        .then((rs) => {
          if (rs?.edges?.length === 0) {
            resolve();
          }

          this.allOrgs = rs.edges.map((org) => {
            const orgData = org.node.organizationsData;

            const { label, code } = orgData;
            const organization = `${label} (${code})`;

            return { label, code, organization };
          });

          this.totalOrgs = rs.totalCount;
          resolve();
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          reject(err);
        });
    });
  }
  closeModal() {
    this.activeModal.close(true);
  }

  async confirmSave() {
    //const div = this.el.nativeElement.querySelector('#confirmDialog');
    //this.renderer.removeClass(div, 'anyway_card');
    this.showCard();
  }

  async onSaveAnyway() {
    const input: AddMemberToOrganizationInput = {
      member: this.member.code,
      orgCode: this.form.get('org').value,
      role: this.form.get('role').value,
    };

    this.activeModal.close(
      await this.MemberService.addMemberToOrg(input)
        .then((rs) => {
          this.notificationService.success(
            'Member succesfully addeed to ' + input.orgCode + ' organization.',
            'Success',
          );
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        }),
    );
  }

  async onSave() {
    const input: AddMemberToOrganizationInput = {
      member: this.member.code,
      orgCode: this.form.get('org').value,
      role: this.form.get('role').value,
    };

    //this.activeModal.close(
    await this.MemberService.addMemberToOrgSafe(input)
      .then((rs) => {
        this.notificationService.success('Member succesfully addeed to ' + input.orgCode + ' organization.', 'Success');
        this.activeModal.close();
      })
      .catch((err) => {
        this.confirmSave();
        //this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
    // );
  }
}
