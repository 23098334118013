import {
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'tgx-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void => *', [animate(100)]),
      transition('* => void', [animate(300)]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent {
  @Input() isLoading: boolean;
  @Input() top = '250px';

}
