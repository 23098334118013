import { gql } from 'apollo-angular';

export const appendAccessParameters = gql`mutation appendAccessParameters(
  $where: AccessWhereUniqueInput!
  $data: [ParameterInput!]!
) {
  admin {
    appendAccessParameters(where: $where, data: $data) {
      code
      adviseMessage {
        code
        description
        level
      }
    }
  }
}

`;
