import { gql } from 'apollo-angular';

export const deleteCategory = gql`
mutation($where: CategoryWhereUniqueInput!) {
	social {
		deleteCategory(where: $where) {
			adviseMessage {
				code
				description
				level
				external {
					code
					message
				}
			}
		}
	}
}
`;
