import { gql } from 'apollo-angular';

export const createFolder = gql`
  mutation createFolder($folder: CreateFolderInput!) {
    admin {
      createFolder(folder: $folder) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
