import { gql } from 'apollo-angular';

export const getUserProfile = gql`
  query ($where: UserProfileWhereUniqueInput) {
    social {
      userProfile(where: $where) {
        code
        adviseMessage {
          description
        }
        userProfileData {
          email
          firstName
          lastName
          partnersIncludedIn {
            department
            jobTitle
            organization {
              code
            }
          }
          onboardingProperties {
            startedAt
            userDetailsFinishedAt
            organizationCreated
            sqlStatus
            sqlStartedAt
            sqlFinishedAt
            billingAccountCreated
            tcpAcceptedAt
            annexBuyerAcceptedAt
            annexSellerAcceptedAt
            annexPlatformBuyerAcceptedAt
            annexPlatformSellerAcceptedAt
            finishedAt
          }
        }
      }
    }
  }
`;
