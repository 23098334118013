import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from '@tgx/web/feature-connections';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { clone } from '../../../../../../@core/utils/utils';
import { UpdateOrganizationInput } from '../../../../interfaces/inputs/update-organization-input.interface';
import { OrganizationNetworkService } from '../../../../services/organization-network.service';

@Component({
  selector: 'tgx-organization-modal',
  styleUrls: ['./organization-modal.component.scss'],
  templateUrl: 'organization-modal.component.html',
})
export class OrganizationModalComponent implements OnInit, OnDestroy {
  @Input() organization: any = null;
  @Input() header: string;
  @Input() partners: any;
  @Input() orgDict: any;

  $subs: Subscription[] = [];
  organizationAux: Organization;
  originalOwnerCode: string;
  originalGiataConfiguration: string;
  originalIsActiveGiata: boolean;
  selectedOwner: any;

  originalMemberCode: any;
  selectedMember: any;

  selectedOrgCode: string;

  public organizationForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private organizationNetworkService: OrganizationNetworkService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.organization) {
      this.selectedOwner = { code: this.organization.owner.code, label: this.organization.owner.label };
      this.originalOwnerCode = this.selectedOwner ? this.selectedOwner : null;
      this.originalGiataConfiguration = this.organization ? this.organization.giata.configuration : '';
      this.originalIsActiveGiata = this.organization ? this.organization.giata.isActive : false;

      const ownerMember =
        this.organization?.ownerMember && this.organization?.ownerMember.length > 0
          ? this.organization.ownerMember[0]
          : '';
      this.selectedMember = { code: ownerMember, label: ownerMember };
      this.originalMemberCode = this.selectedMember ? this.selectedMember : null;
    }

    if (this.selectedOwner === undefined) {
      this.selectedOwner = { code: '', label: '' };
      this.originalOwnerCode = '';
    }

    if (this.selectedMember === undefined) {
      this.selectedMember = { code: '', label: '' };
      this.originalMemberCode = '';
    }

    this.organizationForm = this.fb.group({
      code: [this.organization ? this.organization.code : ''],
      label: [this.organization ? this.organization.label : ''],
      owner: this.fb.control(this.organization.owner.code),
      ownerMember: this.fb.control(this.selectedMember.code),
      isPublic: !this.organization ? this.organization.isTest : '',
      isGiataActive: this.originalIsActiveGiata,
      giataConfiguration: this.originalGiataConfiguration,
    });

    this.selectedOrgCode = this.organizationForm.get('code').value;

    this.organizationForm.get('code').disable();
    this.organizationForm.get('label').disable();

    this.organizationAux = clone(this.organization);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.organization = clone(this.organizationAux);
    this.activeModal.close(true);
  }

  async updateOrganizationMemberOwner() {
    let success = false;
    success = await this.organizationNetworkService.changeOrganizationMemberOwner(
      this.selectedOrgCode,
      this.organizationForm.get('ownerMember').value,
    );

    if (success) {
      this.notificationService.success('Organization member owner was updated', 'Success');
    } else {
      this.notificationService.warning('Error updating Organization member owner', 'Warning');
    }
  }

  async updateOrganizationOwner() {
    let success = false;
    if (this.organizationForm.get('owner').value == '') {
      success = await this.organizationNetworkService.removeOrganizationOwner(this.selectedOrgCode);
    } else {
      success = await this.organizationNetworkService.setOrganizationOwner(
        this.selectedOrgCode,
        this.organizationForm.get('owner').value,
      );
    }
    if (success) {
      this.notificationService.success('Organization owner was updated', 'Success');
    } else {
      this.notificationService.warning('Error updating Organization owner', 'Warning');
    }
  }

  async updateOrganizationGiata() {
    const isGiataActiveToChange = this.organizationForm.get('isGiataActive').value;
    const giataConfigurationToChange = this.organizationForm.get('giataConfiguration').value;
    const isGiataActiveChanged = isGiataActiveToChange && this.originalIsActiveGiata !== isGiataActiveToChange;
    const giataConfigurationChanged =
      giataConfigurationToChange && this.originalGiataConfiguration !== giataConfigurationToChange;

    if (isGiataActiveChanged || giataConfigurationChanged) {
      const giataUpdateSuccess = await this.organizationNetworkService.updateOrganizationGiata(
        this.selectedOrgCode,
        giataConfigurationToChange,
        isGiataActiveToChange,
      );
      if (giataUpdateSuccess) {
        this.notificationService.success('Giata was updated', 'Success');
      } else {
        this.notificationService.warning('Error updating Giata', 'Warning');
      }
    }
  }

  async onSave() {
    try {
      if (this.originalMemberCode.code != this.organizationForm.get('ownerMember').value) {
        this.updateOrganizationMemberOwner();
      }

      if (this.organizationForm.get('owner').value != null) {
        this.updateOrganizationOwner();
      }

      this.updateOrganizationGiata();

      this.activeModal.close();
    } catch (error) {
      this.notificationService.handleGatewayAndGraphqlErrors(error);
    }
  }
}
