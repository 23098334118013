import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { IAuth0User } from '@tgx/shared/interfaces';
import { PlatformAuthService, PlatformInfoService } from '@tgx/shared/services';
import { Subscription } from 'rxjs';
import { MenuService } from '../../../@core/services/menu.service';
import { WebAdminService } from '../../../@core/services/web-admin.service';
import { OrganizationSelector } from '../../../features/entities/interfaces/_index-entities.interfaces';
import { AdminUserPermissions, Options } from './../../../@core/interfaces/_index.interfaces';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  $subs: Subscription[] = [];

  userMenu = [];

  organizations: OrganizationSelector[] = [];

  orgsDropdown: Options[];
  orgDropdownSelected: Options;

  userData: IAuth0User;

  hideSelector = true;
  userPermissions: AdminUserPermissions;

  previousUrl: string;

  userRole: string;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private nBMenuService: NbMenuService,
    private webAdminService: WebAdminService,
    private sidebarService: NbSidebarService,
    private platformAuthService: PlatformAuthService,
    private platformInfoService: PlatformInfoService,
  ) {}

  ngOnInit() {
    this.hideSelector = true;

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe((orgSelected) => {
        if (orgSelected) {
          this.orgDropdownSelected = this.buildDropdownItem(orgSelected);
        }
      }),
    );

    this.$subs.push(
      this.platformInfoService.userAuthenticated$.subscribe((p) => {
        this.userData = p;
      }),
    );

    this.$subs.push(
      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.organizations = orgs;
          this.buildDropdown();
        }
      }),
    );

    this.$subs.push(
      this.webAdminService.userPermissions.subscribe((up) => {
        this.userPermissions = up;
        this.buildDropdownMenu();
      }),
    );

    this.$subs.push(
      this.webAdminService.userRole.subscribe((ur) => {
        if (this.userPermissions) {
          this.userRole = ur;
        }
      }),
    );

    this.nBMenuService.onItemClick().subscribe((option) => {
      if (option.item.title === 'Log out') {
        this.logout();
      }
      if (option.item.title === 'Back Home') {
        this.menuService.buildMenu(this.webAdminService.userPermissions.value);
        if (this.previousUrl) {
          this.router.navigate([this.previousUrl]);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.includes('/dashboard') ||
          event.urlAfterRedirects.includes('/dashboard') ||
          event.url.includes('/mapping/mapping-tool') ||
          event.urlAfterRedirects.includes('/mapping/mapping-tool') ||
          event.url.includes('/billing/partners') ||
          event.urlAfterRedirects.includes('/billing/partners') ||
          event.url.includes('/billing/dashboard') ||
          event.urlAfterRedirects.includes('/billing/dashboard') ||
          event.url.includes('/billing/reports') ||
          event.urlAfterRedirects.includes('/billing/reports') ||
          event.url.includes('/billing/supplier') ||
          event.urlAfterRedirects.includes('/billing/supplier') ||
          event.url.includes('/billing/contract/load') ||
          event.urlAfterRedirects.includes('/billing/contract/load') ||
          event.url.includes('/billing/connections/load') ||
          event.urlAfterRedirects.includes('/billing/connections/load') ||
          event.url.includes('/billing/connections/relations') ||
          event.urlAfterRedirects.includes('/billing/connections/relations') ||
          event.url.includes('/billing/connections/inconclusive') ||
          event.urlAfterRedirects.includes('/billing/connections/inconclusive') ||
          event.url.includes('/billing/costs/default-costs') ||
          event.urlAfterRedirects.includes('/billing/costs/default-costs') ||
          event.url.includes('/billing/contract/subtypes') ||
          event.urlAfterRedirects.includes('/billing/contract/subtypes') ||
          event.url.includes('/entities/organizations') ||
          event.urlAfterRedirects.includes('/entities/organizations') ||
          event.url.includes('/network/profiles') ||
          event.urlAfterRedirects.includes('/network/profiles') ||
          event.url.includes('/network/categories') ||
          event.urlAfterRedirects.includes('/network/categories') ||
          event.url.includes('/network/subcategories') ||
          event.urlAfterRedirects.includes('/network/subcategories') ||
          event.url.includes('/network/signup-status') ||
          event.urlAfterRedirects.includes('/network/signup-status') ||
          event.url.includes('/entities/members') ||
          event.urlAfterRedirects.includes('/entities/members') ||
          event.url.includes('/entities/apikeys') ||
          event.urlAfterRedirects.includes('/entities/apikeys') ||
          event.url.includes('/admin/') ||
          event.urlAfterRedirects.includes('/admin/') ||
          event.url.includes('/entities/legacy-connections') ||
          event.urlAfterRedirects.includes('/entities/legacy-connections') ||
          event.url.includes('/entities/suppliers') ||
          event.urlAfterRedirects.includes('/entities/suppliers') ||
          event.url.includes('/entities/providers') ||
          event.urlAfterRedirects.includes('/entities/providers') ||
          event.url.includes('/entities/clients') ||
          event.urlAfterRedirects.includes('/entities/clients') ||
          event.url.includes('/entities/accesses') ||
          event.urlAfterRedirects.includes('/entities/accesses') ||
          event.url.includes('/entities/group-content-context') ||
          event.urlAfterRedirects.includes('/entities/group-content-context') ||
          event.url.includes('/apps/') ||
          event.urlAfterRedirects.includes('/apps/') ||
          event.url.includes('/apps/logging') ||
          event.urlAfterRedirects.includes('/apps/logging') ||
          event.url.includes('/apps/audit') ||
          event.urlAfterRedirects.includes('/apps/audit') ||
          event.url.includes('/apps/hotel-x-certificator') ||
          event.urlAfterRedirects.includes('/apps/hotel-x-certificator') ||
          event.url.includes('/data/') ||
          event.urlAfterRedirects.includes('/data/') ||
          event.url.includes('/content/') ||
          event.urlAfterRedirects.includes('/content/') ||
          event.url.includes('/billing/agreements/templates') ||
          event.urlAfterRedirects.includes('/billing/agreements/templates') ||
          event.url.includes('/web/web-banner') ||
          event.urlAfterRedirects.includes('/web/web-banner') ||
          event.url.includes('/billing/payment-requests/global') ||
          event.urlAfterRedirects.includes('/billing/payment-requests/global') ||
          event.url.includes('/billing/invoicing-process') ||
          event.urlAfterRedirects.includes('/billing/invoicing-process') ||
          event.url.includes('/billing/connections/main') ||
          event.urlAfterRedirects.includes('/billing/connections/main')
        ) {
          this.hideSelector = true;
        } else {
          this.hideSelector = false;
        }
        if (!event.url.includes('admin')) {
          this.previousUrl = event.url;
        }
      }
    });
  }

  buildDropdown() {
    this.orgsDropdown = [];
    this.organizations.forEach((org) => {
      this.orgsDropdown.push(this.buildDropdownItem(org));
    });
  }

  buildDropdownMenu() {
    this.userMenu = [{ title: 'Log out' }];
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  changeOrganization(orgCode?: string) {
    const code = orgCode ? orgCode : this.orgDropdownSelected.code;

    const index = this.organizations.findIndex((x) => x.code === code);
    if (index > -1) {
      this.webAdminService.changeOrganization(this.organizations[index]);
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.nBMenuService.navigateHome();
    return false;
  }

  logout(): void {
    this.platformAuthService.logout();
  }

  buildDropdownItem(org: OrganizationSelector): Options {
    return {
      code: org.code,
      label: org.label + ' (' + org.code + ')',
    };
  }
}
