import { Component, OnDestroy } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { AuthService } from '@tgx/shared/services';
import { Observable, Subscription } from 'rxjs';
import { RootState } from './@core/interfaces/store/_index.store';
import * as FromUser from './@core/interfaces/store/_index.store';
import { MenuService } from './@core/services/_index-core.services';

@Component({
  selector: 'tgx-admin',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  accessToken$: Observable<string> = this.store.select(FromUser.selectAccessToken);

  $subs: Subscription[] = [];

  menu: NbMenuItem[] = [];

  constructor(private authService: AuthService, private store: Store<RootState>, private menuService: MenuService) {
    this.authService.handleAuthentication(true);

    this.$subs.push(
      this.menuService.menu.subscribe((menu) => {
        this.menu = menu;
        if (this.menu.length > 0) {
          if (this.menu.findIndex((x) => x.title === 'Back Home') > -1 && this.menu.length > 1) {
            this.menu[2].expanded = true;
            this.menu[2].children[0].selected = true;
          } else if (this.menu.findIndex((x) => x.selected === true) === -1) {
            this.menu[1].selected = true;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }
}
