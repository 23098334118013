import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { NbCardModule, NbCheckboxModule, NbTabsetModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionRoutingModule } from './connection.routing-module';
import { BillingConnectionsTableComponent } from './components/connections-table/connections-table.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { BillingConnectionsModalComponent } from './components/connections-modal/connections-modal.component';
import { BillingConnetionsComponent } from './connections.component';
import { BillingLoadConnectionsComponent } from './components/connections-load/connections-load.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BillingConnectionsRelationsComponent } from './components/connections-relations/connections-relations.component';
import { BillingConnectionCloneModalComponent } from './components/connections-clone-modal/connections-clone-modal.component';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { BillingInconclusiveBreakdownsTableComponent } from './components/connections-inconclusive-breakdown-table/connections-inconclusive-breakdown-table.component';
import { BillingInconclusiveTableComponent } from './components/connections-inconclusive-table/connections-inconclusive-table.component';
import { BillingConnectionsinconclusiveModalComponent } from './components/connections-inconclusive-modal/connections-inconclusive-modal.component';
import { BillingConnectionsMainTableComponent } from './components/connections-main-table/connections-main-table.component';
import { BillingConnectionsMainModalComponent } from './components/connections-main-modal/connections-main-modal.component';

@NgModule({
  imports: [
    TableModule,
    CheckboxModule,
    SharedModule,
    ConnectionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    CommonModule,
    NbCheckboxModule,
    DropdownModule,
    CalendarModule,
    AutoCompleteModule,
    NbTabsetModule,
  ],
  declarations: [
    BillingConnetionsComponent,
    BillingConnectionsTableComponent,
    BillingConnectionsModalComponent,
    BillingLoadConnectionsComponent,
    BillingConnectionsRelationsComponent,
    BillingConnectionCloneModalComponent,
    BillingInconclusiveBreakdownsTableComponent,
    BillingInconclusiveTableComponent,
    BillingConnectionsinconclusiveModalComponent,
    BillingConnectionsMainTableComponent,
    BillingConnectionsMainModalComponent,
  ],
})
export class ConnectionModule {}
