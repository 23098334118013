import { gql } from 'apollo-angular';

export const archiveClient = gql`
mutation ($where: ClientWhereUniqueInput!) {
    admin {
      archiveClient(where: $where) {
        code
        adviseMessage {
            code
            description
            level            
        }
      }
    }
  }
`;