import { gql } from 'apollo-angular';

export const unarchiveMember = gql`
  mutation unArchiveMember($memberCode: ID!) {
    admin {
      unArchiveMember(member: { code: $memberCode }) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
