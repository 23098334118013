import { gql } from 'apollo-angular';

export const deleteL2BCost = gql`
mutation DeleteL2bCost($where: BookingL2BCostWhereUniqueInput!) {
    deleteBookingL2BCost(where: $where) {
      id
    }
  }
  
`;
