import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProvidersService } from '../../../../services/providers.service';

@Component({
  selector: 'tgx-admin-provider-modal',
  templateUrl: './provider-modal.component.html',
  styleUrls: ['./provider-modal.component.scss'],
})
export class ProviderModalComponent implements OnInit {
  @Input() header: string;
  @Input() provider: any;
  @Input() isCreating: boolean;

  isLoading: boolean;

  public providerForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private ProvidersService: ProvidersService
  ) {}

  ngOnInit(): void {
    this.providerForm = this.fb.group({
      code: [this.provider ? this.provider.code : '', Validators.required],
      name: [this.provider ? this.provider.name : '', Validators.required],
      isActive: [this.provider ? this.provider.isActive : false, Validators.required],
    });

    if (!this.isCreating) {
      this.providerForm.get('code').disable();
    }
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    if (this.isCreating) {
      console.log('Not yet implemented');
      this.activeModal.close(
        await this.ProvidersService.createProvider({
          code: this.providerForm.get('code').value,
          name: this.providerForm.get('name').value,
          isActive: this.providerForm.get('isActive').value,
        })
      );
    } else {
      this.activeModal.close(
        await this.ProvidersService.updateProvider({
          code: this.providerForm.get('code').value,
          name: this.providerForm.get('name').value,
          isActive: this.providerForm.get('isActive').value,
        })
      );
    }
  }
}
