<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="accessForm">
    <p-tabView>
      <p-tabPanel header="Access" [selected]="true">
        <nb-card>
          <nb-card-header> Access </nb-card-header>
          <nb-card-body>
            <div class="row"></div>
            <div class="row mt-2">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('code')?.errors && accessForm.get('code').touched
                  }"
                >
                  <label class="mb-1" for="label">Code</label>
                  <tooltip-error [element]="accessForm.get('code')" [errors]="accessForm.get('code').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="code"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('code')?.errors && accessForm.get('code').touched
                    }"
                    placeholder="Enter a valid code"
                    name="code"
                  />
                </div>
              </div>
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('name')?.errors && accessForm.get('name').touched
                  }"
                >
                  <label class="mb-1" for="label">Name</label>
                  <tooltip-error [element]="accessForm.get('name')" [errors]="accessForm.get('name').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('name')?.errors && accessForm.get('name').touched
                    }"
                    placeholder="Enter a valid name"
                    name="name"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <nb-card>
                <div class="row mt-2">
                  <div class="col-3">
                    <div class="form-group">
                      <label class="mb-1" for="isActive">Is Active</label>
                      <tooltip-error
                        [element]="accessForm.get('isActive')"
                        [errors]="accessForm.get('isActive').errors"
                      >
                      </tooltip-error>
                      <nb-toggle
                        class="form-control"
                        [ngClass]="{
                          'form-control-danger': accessForm.get('isActive').errors && accessForm.get('isActive').touched
                        }"
                        name="isActive"
                        formControlName="isActive"
                      ></nb-toggle>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="form-group">
                      <label class="mb-1" for="isTest">Is Test</label>
                      <tooltip-error [element]="accessForm.get('isTest')" [errors]="accessForm.get('isTest').errors">
                      </tooltip-error>
                      <nb-toggle
                        class="form-control"
                        [ngClass]="{
                          'form-control-danger': accessForm.get('isTest').errors && accessForm.get('isTest').touched
                        }"
                        name="isTest"
                        formControlName="isTest"
                      ></nb-toggle>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="form-group">
                      <label class="mb-1" for="isSchedulerActive">is Scheduler Active</label>
                      <tooltip-error
                        [element]="accessForm.get('isSchedulerActive')"
                        [errors]="accessForm.get('isSchedulerActive').errors"
                      >
                      </tooltip-error>
                      <nb-toggle
                        class="form-control"
                        [ngClass]="{
                          'form-control-danger':
                            accessForm.get('isSchedulerActive').errors && accessForm.get('isSchedulerActive').touched
                        }"
                        name="isSchedulerActive"
                        formControlName="isSchedulerActive"
                      ></nb-toggle>
                    </div>
                  </div>

                  <div class="col-3" *ngIf="!isCreating">
                    <div class="form-group">
                      <label class="mb-1" for="isShared">is Shared</label>
                      <tooltip-error
                        [element]="accessForm.get('isShared')"
                        [errors]="accessForm.get('isShared').errors"
                      >
                      </tooltip-error>
                      <nb-toggle
                        class="form-control"
                        [ngClass]="{
                          'form-control-danger': accessForm.get('isShared').errors && accessForm.get('isShared').touched
                        }"
                        name="isShared"
                        formControlName="isShared"
                      ></nb-toggle>
                    </div>
                  </div>
                </div>
              </nb-card>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger':
                      accessForm.get('supplierCode')?.errors && accessForm.get('supplierCode').touched
                  }"
                >
                  <label class="mb-1" for="label">Supplier</label>
                  <tooltip-error
                    [element]="accessForm.get('supplierCode')"
                    [errors]="accessForm.get('supplierCode').errors"
                  >
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="supplierCode"
                    [ngClass]="{
                      'form-control-danger':
                        accessForm.get('supplierCode')?.errors && accessForm.get('supplierCode').touched
                    }"
                    placeholder="Enter a valid supplierCode"
                    name="supplierCode"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('user')?.errors && accessForm.get('user').touched
                  }"
                >
                  <label class="mb-1" for="label">User</label>
                  <tooltip-error [element]="accessForm.get('user')" [errors]="accessForm.get('user').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="user"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('user')?.errors && accessForm.get('user').touched
                    }"
                    placeholder="Enter a valid user"
                    name="user"
                  />
                </div>
              </div>
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('password')?.errors && accessForm.get('password').touched
                  }"
                >
                  <label class="mb-1" for="label">Password</label>
                  <tooltip-error [element]="accessForm.get('password')" [errors]="accessForm.get('password').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="password"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('password')?.errors && accessForm.get('password').touched
                    }"
                    placeholder="Enter a valid password"
                    name="password"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2" *ngIf="!isCreating">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('master')?.errors && accessForm.get('master').touched
                  }"
                >
                  <label class="mb-1" for="label">Master</label>
                  <tooltip-error [element]="accessForm.get('master')" [errors]="accessForm.get('master').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="master"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('master')?.errors && accessForm.get('master').touched
                    }"
                    placeholder="Enter a valid master"
                    name="master"
                  />
                </div>
              </div>
              <div class="col-6" *ngIf="!isCreating">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('shared')?.errors && accessForm.get('shared').touched
                  }"
                >
                  <label class="mb-1" for="label">Shared</label>
                  <tooltip-error [element]="accessForm.get('shared')" [errors]="accessForm.get('shared').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="shared"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('shared')?.errors && accessForm.get('shared').touched
                    }"
                    placeholder="Enter a valid shared"
                    name="shared"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('rateRules')?.errors && accessForm.get('rateRules').touched
                  }"
                >
                  <label class="mb-1" for="label">Rate Rules</label>
                  <tooltip-error [element]="accessForm.get('rateRules')" [errors]="accessForm.get('rateRules').errors">
                  </tooltip-error>
                  <p-multiSelect
                    [options]="rateRulesOptions"
                    formControlName="rateRules"
                    display="chip"
                    optionLabel="label"
                    [appendTo]="'body'"
                    baseZIndex="999"
                    [(ngModel)]="iRateRules"
                    (onChange)="setRateRulesForUpdate()"
                    selectedItemsLabel="{0} Rate rule/s selected"
                  >
                  </p-multiSelect>
                </div>
              </div>

              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('markets')?.errors && accessForm.get('markets').touched
                  }"
                >
                  <label class="mb-1" for="label">Markets (comma separated)</label>
                  <tooltip-error [element]="accessForm.get('markets')" [errors]="accessForm.get('markets').errors">
                  </tooltip-error>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="markets"
                    [ngClass]="{
                      'form-control-danger': accessForm.get('markets')?.errors && accessForm.get('markets').touched
                    }"
                    placeholder="Enter comma separated markets"
                    name="markets"
                    (keyup)="setMarketsForUpdate()"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger':
                      accessForm.get('descriptiveInfoLimit')?.errors && accessForm.get('descriptiveInfoLimit').touched
                  }"
                >
                  <label class="mb-1" for="label">Descriptive Info Limit</label>

                  <input
                    type="number"
                    class="form-control"
                    formControlName="descriptiveInfoLimit"
                    [ngClass]="{
                      'form-control-danger':
                        accessForm.get('descriptiveInfoLimit')?.errors && accessForm.get('descriptiveInfoLimit').touched
                    }"
                    placeholder="Enter a valid descriptiveInfoLimit"
                    name="descriptiveInfoLimit"
                  />
                </div>
              </div>

              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('updateList')?.errors && accessForm.get('updateList').touched
                  }"
                >
                  <label class="mb-1" for="label">Update List</label>

                  <input
                    type="number"
                    class="form-control"
                    formControlName="updateList"
                    [ngClass]="{
                      'form-control-danger':
                        accessForm.get('updateList')?.errors && accessForm.get('updateList').touched
                    }"
                    placeholder="Enter a valid updateList"
                    name="updateList"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger':
                      accessForm.get('updateDateRange')?.errors && accessForm.get('updateDateRange').touched
                  }"
                >
                  <label class="mb-1" for="label">Update Date Range</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="updateDateRange"
                    [ngClass]="{
                      'form-control-danger':
                        accessForm.get('updateDateRange')?.errors && accessForm.get('updateDateRange').touched
                    }"
                    placeholder="Enter a valid updateDateRange"
                    name="updateDateRange"
                  />
                </div>
              </div>

              <div class="col-6">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger':
                      accessForm.get('updateDescriptiveInfo')?.errors && accessForm.get('updateDescriptiveInfo').touched
                  }"
                >
                  <label class="mb-1" for="label">Update Descriptive Info</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="updateDescriptiveInfo"
                    [ngClass]="{
                      'form-control-danger':
                        accessForm.get('updateDescriptiveInfo')?.errors &&
                        accessForm.get('updateDescriptiveInfo').touched
                    }"
                    placeholder="Enter a valid updateDescriptiveInfo"
                    name="updateDescriptiveInfo"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6" *ngIf="showOwner">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('ownerCode')?.errors && accessForm.get('ownerCode').touched
                  }"
                >
                  <label class="mb-1" for="label">Owner Code</label>
                  <tooltip-error [element]="accessForm.get('ownerCode')" [errors]="accessForm.get('ownerCode').errors">
                  </tooltip-error>
                  <p-dropdown
                    [options]="organizations"
                    [(ngModel)]="selectedOrg"
                    [virtualScroll]="true"
                    itemSize="30"
                    [showClear]="true"
                    [filter]="true"
                    optionsValue="ownerCode.code"
                    filterby="ownerCode.label"
                    appendTo="body"
                    baseZIndex="999"
                    placeholder="Select an Organization"
                    (onChange)="loadOrganizationsGroupsEvent($event)"
                    formControlName="ownerCode"
                  >
                  </p-dropdown>
                </div>
              </div>
              <div class="col-6" *ngIf="isCreating">
                <div
                  class="form-group"
                  [ngClass]="{
                    'form-control-danger': accessForm.get('groupCode')?.errors && accessForm.get('groupCode').touched
                  }"
                >
                  <label class="mb-1" for="label">Group Code (buyer)</label>
                  <tooltip-error [element]="accessForm.get('groupCode')" [errors]="accessForm.get('groupCode').errors">
                  </tooltip-error>

                  <p-dropdown
                    #groups_dd
                    [options]="groups"
                    [virtualScroll]="true"
                    itemSize="30"
                    [showClear]="true"
                    [filter]="true"
                    optionsValue="groups.value"
                    filterby="groups.label"
                    appendTo="body"
                    baseZIndex="999"
                    placeholder="Empty groups"
                    formControlName="groupCode"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </p-tabPanel>

      <p-tabPanel header="Urls">
        <div class="row mt-2">
          <div class="col-12">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': accessForm.get('urls_search')?.errors && accessForm.get('urls_search').touched
              }"
            >
              <label class="mb-1" for="label">Search</label>
              <input
                type="text"
                class="form-control"
                formControlName="urls_search"
                [ngClass]="{
                  'form-control-danger': accessForm.get('urls_search')?.errors && accessForm.get('urls_search').touched
                }"
                placeholder="Enter a valid Search url"
                name="urls_search"
              />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': accessForm.get('urls_quote')?.errors && accessForm.get('urls_quote').touched
              }"
            >
              <label class="mb-1" for="label">Quote</label>
              <input
                type="text"
                class="form-control"
                formControlName="urls_quote"
                [ngClass]="{
                  'form-control-danger': accessForm.get('urls_quote')?.errors && accessForm.get('urls_quote').touched
                }"
                placeholder="Enter a valid Quote url"
                name="urls_quote"
              />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': accessForm.get('urls_book')?.errors && accessForm.get('urls_book').touched
              }"
            >
              <label class="mb-1" for="label">Book</label>
              <input
                type="text"
                class="form-control"
                formControlName="urls_book"
                [ngClass]="{
                  'form-control-danger': accessForm.get('urls_book')?.errors && accessForm.get('urls_book').touched
                }"
                placeholder="Enter a valid Book url"
                name="urls_book"
              />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger': accessForm.get('urls_generic')?.errors && accessForm.get('urls_generic').touched
              }"
            >
              <label class="mb-1" for="label">Generic</label>
              <input
                type="text"
                class="form-control"
                formControlName="urls_generic"
                [ngClass]="{
                  'form-control-danger':
                    accessForm.get('urls_generic')?.errors && accessForm.get('urls_generic').touched
                }"
                placeholder="Enter a valid Generic url"
                name="urls_generic"
              />
            </div>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Parameters">
        <div formArrayName="parameters" *ngFor="let t of parameters.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-md-5">
              <input
                formControlName="key"
                id="{{ 'key' + i }}"
                type="text"
                class="form-control mb-4"
                placeholder="Enter Key"
                (keyup)="setParametersForUpdate()"
              />
            </div>
            <div class="col-md-6">
              <input
                formControlName="value"
                id="{{ 'value' + i }}"
                type="text"
                class="form-control mb-4"
                placeholder="Enter value "
                (keyup)="setParametersForUpdate()"
              />
            </div>
            <div class="col-md-1">
              <button class="btn btn-md btn-primary" (click)="removeParameter(i)">X</button>
            </div>
          </div>
        </div>
        <button class="btn btn-md btn-primary" (click)="addParameter()">Add parameter</button>
      </p-tabPanel>
    </p-tabView>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Update' }}
  </button>
</div>
