import {
  BillingAccountService,
  PartnerService,
  StaticBillingService,
  ContractService,
  ConnectionService,
  ContractConnectionService,
  CostsService,
  CostsL2BService,
  NewPricingCostsService,
  ReportService,
  UtilsService,
  LoadCSVService,
  ContractTypeService,
  BillingGatewayService,
} from './services/_index-billing.services';
import { SharedModule } from './../../@core/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { BillingComponent } from './billing.component';
import { BillingRoutingModule } from './billing-routing.module';
import {
  PartnerModule,
  DashboardBillingModule,
  BillingAccountModule,
  CostModule,
  ContractsModule,
  ConnectionModule,
  ReportsBillingModule,
  AgreementsModule,
  PaymentRequestModule,
  InvoicingProcessRoutingModule,
} from './modules/_index-billing-modules';

const SERVICES = [
  StaticBillingService,
  PartnerService,
  BillingAccountService,
  ContractService,
  ConnectionService,
  ContractConnectionService,
  ContractTypeService,
  CostsService,
  CostsL2BService,
  NewPricingCostsService,
  ReportService,
  UtilsService,
  LoadCSVService,
  BillingGatewayService,
];

@NgModule({
  imports: [
    SharedModule,
    // Routing
    BillingRoutingModule,
    //Custom modules
    DashboardBillingModule,
    BillingAccountModule,
    ConnectionModule,
    ContractsModule,
    CostModule,
    PartnerModule,
    AgreementsModule,
    ReportsBillingModule,
    PaymentRequestModule,
    InvoicingProcessRoutingModule,
  ],
  declarations: [BillingComponent],
  providers: [],
})
export class BillingModule {
  static forRoot(): ModuleWithProviders<BillingModule> {
    return {
      ngModule: BillingModule,
      providers: [...SERVICES],
    };
  }
}
