import { Injectable } from '@angular/core';
import {
  Cost,
  CostWhereInput,
  CostCreateInput,
  CostUpdateInput,
  ObjectWhereUniqueInputById,
} from './../interfaces/_index.billing.interfaces';
import { GraphqlApiBillingMutations } from './mutations/_index-billing-mutations';
import { GraphqlApiBillingQueries } from './queries/_index-billing-queries';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CostsService {
  constructor(private apollo: Apollo) {}

  async getCosts(contractId: number): Promise<Cost[]> {
    const where: CostWhereInput = { contract: { equals: contractId } };

    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .watchQuery<any>({
          query: GraphqlApiBillingQueries.getCosts,
          variables: { where: where },
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((res: any) => {
          if (res?.data) {
            resolve(((res || {}).data || {}).costs);
          } else {
            reject(res);
          }
        });
    });
  }

  async createCost(create: CostCreateInput): Promise<Cost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.createCost,
          variables: { data: create },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).createCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  async updateCost(update: CostUpdateInput, where: ObjectWhereUniqueInputById): Promise<Cost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.updateCost,
          variables: { data: update, where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).updateCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  async deleteCost(where: ObjectWhereUniqueInputById): Promise<Cost> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('billing_api')
        .mutate({
          mutation: GraphqlApiBillingMutations.deleteCost,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(((res || {}).data || {}).deleteCost);
            } else {
              reject(res);
            }
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
