<nb-card>
  <nb-card-header> Commercial Partners </nb-card-header>

  <nb-card-body>
    <div *ngIf="isEditor" class="mb-3">
      <p-button label="Create" icon="pi pi-plus" (onClick)="onCreate()"></p-button>
    </div>
    <p-table
      #dt
      [value]="agreementsLoadSource"
      [paginator]="false"
      [rows]="agreementsLoadSource?.length"
      [showCurrentPageReport]="false"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon
              *ngIf="head.field !== 'Options' && head.field !== 'CreatedAt'"
              [field]="head.field"
            ></p-sortIcon>
          </th>
        </tr>
        <tr *ngIf="agreementsLoadSource?.length > 1">
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              *ngIf="head.field !== 'Options' && head.field !== 'CreatedAt'"
              [type]="getFilterType(head.field)"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.field)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-agreement>
        <tr>
          <td>{{ agreement.ID }}</td>
          <td>{{ agreement.Type }}</td>
          <td>{{ agreement.Name }}</td>
          <td>{{ agreement.Version }}</td>
          <td>
            <p-checkbox [ngModel]="agreement.Approved" [binary]="true" inputId="binary" disabled="true"></p-checkbox>
          </td>
          <td>{{ agreement.Contract }}</td>
          <td>{{ agreement.CreatedAt | date : 'dd-MM-yyyy' }}</td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi {{ isEditor ? 'pi-pencil' : 'pi-search' }}"
                (click)="onEdit(agreement)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-download"
                (click)="onDownload(agreement)"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No agreement found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
