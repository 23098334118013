import { Component, Input, OnInit } from '@angular/core';
import {Pipe} from '@angular/core';

@Component({
  selector: 'xen-meta-info',
  templateUrl: './meta-info.component.html',
  styleUrls: ['./meta-info.component.scss'],
})
export class MetaInfoComponent implements OnInit {
  @Input() title: string;
  @Input() name: string;
  @Input() caption: string;
  @Input() type: 'buyer' | 'seller';
  @Input() state: 'live' | 'test';

  @Input() accessName: string;
  @Input() accessInfo: boolean = false;
  @Input() accessStatus: 'notWorking' | 'working' | 'pending';
  @Input() accommodations: {
    value: number,
    href: string
  };

  @Input() relatedTickets = [];
  @Input() footer : boolean = false;
  @Input() footerInfo = [];
  
  public get stateClass(): Array<string> {
    return [
      `state--${this.state}`
    ];
  }




  constructor() {}

  ngOnInit(): void {}

}
