import { gql } from 'apollo-angular';

export const appendAccessMarkets = gql`mutation appendAccessMarkets(
  $where: AccessWhereUniqueInput!
  $data: [String!]!
) {
  admin {
    appendAccessMarkets(where: $where, data: $data) {
      code
      adviseMessage {
        code
        description
        level
      }
    }
  }
}

`;
