import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xen-title-section',
  templateUrl: './title-section.component.html',
  styleUrls: ['./title-section.component.scss'],
})
export class TitleSectionComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() htmlContent: any;

  constructor() { }

  ngOnInit(): void { }

}
