import { gql } from 'apollo-angular';

export const removeAccessMaster = gql`mutation removeAccessMaster($where: AccessWhereUniqueInput!) {
  admin {
    removeAccessMaster(where: $where) {
      code
      adviseMessage {
        code
        description
        level
      }
    }
  }
}
`;
