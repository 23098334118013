import { NgModule } from '@angular/core';
import { LoginGuard } from '@tgx/shared/guards';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { EntitiesGuard, NetworkGuard, BillingGuard, BannerGuard } from './@core/guards/_index.guards';

export const routes: Routes = [
  {
    path: 'mapping',
    loadChildren: () => import('./features/mapping/mapping.module').then((m) => m.MappingModule),
    canLoad: [LoginGuard],
  },
  {
    path: 'content',
    loadChildren: () => import('./features/content/content.module').then((m) => m.ContentModule),
    canLoad: [LoginGuard],
  },
  {
    path: 'network',
    loadChildren: () => import('./features/network/network.module').then((m) => m.NetworkModule),
    canLoad: [NetworkGuard, LoginGuard],
  },
  {
    path: 'entities',
    loadChildren: () => import('./features/entities/entities.module').then((m) => m.EntitiesModule),
    canLoad: [EntitiesGuard, LoginGuard],
  },
  {
    path: 'apps',
    loadChildren: () => import('./features/apps/apps.module').then((m) => m.AppsModule),
  },
  {
    path: 'billing',
    loadChildren: () => import('./features/billing/billing.module').then((m) => m.BillingModule),
    canLoad: [BillingGuard, LoginGuard],
  },
  {
    path: 'data',
    loadChildren: () => import('./features/data/data.module').then((m) => m.DataModule),
    canLoad: [LoginGuard],
  },
  {
    path: 'web',
    loadChildren: () => import('./features/web/web.module').then((m) => m.WebModule),
    canLoad: [BannerGuard, LoginGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [LoginGuard],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'dashboard' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
