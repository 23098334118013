import { gql } from 'apollo-angular';

export const deleteInvoiceBreakdown = gql`
mutation DeleteInvoiceBreakdown($where: InvoiceBreakdownWhereUniqueInput!) {
    deleteInvoiceBreakdown(where: $where) {
      id
    }
  }
  
`;
