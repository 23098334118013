import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { SharedUiStylesModule } from '@tgx/shared/ui/styles';
import { HelpToolsRoutingModule } from './help-tools.routing';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { SignupStatusService } from './signup-status/signup-status.service';
import { SignupStatusComponent } from './signup-status/signup-status.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbCardModule, NbCheckboxModule, NbIconModule } from '@nebular/theme';
import { SharedModule } from 'apps/web-admin/src/app/@core/shared/shared.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';

const SERVICES = [SignupStatusService];

@NgModule({
  imports: [
    CommonModule,
    HelpToolsRoutingModule,
    JsonPipe,
    SharedUiStorybookModule,
    SharedUiStylesModule,
    ReactiveFormsModule,
    NbIconModule,
    SharedModule,
    FormsModule,
    NbCardModule,
    NbCheckboxModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    NgbModule,
    DropdownModule,
    SkeletonModule,
  ],
  declarations: [SignupStatusComponent],
})
export class HelpToolsModule {
  static forRoot(): ModuleWithProviders<HelpToolsModule> {
    return {
      ngModule: HelpToolsModule,
      providers: [...SERVICES],
    };
  }
}
