import { gql } from 'apollo-angular';

export const createNewPricingCost = gql`
mutation CreateNewPricingCost($data: NewPricingCostCreateInput!) {
    createNewPricingCost(data:$data) {
      id
    }
  }
  
`;
