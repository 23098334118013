import { gql } from 'apollo-angular';

export const deleteSubCategory = gql`
mutation($where: SubCategoryWhereUniqueInput!) {
	social {
		deleteSubCategory(
			where: $where
		) {
			adviseMessage {
				code
				description
				level
				external {
					code
					message
				}
			}
		}
	}
}
`;
