import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Agreement, Contract, Partner } from '../../../../interfaces/_index.billing.interfaces';
import {
  PartnerService,
  ContractService,
  CommercialAgreementService,
} from '../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../entities/interfaces/_index-entities.interfaces';
import { BillingAgreementsCommercialPartnerModalComponent } from '../commercial-bypartner-modal/commercial-bypartner-modal.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'tgx-admin-billing-agreement-partner-table',
  templateUrl: './commercial-bypartner-table.component.html',
  styleUrls: ['./commercial-bypartner-table.component.scss'],
})
export class BillingAgreementsCommercialByPartnerTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'ID' },
    { label: 'Type', field: 'Type' },
    { label: 'Name', field: 'Name' },
    { label: 'Version', field: 'Version' },
    { label: 'Approved', field: 'Approved' },
    { label: 'Contract', field: 'Contract' },
    { label: 'Created At', field: 'CreatedAt' },
    { label: 'Options', field: 'Options' },
  ];

  mapContracts: Map<number, Contract>;

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  isEditor = false;

  agreementsLoadSource: any[];

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private commercialAgreementService: CommercialAgreementService,
  ) {}

  ngOnInit(): void {
    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        this.isLoading = true;
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService
            .getPartner(orgSelected.code)
            .then(async (partner) => {
              if (partner) {
                this.partner = partner;
                await this.getData();
              } else {
                this.partner = null;
                this.notificationService.warning(
                  'This organization has not partner in billing section, first create it and then you will be able to manage its agreements.',
                );
              }
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }),
    );
  }

  async getData() {
    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }
    await this.getContracts(this.partner.orgCode);
    await this.getTemplates(this.partner.orgCode);
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async onDownload($event) {
    await this.commercialAgreementService
      .downloadPdfTemplate('agreement', $event.ID)
      .then((rs) => {
        if (rs.response?.base64) {
          const byteArray = Uint8Array.from(
            atob(rs.response?.base64)
              .split('')
              .map((char) => char.charCodeAt(0)),
          );

          const blob = new Blob([byteArray], { type: 'application/pdf' });
          saveAs(blob, rs.response.fileName + '.pdf');
        }
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async getContracts(orgCode: string) {
    this.agreementsLoadSource = [];

    if (orgCode) {
      await this.contractService
        .getContracts(orgCode)
        .then((cnts) => {
          this.mapContracts = new Map<number, Contract>();
          cnts.forEach((cn) => {
            if (!cn.endDate) {
              this.mapContracts.set(cn.id, cn);
            }
          });
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  async getTemplates(orgCode: string) {
    this.agreementsLoadSource = [];

    await this.commercialAgreementService
      .getAgrementsByPartner(orgCode)
      .then((rs) => {
        if (rs.success === true) {
          rs.response.forEach((agr) => {
            let Contract = '';

            if (this.mapContracts.has(agr.ContractId)) {
              const c = this.mapContracts.get(agr.ContractId);
              Contract =
                '(' +
                c.id +
                ') ' +
                c.partnerType +
                (c.modelType ? ' - ' + c.modelType : '') +
                (c.ContractType ? ' - ' + c.ContractType.name : '');
            }

            const { ID, Type, Name, Version, Approved, CreatedAt, UpdatedAt } = agr;

            this.agreementsLoadSource.push({
              ID,
              Type,
              Name,
              Version,
              Approved,
              Contract,
              CreatedAt,
              UpdatedAt,
            });

            this.agreementsLoadSource = [...this.agreementsLoadSource];
          });
        }
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async onCreate(): Promise<void> {
    if (!this.partner) {
      this.notificationService.warning(
        'This organization has not partner in billing section, first create it and then you will be able to manage its agreements.',
      );
      return;
    }

    const activeModal = this.modalService.open(BillingAgreementsCommercialPartnerModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Add agreement';
    activeModal.componentInstance.partnerOrgCode = this.partner.orgCode;
    activeModal.componentInstance.contracts = Array.from(this.mapContracts.values());

    const self = this;
    activeModal.result.then(async (res) => {
      // OK
      if (typeof res === 'string' && res.includes('successfully')) {
        await self.getData();
      }
    });
  }

  onEdit(event): void {
    const agreement: Agreement = event;

    if (agreement.Approved && agreement.Approved === true) {
      this.notificationService.warning('Any change is allowed, the agreement is already approved.');
      return;
    }

    const activeModal = this.modalService.open(BillingAgreementsCommercialPartnerModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Edit agreement';
    activeModal.componentInstance.partnerOrgCode = this.partner.orgCode;
    activeModal.componentInstance.agreement = agreement;

    const self = this;
    activeModal.result.then(async (res) => {
      // OK
      if (typeof res === 'string' && res.includes('successfully')) {
        await self.getData();
      }
    });
  }

  getFilterType(field: string) {
    switch (field) {
      case 'Approved':
        return 'boolean';
      default:
        return 'text';
    }
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
