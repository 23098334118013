import { gql } from 'apollo-angular';

export const setOrganizationPartner = gql`
  mutation SetOrganizationPartner($orgCode: ID!, $partnerCode: ID!) {
    admin {
      setPartnerToOrganization(orgCode: $orgCode, partnerCode: $partnerCode) {
        code
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
