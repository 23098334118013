import gql from 'graphql-tag';

export const getSuppliersWithClients = gql`
query SupplierWithClients($suppliers: [ID!], $clients: [ID!]) {
    admin {
      allSuppliers(where: { code_in: $suppliers }) {
        edges {
          node {
            supplierData {
                code
                name
              clients(filter: { clientID: $clients }) {
                edges {
                  node {
                    code
                    clientData {
                      code
                      name
                      owner {
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
`;