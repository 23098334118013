import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { PreAndPostCheckService } from '../../../../../services/_index-billing.services';
import { NotificationService } from './../../../../../../../@core/services/_index-core.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tgx-admin-billing-invoicing-process-pre-sixth',
  templateUrl: './invoicing-process-pre-sixth.component.html',
  styleUrls: ['./invoicing-process-pre-sixth.component.scss'],
})
export class BillingInvoicingProcessPreSixthComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  @Output() isValidStep: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  header = [
    { label: 'ID', field: 'ID' },
    { label: 'PartnerID', field: 'PartnerID' },
    { label: 'PartnerType', field: 'PartnerType' },
    { label: 'ModelType', field: 'ModelType' },
    { label: 'SubType', field: 'SubType' },
  ];

  source: any[];

  isLoading: boolean;

  $subs: Subscription[] = [];

  step = 6;
  constructor(
    private notificationService: NotificationService,
    private preAndPostCheckService: PreAndPostCheckService
  ) {
    this.$subs.push(
      this.preAndPostCheckService.refreshPreStepPage.subscribe(async (refreshStep) => {
        if (refreshStep && refreshStep === this.step) {
          await this.getContracts();
        }
      })
    );
  }

  async ngOnInit(): Promise<void> {
    this.$subs.push(
      this.preAndPostCheckService.currentPreStepPage.subscribe(async (currentStep) => {
        if (currentStep && currentStep === this.step) {
          await this.getContracts();
        }
      })
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  async getContracts() {
    this.isLoading = true;
    this.source = [];
    const requests: any[] = [];

    await this.preAndPostCheckService
      .getCM23WithoutSTDCosts()
      .then((rs) => {
        if (rs.success) {
          rs.response?.forEach((cn) => {
            requests.push({
              'ID': cn.ID,
              'PartnerID': cn.PartnerID,
              'PartnerType': cn.PartnerType,
              'ModelType': cn.ModelType,
              'SubType': cn.SubType,
            });
          });
        }
        this.source = [...requests];
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
        this.isValidStep.emit(this.source?.length === 0);
      });
  }
}
