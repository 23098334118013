import { gql } from 'apollo-angular';

export const createCategory = gql`
mutation($data:CategoryCreateInput!) {
	social {
		createCategory(data:$data) {
			code
			categoryData {
				description
				subCategories {
					edges {
						node {
							createdAt
							updatedAt
							code
							subCategoryData {
								description
								defaultIsBuyer
								defaultIsSeller
								defaultDemandBridge
								defaultSupplyBridge
							}
						}
					}
				}
			}
			adviseMessage {
				code
				description
				level
			}
		}
	}
}
`;

