import { Injectable } from '@angular/core';
import {
  adminMutations,
  GqlContextName,
  GqlService,
  socialMutations,
  socialQueries,
} from '@tgx/shared/data-access/graphql';
import { OnboardingDates, Profile, ProfileCache, SelectorOrganization } from '@tgx/shared/interfaces';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  profileSelected = new BehaviorSubject<Profile>(null as any);
  profiles = new BehaviorSubject<Profile[]>(null as any);

  allProfiles: Profile[];

  profileFields: { name: string; label: string }[] = [
    { name: 'name', label: 'Company name' },
    { name: 'websiteUrl', label: 'Website URL' },
    { name: 'description', label: 'Description' },
    { name: 'logoUrl', label: 'Company logo' },
  ];

  organizationFields: { name: string; label: string }[] = [
    { name: 'country', label: 'Headquarters location' },
    { name: 'types', label: 'Company type' },
  ];

  constructor(private apollo: Apollo, private gqlService: GqlService, private cacheService: CacheService) {}

  setProfile(profile: Profile): void {
    if (!profile && this.profiles.value && this.profiles.value.length > 0) {
      profile = this.profiles.value.sort((a, b) =>
        parseInt(a.code.replace('prf', '')) < parseInt(b.code.replace('prf', '')) ? 1 : -1
      )[0];
    }

    localStorage.setItem('profile', JSON.stringify(profile));
    this.profileSelected.next(profile);
  }

  getAllProfiles(where: any): Promise<Profile[]> {
    return new Promise((resolve, reject) => {
      this.cacheService.retrieveProfilesByCodes([where.ownerCode.equals]).then(
        (res: ProfileCache[]) => {
          const profiles = this.buildProfilesByCache(res);

          return resolve(
            profiles.sort((a, b) => {
              if (a.profileData.name < b.profileData.name) {
                return -1;
              } else if (a.profileData.name > b.profileData.name) {
                return 1;
              } else {
                return 0;
              }
            })
          );
        },
        (err) => {
          console.log(err);
          // this.notificationService.handleGatewayAndGraphqlErrors(err);
          return reject(err);
        }
      );
    });
  }

  updateOrg(organization: { organization: string; label?: string; country?: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apollo
        .use('gateway')
        .mutate<any>({
          mutation: adminMutations.updateOrganization,
          variables: {
            organization: organization,
          },
        })
        .subscribe(
          (res: any) => {
            const organizationData = res?.data?.admin?.updateOrganization;

            return organizationData.code ? resolve(organizationData) : reject(false);
          },
          (err) => {
            console.log(err);
            // this.notificationService.handleGatewayAndGraphqlErrors(err);
            return reject(err);
          }
        );
    });
  }

  editOrganizationTypeFolder(
    organization: { code: string; isBuyer: boolean; isSeller: boolean },
    type?: 'BUYER' | 'SELLER'
  ): any {
    if (!organization || !organization.code) return;
    if (!organization.isBuyer && !organization.isSeller) {
      console.log('Third type?');
      return;
    }
    if (organization.isBuyer && organization.isSeller) {
      console.log('Already both');
      return;
    }
    if (type && type !== 'BUYER' && type !== 'SELLER') {
      console.log('Invalid type');
      return;
    }

    const folder = {
      orgCode: organization.code,
      template: '',
    };

    if (type) {
      folder.template = type === 'BUYER' ? 'FOLDER_BUYER' : 'FOLDER_SELLER';
    } else {
      if (organization.isBuyer && !organization.isSeller) folder.template = 'FOLDER_SELLER';
      else folder.template = 'FOLDER_BUYER';
    }

    return new Promise((resolve, reject) => {
      this.apollo
        .use('gateway')
        .mutate<any>({
          mutation: adminMutations.createFolder,
          variables: { folder: folder },
        })
        .subscribe(
          (res: any) => {
            const code = ((((res || {}).data || {}).admin || {}).createFolder || {}).code;

            return code ? resolve(code) : reject();
          },
          (err) => {
            console.log(err);
            // this.notificationService.handleGatewayAndGraphqlErrors(err);
            return reject(err);
          }
        );
    });
  }
  getProfileCompletionPercentage(
    profile: Profile,
    organization: SelectorOrganization,
    defaultPercentage: number = 3
  ): number {
    if (!profile || !organization) {
      return defaultPercentage;
    }

    let completionPercentage = defaultPercentage;

    let completedFields = 0;
    this.profileFields.forEach((field) => {
      if (field.name === 'logoUrl') {
        if (profile.profileData.assets?.find((asset) => asset.type === 'LOGO')?.url) {
          completedFields++;
        }

        return;
      }

      if (field.name === 'banner') {
        if (profile.profileData.assets?.find((asset) => asset.type === 'BANNER')?.url) {
          completedFields++;
        }

        return;
      }

      if (
        !!profile.profileData[field.name] ||
        (profile.profileData[field.name] instanceof Array && profile.profileData[field.name].length)
      ) {
        completedFields++;
      }
    });

    this.organizationFields.forEach((field) => {
      if (field.name === 'types') {
        if (organization.isBuyer || organization.isSeller) {
          completedFields++;
        }

        return;
      }

      if (
        !!organization[field.name] ||
        (organization[field.name] instanceof Array && organization[field.name].length)
      ) {
        completedFields++;
      }
    });

    completionPercentage = Math.round(
      (completedFields / (this.profileFields.length + this.organizationFields.length)) * 100
    );

    return completionPercentage;
  }

  buildProfilesByCache(profiles: ProfileCache[]): any[] {
    return profiles?.map((profile) => {
      return {
        code: profile.profileCode,
        updatedAt: profile.updatedAt,
        createdAt: profile.createdAt,
        profileData: {
          owner: {
            code: profile.code,
            organizationData: {
              deleted_at: profile.deletedAt,
              isBuyer: profile.isBuyer,
              isSeller: profile.isSeller,
            },
          },
          group: {
            code: profile?.groupCode,
          },
          name: profile.name,
          country: profile.country,
          status: profile.status,
          slug: profile.slug,
          classifications: profile?.classifications,
          logoUrl: profile.logoUrl,
          websiteUrl: profile.websiteUrl,
          description: profile.description,
          buyerCategory: profile.buyerCategory,
          sellerCategory: profile.sellerCategory,
          buyerSubCategory: profile.buyerSubCategory,
          sellerSubCategory: profile.sellerSubCategory,
          isDemandBridge: profile?.isDemandBridge,
          isSupplyBridge: profile?.isSupplyBridge,
          buyerInsightsCountries: profile.buyerInsightsCountries,
          sellerInsightsCountries: profile.sellerInsightsCountries,
          buyerIntegrationType: profile.buyerIntegrationType,
          sellerIntegrationType: profile.sellerIntegrationType,
          buyerOnboardingStart: profile?.buyerOnboardingStart,
          buyerOnboardingEnd: profile?.buyerOnboardingEnd,
          sellerOnboardingStart: profile?.sellerOnboardingStart,
          sellerOnboardingEnd: profile?.sellerOnboardingEnd,
          buyerPartnerStatus: profile?.buyerPartnerStatus,
          sellerPartnerStatus: profile?.sellerPartnerStatus,
          buyerTechBridge: profile?.buyerTechBridge,
          sellerTechBridge: profile?.sellerTechBridge,
        },
      };
    });
  }

  getProfileFieldsToBeCompleted(
    profile: Profile,
    organization: SelectorOrganization
  ): { name: string; label: string }[] {
    const missingFields: { name: string; label: string }[] = [];

    if (!profile || !organization) {
      return [];
    }

    this.profileFields.forEach((field) => {
      if (field.name === 'logoUrl') {
        if (!profile.profileData.assets?.find((asset) => asset.type === 'LOGO')?.url) {
          missingFields.push(field);
        }

        return;
      }

      if (field.name === 'banner') {
        if (!profile.profileData.assets?.find((asset) => asset.type === 'BANNER')?.url) {
          missingFields.push(field);
        }

        return;
      }

      if (
        !profile.profileData[field.name] ||
        (profile.profileData[field.name] instanceof Array && !profile.profileData[field.name].length)
      ) {
        missingFields.push(field);
      }
    });

    this.organizationFields.forEach((field) => {
      if (field.name === 'types') {
        if (!organization.isBuyer && !organization.isSeller) {
          missingFields.push(field);
        }

        return;
      }

      if (
        !organization[field.name] ||
        (organization[field.name] instanceof Array && !organization[field.name].length)
      ) {
        missingFields.push(field);
      }
    });

    return missingFields;
  }

  retrieveCompanyTypes(): Promise<any> {
    return new Promise((resolve) => {
      this.apollo
        .use('gateway')
        .query<any>({
          query: gql`
            query IntrospectionQuery {
              __type(name: "ProfileClassification") {
                enumValues {
                  name
                }
              }
            }
          `,
        })
        .subscribe(
          (res: any) => {
            const enumValues = res?.data?.__type?.enumValues;
            if (enumValues?.length > 0) {
              return resolve(enumValues.map((v) => v.name));
            }
            return resolve([]);
          },
          (err) => {
            console.error(err);
          }
        );
    });
  }

  async getCategories(): Promise<{ name: string; subCategories: string[] }[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY_PUBLIC, socialQueries.getCategories)
        .then((res: any) => {
          if (res?.social?.allCategories?.edges) {
            const edges = res.social.allCategories.edges || [];
            resolve(
              edges.map((edge) => {
                const category = edge?.node?.categoryData;
                return {
                  name: category?.code,
                  subCategories: (category?.subCategories?.edges || []).map((sc) => sc.node.code),
                };
              })
            );
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  retrieveOnboardingDates(orgCode: string): Promise<OnboardingDates> {
    if (!orgCode) return null;
    const body = {
      where: { ownerCode: orgCode },
    };

    return new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY, socialQueries.getOnboardingDates, body)
        .then((apiResponse: any) => {
          resolve({
            buyerOnboardingStart: apiResponse?.social?.profile?.profileData?.buyerOnboardingStart,
            buyerOnboardingEnd: apiResponse?.social?.profile?.profileData?.buyerOnboardingEnd,
            sellerOnboardingStart: apiResponse?.social?.profile?.profileData?.sellerOnboardingStart,
            sellerOnboardingEnd: apiResponse?.social?.profile?.profileData?.sellerOnboardingEnd,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateOnboardingDate(dateToUpdate: string, orgCode: string, date: string): Promise<OnboardingDates> {
    const body = {
      where: { ownerCode: orgCode },
      data: JSON.parse(`{ "${dateToUpdate}": "${date}" }`),
    };

    return new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, socialMutations.updateBuyerOnboardingDate, body)
        .then((apiResponse: any) => {
          resolve({
            buyerOnboardingStart: apiResponse?.social?.updateProfile?.profileData?.buyerOnboardingStart,
            buyerOnboardingEnd: apiResponse?.social?.updateProfile?.profileData?.buyerOnboardingEnd,
            sellerOnboardingStart: apiResponse?.social?.updateProfile?.profileData?.sellerOnboardingStart,
            sellerOnboardingEnd: apiResponse?.social?.updateProfile?.profileData?.sellerOnboardingEnd,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateSellerOnboardingStartedDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('sellerOnboardingStart', orgCode, date);
  }

  updateSellerOnboardingEndDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('sellerOnboardingEnd', orgCode, date);
  }

  updateBuyerOnboardingStartedDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('buyerOnboardingStart', orgCode, date);
  }

  updateBuyerOnboardingEndDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('buyerOnboardingEnd', orgCode, date);
  }

  async getAllProfilesForSearch(): Promise<any[]> {
    return await new Promise((resolve, reject) => {
      this.cacheService
        .retrieveProfiles()
        .then((profiles: ProfileCache[]) => {
          if (profiles?.length) {
            const searchList = profiles.filter(
              (prf) => prf.status === 'PUBLISHED' || this.isBuyerOnboarding(prf) || this.isSellerOnboarding(prf)
            );

            resolve(searchList);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  isBuyerOnboarding(profiles: any) {
    return profiles.buyerOnboardingStart && !profiles.buyerOnboardingEnd;
  }

  isSellerOnboarding(profiles: any) {
    return profiles.sellerOnboardingStart && !profiles.sellerOnboardingEnd;
  }
}
