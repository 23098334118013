import { gql } from 'apollo-angular';

export const updateL2BCost = gql`
mutation UpdateL2bCost(
    $data: BookingL2BCostUpdateInput!
    $where: BookingL2BCostWhereUniqueInput!
  ) {
    updateBookingL2BCost(data: $data, where: $where) {
      id
    }
  }
  
  
`;
