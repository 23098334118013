import { gql } from 'apollo-angular';

export const getLegacyConnectionByClient = gql`
query($filter:LegacyAccessFilter) {
  admin {
    legacyAccesses(filter: $filter) {
      edges {
        node {
          legacyAccessData {
            hubUser
            hubProvider
            isActive
          }
        }
      }
    }
  }
}
`;