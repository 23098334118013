import { gql } from 'apollo-angular';

export const setAccessMaster = gql`mutation setAccessMaster($where: AccessWhereUniqueInput!, $masterCode: ID!) {
  admin {
    setAccessMaster(where: $where, masterCode: $masterCode) {
      code
      adviseMessage {
        code
        description
        level
      }
    }
  }
}
`;
