<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="clientLegacyConnectionForm">
    <nb-card>
      <nb-card-header> Legacy connection </nb-card-header>
      <nb-card-body>
        <div class="row"></div>
        <div class="row mt-2">
          <div class="col-6">
            <div
              class="form-group"
              [ngClass]="{
                'form-control-danger':
                  clientLegacyConnectionForm.get('hubProvider')?.errors &&
                  clientLegacyConnectionForm.get('hubProvider').touched
              }"
            >
              <label class="mb-1" for="label">Hub Provider</label>
              <tooltip-error
                [element]="clientLegacyConnectionForm.get('hubProvider')"
                [errors]="clientLegacyConnectionForm.get('hubProvider').errors"
              >
              </tooltip-error>
              <input
                type="text"
                class="form-control"
                formControlName="hubProvider"
                [ngClass]="{
                  'form-control-danger':
                    clientLegacyConnectionForm.get('hubProvider')?.errors &&
                    clientLegacyConnectionForm.get('hubProvider').touched
                }"
                placeholder="Enter a valid Hub Provider"
                name="hubProvider"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="form-group">
            <label class="mb-1" for="isActive">Is Active</label>
            <tooltip-error
              [element]="clientLegacyConnectionForm.get('isActive')"
              [errors]="clientLegacyConnectionForm.get('isActive').errors"
            >
            </tooltip-error>
            <nb-checkbox
              class="form-control"
              [ngClass]="{
                'form-control-danger':
                  clientLegacyConnectionForm.get('isActive').errors &&
                  clientLegacyConnectionForm.get('isActive').touched
              }"
              name="isActive"
              formControlName="isActive"
            ></nb-checkbox>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
  <button class="btn btn-md btn-primary" [disabled]="clientLegacyConnectionForm.invalid" (click)="onSave()">
    {{ isCreating ? 'Create' : 'Grant' }}
  </button>
</div>
