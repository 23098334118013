import { gql } from 'apollo-angular';

export const revokeSupplierFromGroups = gql`
mutation($where: SupplierWhereUniqueInput!, $groupCodes: [ID!]) {
  admin {
    revokeSupplierFromGroups(where: $where, groupCodes: $groupCodes) {
      supplierData {
        code
      }
      adviseMessage {
        code
        description
        level
      }
    }
  }
}
`;
