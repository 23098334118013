import { gql } from 'apollo-angular';

export const createSupplier = gql`
mutation ($data: SupplierCreateInput!) {
    admin {
      createSupplier(data: $data) {
        code
        adviseMessage{
          code
          description
          level
        }
      }
    }
  }
`;