import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebAdminService } from '../../../../../../../../..//web-admin/src/app/@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from '../../../../interfaces/_index-entities.interfaces';
import { LegacyConnectionEntitiesService } from '../../../../services/clients-legacy-connections-entities.service';

@Component({
  selector: 'tgx-admin-legacy-connections-modal.component-modal',
  templateUrl: './legacy-connections-modal.component.html',
  styleUrls: ['./legacy-connections-modal.component.scss'],
})
export class LegacyConnectionsModalComponent implements OnInit {
  @Input() header: string;
  @Input() legacyConnection: any;
  @Input() isCreating: boolean;

  isLoading: boolean;

  $subs: Subscription[] = [];

  org: OrganizationSelector;

  public legacyConnectionForm: UntypedFormGroup;

  constructor(
    private webAdminService: WebAdminService,
    private activeModal: NgbActiveModal,
    private legacyConnectionsEntitiesService: LegacyConnectionEntitiesService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.legacyConnectionForm = this.fb.group({
      hubUser: [this.legacyConnection ? this.legacyConnection.hubUser : '', Validators.required],
      hubProvider: [this.legacyConnection ? this.legacyConnection.hubProvider : '', Validators.required],
      isActive: [this.legacyConnection ? this.legacyConnection.isActive : false, Validators.required],
    });

    if (!this.isCreating) {
      this.legacyConnectionForm.get('hubUser').disable();
      this.legacyConnectionForm.get('hubProvider').disable();
      this.legacyConnectionForm.get('isActive').disable();
    }
  }

  closeModal() {
    this.activeModal.close(true);
  }

  async onSave() {
    if (this.isCreating) {
      this.activeModal.close(
        await this.legacyConnectionsEntitiesService.createLegacyConnection({
          hubUser: this.legacyConnectionForm.get('hubUser').value,
          hubProvider: this.legacyConnectionForm.get('hubProvider').value,
          isActive: this.legacyConnectionForm.get('isActive').value,
        })
      );
    } else {
      console.log('Not yet implemented - NOT isCreating');
      this.activeModal.close();
    }
  }
}
