import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Client } from '../interfaces/models/client.interface';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import {
  RetrieveAllClientsInput,
  ClientWhereUniqueInput,
  ClientCreateInput,
} from './../interfaces/_index-entities.interfaces';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { gql } from 'apollo-angular';
import { NotificationService } from '../../../@core/services/notification.service';
import { AdviseMessage } from 'libs/web/feature-connections/src/lib/shared/operations';

@Injectable({
  providedIn: 'root',
})
export class ClientsEntitiesService {
  onToggleTable$: BehaviorSubject<any> = new BehaviorSubject(null);
  onToggleArchive$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private gqlService: GqlService,
    private notificationService: NotificationService,
  ) {}

  async getClientsByOwner(input: RetrieveAllClientsInput): Promise<Client[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllClientsByOwner, { where: input })
        .then((response) => {
          if (
            response['admin']['allClients']['edges'].length > 0 &&
            response['admin']['allClients']['edges'][0]['node']['clientData']
          ) {
            resolve(response['admin']['allClients']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getAllGroupsByOrg(code: string): Promise<any> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getGroupsByOrg, { code: code })
        .then((response) => {
          const groups: any[] = [];
          if (
            response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'].length > 0
          ) {
            const edges =
              response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'];
            edges.forEach((edge) => {
              const group = edge.node.groupData;
              const option = { code: group.code, label: group.label };
              groups.push(option);
            });
          }
          resolve(groups);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getSellerGroupsByOrgs(code: string): Promise<any> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getGroupsByOrg, { code: code })
        .then((response) => {
          const groups: any[] = [];
          if (response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['isSeller'] === false) {
            resolve(groups);
          }

          if (
            response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'].length > 0
          ) {
            const edges =
              response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'];
            edges.forEach((edge) => {
              const group = edge.node.groupData;
              const option = { code: group.code, label: group.label };
              groups.push(option);
            });
          }
          resolve(groups);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getHotelXGroupsByOrgs(code: string): Promise<any> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getGroupsByOrg, { code: code })
        .then((response) => {
          const groups: any[] = [];
          if (response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['isBuyer'] === false) {
            resolve(groups);
          }

          if (
            response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'].length > 0
          ) {
            const edges =
              response['admin']['allOrganizations']['edges'][0]['node']['organizationsData']['groups']['edges'];
            edges.forEach((edge) => {
              const group = edge.node.groupData;
              const option = { code: group.code, label: group.label };
              groups.push(option);
            });
          }
          resolve(groups);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getAllClients(): Promise<Client[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getAllClients)
        .then((response) => {
          if (
            response['admin']['allClients']['edges'].length > 0 &&
            response['admin']['allClients']['edges'][0]['node']['clientData']
          ) {
            resolve(response['admin']['allClients']['edges']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async activateClient(input: ClientWhereUniqueInput): Promise<Client[]> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.activateClient, { where: input })
        .then((response) => {
          if (response['admin']['clientData']) {
            resolve(response['admin']['clientData']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async deactivateClient(input: ClientWhereUniqueInput): Promise<Client> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deactivateClient, { where: input })
        .then((response) => {
          if (response['admin']['deactivateClient']['clientData']) {
            resolve(response['admin']['deactivateClient']['clientData']);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async safeDeactivateClient(input): Promise<Client> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.safeDeactivateClient, {
          where: { search: input.name, searchBy: 'NAME' },
        })
        .then((response) => {
          if (response['admin']['safeDeactivateClient']) {
            resolve(response['admin']['safeDeactivateClient']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async grantClientToGroup(input: ClientWhereUniqueInput, group: string): Promise<Client> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.grantClientToGroup, { where: input, group: group })
        .then((res) => {
          if (res['admin']['grantClientToGroup']['clientData'].length > 0) {
            resolve(res['admin']['grantClientToGroup']['clientData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createClient(data: ClientCreateInput): Promise<Client> {
    const mutation = gql`mutation {
            admin {
                createClient(data: {name: "${data.name}", group: "${data.group}", isActive: ${data.isActive}}) {
                    clientData {
                        code
                        name
                        isActive
                        group {
                            code
                        }
                    }
                    adviseMessage {
                      code
                      description
                      level
                    }
                }
            }
        }
        `;
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(mutation)
        .then((response) => {
          const adviseMessage = response['admin']['createClient']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            const adviseMessage = response['admin']['createClient']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (response['admin']['createClient']['clientData']) {
            resolve(response['admin']['createClient']['clientData']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  async deleteClient(where: ClientWhereUniqueInput) {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.deleteClient, { where: where })
        .then((res) => {
          if (res['admin']['deleteClient']['adviseMessage'].length > 0) {
            const adviseMessage = res['admin']['deleteClient']['adviseMessage'][0];
            throw new Error(adviseMessage.description);
          }
          if (res['admin']['deleteClient']['clientData']) {
            resolve(res['admin']['deleteClient']['clientData']);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          this.notificationService.error(error);
        });
    });
  }

  getSupplierWithClients(clients: string[], suppliers: string[]): Promise<Map<string, Map<string, any>>> {
    const mapCliSupObjects = new Map<string, Map<string, any>>();
    mapCliSupObjects.set('client', new Map<string, any>());
    mapCliSupObjects.set('supplier', new Map<string, any>());

    return new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getSuppliersWithClients, { suppliers: suppliers, clients: clients })
        .then((response) => {
          if (
            response['admin'] &&
            response['admin']['allSuppliers'] &&
            response['admin']['allSuppliers']['edges'] &&
            response['admin']['allSuppliers']['edges'].length > 0
          ) {
            response['admin']['allSuppliers']['edges'].forEach((sup: any) => {
              if (sup.node?.supplierData && sup.node.supplierData.code !== '') {
                mapCliSupObjects.get('supplier').set(sup.node.supplierData.code, sup.node.supplierData);

                if (sup.node.supplierData.clients?.edges?.length > 0) {
                  sup.node.supplierData.clients?.edges.forEach((cli: any) => {
                    if (cli.node?.clientData && cli.node.clientData.code !== '') {
                      mapCliSupObjects.get('client').set(cli.node.clientData.code, cli.node.clientData);
                    }
                  });
                }
              }
            });

            resolve(mapCliSupObjects);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async archiveClient(input: ClientWhereUniqueInput): Promise<Client> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveClient, { where: input })
        .then((response) => {
          const adviseMessage = response['admin']['archiveClient']['adviseMessage'];
          if (adviseMessage && adviseMessage.length > 0) {
            reject(adviseMessage[0]['description']);
          }

          if (response['admin']['archiveClient']['code']) {
            resolve(response['admin']['archiveClient']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async unArchiveClient(input: ClientWhereUniqueInput): Promise<Client> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unArchiveClient, { where: input })
        .then((response) => {
          if (response['admin']['unArchiveClient']['code']) {
            resolve(response['admin']['unArchiveClient']);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
