import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../@core/services/notification.service';
import { ContractConnectionService } from './../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import {
  ContractConnectionUpdateInput,
  ObjectWhereUniqueInputById,
  ConnectionType,
  ActiveConnectionData,
} from '../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-connections-inconclusive-modal',
  styleUrls: ['./connections-inconclusive-modal.component.scss'],
  templateUrl: 'connections-inconclusive-modal.component.html',
})
export class BillingConnectionsinconclusiveModalComponent implements OnInit {
  @Input() connection: ActiveConnectionData = null;
  @Input() header: string;

  public connectionForm: UntypedFormGroup;

  connectionTypes: Options[] = [{ code: null, label: 'Select One' }];

  currentConType: ConnectionType;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private contractConnectionService: ContractConnectionService
  ) {}

  async ngOnInit() {
    this.buildForm();
    this.buildSelect();

    this.connectionForm.get('connectionId').setValue(this.connection.connectionId);
    this.connectionForm.get('partnerId').setValue(this.connection.partnerId);
    this.connectionForm.get('partnerName').setValue(this.connection.partnerName);
    this.connectionForm.get('partnerOrgCode').setValue(this.connection.partnerOrgCode);
    this.connectionForm.get('contractId').setValue(this.connection.contractId);
    this.connectionForm.get('partnerType').setValue(this.connection.partnerType);
    this.connectionForm.get('partnerSubType').setValue(this.connection.partnerSubType);
    this.connectionForm.get('modelType').setValue(this.connection.modelType);

    this.currentConType = ConnectionType.FREE;
    if (this.connection.connectionType === 'SUP') {
      this.currentConType = ConnectionType.STANDARD_SUPPLEMENT;
    } else if (this.connection.connectionType === 'STD') {
      this.currentConType = ConnectionType.STANDARD;
    }

    this.connectionForm
      .get('connectionType')
      .setValue(this.connectionTypes[this.connectionTypes.findIndex((x) => x.code === this.currentConType)]);
  }

  buildForm() {
    this.connectionForm = this.fb.group({
      connectionId: [{ value: '', disabled: true }, Validators.required],
      partnerId: [{ value: '', disabled: true }, Validators.required],
      partnerName: [{ value: '', disabled: true }, Validators.required],
      partnerOrgCode: [{ value: '', disabled: true }, Validators.required],
      contractId: [{ value: '', disabled: true }, Validators.required],
      partnerType: [{ value: '', disabled: true }, Validators.required],
      partnerSubType: [{ value: '', disabled: true }],
      modelType: [{ value: '', disabled: true }, Validators.required],
      connectionType: ['', Validators.required],
    });
  }

  buildSelect() {
    Object.keys(ConnectionType).forEach((value) => {
      this.connectionTypes.push({ code: value, label: value });
    });
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSave() {
    const updateData: ContractConnectionUpdateInput = {
      connectionType: { set: this.connectionForm.get('connectionType').value.code },
    };

    const where: ObjectWhereUniqueInputById = { id: this.connection.connectionId };

    this.contractConnectionService
      .updateConnection(updateData, where)
      .then((rs) => {
        this.notificationService.success('Contract connection succesfully updated', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        this.activeModal.close(false);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  validForm(): boolean {
    return this.connectionForm.get('connectionType').value.code !== this.currentConType;
  }
}
