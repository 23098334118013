import { NotificationService } from './../../../../../../@core/services/notification.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../@core/utils/utils';
import {
  Agreement,
  CommercialTemplate,
  CommercialTemplateResponse,
  CommercialTypeTemplate,
  Contract,
  PartnerModelType,
} from '../../../../interfaces/_index.billing.interfaces';
import { Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { CommercialAgreementService } from '../../../../services/commercial-agreement.service';

@Component({
  selector: 'tgx-admin-billing-commercial-agreement-partner-modal',
  styleUrls: ['./commercial-bypartner-modal.component.scss'],
  templateUrl: 'commercial-bypartner-modal.component.html',
})
export class BillingAgreementsCommercialPartnerModalComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() contracts: Contract[];
  @Input() partnerOrgCode: string;
  @Input() agreement: Agreement;

  isLoading: boolean;
  isCreating: boolean;
  agreementAux: Agreement;

  public agreementForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];
  typeOptions: Options[] = [{ code: null, label: 'Select One' }];
  container: any;
  contractOptions: Options[] = [{ code: null, label: 'Select One' }];

  templateRs: CommercialTemplateResponse;
  selectedTemplate: CommercialTemplate;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private commercialAgreementService: CommercialAgreementService
  ) {}

  async ngOnInit() {
    this.agreementForm = this.fb.group({
      typeTemplate: [null, Validators.required],
      version: [0, Validators.required],
      contractId: [0, Validators.required],
      approved: [false, Validators.required],
    });

    this.isCreating = true;

    if (this.agreement) {
      this.isCreating = false;
      this.agreementForm.get('typeTemplate').disable();
      this.agreementForm.get('version').disable();
      this.agreementForm.get('contractId').disable();

      this.agreementForm.get('typeTemplate').setValue(this.typeOptions[0]);
      this.agreementForm.get('version').setValue(this.agreement.Version);
      this.agreementForm.get('approved').setValue(this.agreement.Approved);
    } else {
      await this.getTemplates();
      this.buildSelects();

      this.agreementForm.get('typeTemplate').setValue(this.typeOptions[0]);
      this.agreementForm.get('contractId').setValue(this.contractOptions[0]);
    }
    this.agreementAux = clone(this.agreement);
  }

  buildSelects() {
    this.contracts.forEach((cn) => {
      this.contractOptions.push({
        code: cn.id.toString(),
        label:
          '(' +
          cn.id +
          ') ' +
          cn.partnerType +
          (cn.modelType ? ' - ' + cn.modelType : '') +
          (cn.ContractType ? ' - ' + cn.ContractType.name : ''),
      });
    });
  }

  filterContractSelect() {
    this.contractOptions = [{ code: null, label: 'Select One' }];

    this.contracts.forEach((cn) => {
      if (
        this.selectedTemplate.Type === cn.partnerType &&
        this.selectedTemplate.ContractTypeName === (cn.ContractType ? cn.ContractType.name : '')
      ) {
        this.contractOptions.push({
          code: cn.id.toString(),
          label:
            '(' +
            cn.id +
            ') ' +
            cn.partnerType +
            (cn.modelType ? ' - ' + cn.modelType : '') +
            (cn.ContractType ? ' - ' + cn.ContractType.name : ''),
        });
      }
    });
  }

  async getTemplates() {
    await this.commercialAgreementService
      .getTemplates()
      .then((rs) => {
        this.templateRs = rs;

        if (rs?.success === true) {
          rs.response?.forEach((tmplt: CommercialTemplate) => {
            this.typeOptions.push({
              code: tmplt.ID.toString(),
              label:
                'Type: ' +
                tmplt.Type +
                (tmplt.ContractTypeName ? ' Subtype: ' + tmplt.ContractTypeName : '') +
                ' Version: ' +
                tmplt.Version,
            });
          });
        }
      })
      .catch((err) => {
        this.notificationService.handleError(err);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.agreement = clone(this.agreementAux);
    this.activeModal.close(true);
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file && this.isValidPdfFile(file)) {
      this.container = file;
    } else {
      this.notificationService.error('Invalid pdf file.');
    }
  }

  isValidPdfFile(file: any) {
    return file.name.endsWith('.pdf');
  }

  async onSave() {
    this.isLoading = true;

    const approved = this.agreementForm.get('approved').value;

    if (this.isCreating) {
      const version = this.agreementForm.get('version').value;
      const contractId = this.agreementForm.get('contractId').value?.code;
      const type = this.selectedTemplate.Type;
      const subType = this.selectedTemplate.ContractTypeName;

      await this.commercialAgreementService
        .loadContractAgreement(this.container, type, subType, version, contractId, approved)
        .then((rs) => {
          this.notificationService.success(rs);
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.notificationService.handleError(err);
          this.activeModal.close(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      // Solo pueden cambiar el approved.

      await this.commercialAgreementService
        .updateContractAgreement(this.agreement.ID, approved)
        .then((rs) => {
          this.notificationService.success(rs);
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.notificationService.handleError(err);
          this.activeModal.close(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  validDropdown(dropdownType: string) {
    if (!this.agreementForm.get(dropdownType).value?.code) {
      this.agreementForm.get(dropdownType).setErrors({ 'Required': true });
    } else {
      this.agreementForm.get(dropdownType).setErrors(null);
    }

    if (dropdownType === 'typeTemplate' && this.agreementForm.get(dropdownType).value.code) {
      this.selectedTemplate = this.templateRs.response.filter(
        (x) => x.ID === Number(this.agreementForm.get(dropdownType).value.code)
      )[0];
      this.agreementForm.get('version').setValue(this.selectedTemplate.Version);

      this.filterContractSelect();

      if (this.contractOptions?.length > 0) {
        this.agreementForm.get('contractId').setErrors({ 'Required': true });
      } else {
        this.agreementForm.get('contractId').setErrors(null);
      }
    }
  }

  checkVersion(value: number) {
    if (Math.trunc(value) !== Math.trunc(this.selectedTemplate.Version)) {
      this.notificationService.warning('Invalid version, the agreement has to be with same base template version.');
      this.agreementForm.get('version').setErrors({ 'Invalid Version': true });
    } else {
      this.agreementForm.get('version').setErrors(null);
    }
  }

  validForm(): boolean {
    if (this.isCreating) {
      return (
        this.agreementForm.get('typeTemplate').value?.code &&
        this.agreementForm.get('version').value >= 1 &&
        this.container &&
        this.agreementForm.valid
      );
    }

    return true;
  }
}
