import { NetworkComponent } from './network.component';
import { NotFoundComponent } from './../../@core/shared/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { Sign } from 'crypto';
import { SignupStatusComponent } from './modules/help-tools/signup-status/signup-status.component';

const routes: Routes = [
  {
    path: '',
    component: NetworkComponent,
    children: [
      {
        path: 'profiles',
        loadChildren: () => import('./modules/profiles/profiles.module').then((m) => m.ProfilesModule),
      },
      {
        path: 'categories',
        loadChildren: () => import('./modules/categories/categories.module').then((m) => m.CategoriesModule),
      },
      {
        path: 'subcategories',
        loadChildren: () => import('./modules/subcategories/subcategories.module').then((m) => m.SubcategoriesModule),
      },
      {
        path: 'signup-status',
        loadChildren: () => import('./modules/help-tools/help-tools.module').then((m) => m.HelpToolsModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NetworkRoutingModule {}
