import { getUserProfile } from './social/get-user-profile';
import { allProfiles } from './social/all-profiles';
import { getOnboardingDates } from './social/get-onboarding-dates';
import { getImpersonation } from './members/get-impersonation';
import { getOrganizationsSimple } from './organizations/get-organizations-simple';
import { getCategories } from './social/get-categories';
import { getMemberRole2 } from './members/get-member-role2';
import { getAccesses } from './connections-accesses/get-accesses';
import { getAccessesBySupplierCode } from './connections-accesses/get-accesses-by-supplier-code';
import { getActivationsByOrg } from './activation/get-activations-by-org';
export { getClients } from './get-clients';
export { getOrgs } from './get-orgs-simple';
export { getSuppliers } from './get-suppliers-simple';

export const authQueries = {
  members: {
    getImpersonation,
    getMemberRole2,
  },
};
export const entitiesQuery = {
  organizations: {
    getOrganizationsSimple,
  },
};

export const socialQueries = {
  getUserProfile,
  allProfiles,
  getOnboardingDates,
  getCategories,
};

export const sharedAdminQueries = {
  getAccesses,
  getAccessesBySupplierCode,
};

export const sharedActivationsQueries = {
  getActivationsByOrg,
};
