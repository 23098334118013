import { gql } from 'apollo-angular';

export const getAllSuppliersWithoutFilter = gql`
query {
  admin{
      allSuppliers{
      edges{
        node{
          supplierData{
            code
            name
          }
        }
      }
    }
  }
}
`;